.navbar-blue {
    background-color: #263456;
    border-color: #4b62ba;
}
.navbar-blue .navbar-brand {
    color: #f1f8f9;
}
.navbar-blue .navbar-brand:hover,
.navbar-blue .navbar-brand:focus {
    color: #ecdbff;
}
.navbar-blue .navbar-text {
    color: #f1f8f9;
}
.navbar-blue .navbar-nav > li > a {
    color: #f1f8f9;
}
.navbar-blue .navbar-nav > li > a:hover,
.navbar-blue .navbar-nav > li > a:focus {
    color: #ecdbff;
}
.navbar-blue .navbar-nav > .active > a,
.navbar-blue .navbar-nav > .active > a:hover,
.navbar-blue .navbar-nav > .active > a:focus {
    color: #ecdbff;
    background-color: #4b62ba;
}
.navbar-blue .navbar-nav > .open > a,
.navbar-blue .navbar-nav > .open > a:hover,
.navbar-blue .navbar-nav > .open > a:focus {
    color: #ecdbff;
    background-color: #4b62ba;
}
.navbar-blue .navbar-toggle {
    border-color: #4b62ba;
}
.navbar-blue .navbar-toggle:hover,
.navbar-blue .navbar-toggle:focus {
    background-color: #4b62ba;
}
.navbar-blue .navbar-toggle .icon-bar {
    background-color: #f1f8f9;
}
.navbar-blue .navbar-collapse,
.navbar-blue .navbar-form {
    border-color: #f1f8f9;
}
.navbar-blue .navbar-link {
    color: #f1f8f9;
}
.navbar-blue .navbar-link:hover {
    color: #ecdbff;
}
.navbar-blue.nav > li.active > a:focus, .navbar-blue.nav > li.active > a:hover {
    color: white !important;
}

@media (max-width: 767px) {
    .navbar-blue .navbar-nav .open .dropdown-menu > li > a {
        color: #f1f8f9;
    }
    .navbar-blue .navbar-nav .open .dropdown-menu > li > a:hover,
    .navbar-blue .navbar-nav .open .dropdown-menu > li > a:focus {
        color: #ecdbff;
    }
    .navbar-blue .navbar-nav .open .dropdown-menu > .active > a,
    .navbar-blue .navbar-nav .open .dropdown-menu > .active > a:hover,
    .navbar-blue .navbar-nav .open .dropdown-menu > .active > a:focus {
        color: #ecdbff;
        background-color: #4b62ba;
    }
}