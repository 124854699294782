.col-xs-5ths, .col-sm-5ths, .col-md-5ths, .col-lg-5ths, .col-xl-5ths,
.col-xs-two5ths, .col-sm-two5ths, .col-md-two5ths, .col-lg-two5ths, .col-xl-two5ths,
.col-xs-three5ths, .col-sm-three5ths, .col-md-three5ths, .col-lg-three5ths, .col-xl-three5ths,
.col-xs-four5ths, .col-sm-four5ths, .col-md-four5ths, .col-lg-four5ths, .col-xl-four5ths,
.col-xs-five5ths, .col-sm-five5ths, .col-md-five5ths, .col-lg-five5ths, .col-xl-five5ths
{
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 576px) {
    .col-sm-5ths {width: 20%; float: left;}
    .col-sm-two5ths {width: 40%; float: left;}
    .col-sm-three5ths {width: 60%; float: left;}
    .col-sm-four5ths {width: 80%; float: left;}
}

@media (min-width: 768px) {
    .col-md-5ths {width: 20%; float: left;}
    .col-md-two5ths {width: 40%; float: left;}
    .col-md-three5ths {width: 60%; float: left;}
    .col-md-four5ths {width: 80%; float: left;}
}

@media (min-width: 992px) {
    .col-lg-5ths {width: 20%; float: left;}
    .col-lg-two5ths {width: 40%; float: left;}
    .col-lg-three5ths {width: 60%; float: left;}
    .col-lg-four5ths {width: 80%; float: left;}
}

@media (min-width: 1200px) {
    .col-xl-5ths {width: 20%; float: left;}
    .col-xl-two5ths {width: 40%; float: left;}
    .col-xl-three5ths {width: 60%; float: left;}
    .col-xl-four5ths {width: 80%; float: left;}
}