.vis-background,.vis-labelset,.vis-timeline {
    overflow:hidden
}
.vis .overlay {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:10
}
.vis-active {
    box-shadow:0 0 10px #86d5f8
}
.vis [class*=span] {
    min-height:0;
    width:auto
}
div.vis-configuration {
    position:relative;
    display:block;
    float:left;
    font-size:9pt
}
div.vis-configuration-wrapper {
    display:block;
    width:700px
}
div.vis-configuration.vis-config-option-container {
    display:block;
    width:495px;
    background-color:#fff;
    border:2px solid #f7f8fa;
    border-radius:4px;
    margin-top:20px;
    left:10px;
    padding-left:5px
}
div.vis-configuration.vis-config-button {
    display:block;
    width:495px;
    height:25px;
    vertical-align:middle;
    line-height:25px;
    background-color:#f7f8fa;
    border:2px solid #ceced0;
    border-radius:4px;
    margin-top:20px;
    left:10px;
    padding-left:5px;
    cursor:pointer;
    margin-bottom:30px
}
div.vis-configuration.vis-config-button.hover {
    background-color:#4588e6;
    border:2px solid #214373;
    color:#fff
}
div.vis-configuration.vis-config-item {
    display:block;
    float:left;
    width:495px;
    height:25px;
    vertical-align:middle;
    line-height:25px
}
div.vis-configuration.vis-config-item.vis-config-s2 {
    left:10px;
    background-color:#f7f8fa;
    padding-left:5px;
    border-radius:3px
}
div.vis-configuration.vis-config-item.vis-config-s3 {
    left:20px;
    background-color:#e4e9f0;
    padding-left:5px;
    border-radius:3px
}
div.vis-configuration.vis-config-item.vis-config-s4 {
    left:30px;
    background-color:#cfd8e6;
    padding-left:5px;
    border-radius:3px
}
div.vis-configuration.vis-config-header {
    font-size:18px;
    font-weight:700
}
div.vis-configuration.vis-config-label {
    width:90pt;
    height:25px;
    line-height:25px
}
div.vis-configuration.vis-config-label.vis-config-s3 {
    width:110px
}
div.vis-configuration.vis-config-label.vis-config-s4 {
    width:75pt
}
div.vis-configuration.vis-config-colorBlock {
    top:1px;
    width:30px;
    height:19px;
    border:1px solid #444;
    border-radius:2px;
    padding:0;
    margin:0;
    cursor:pointer
}
input.vis-configuration.vis-config-checkbox {
    left:-5px
}
input.vis-configuration.vis-config-rangeinput {
    position:relative;
    top:-5px;
    width:60px;
    height:13px;
    padding:1px;
    margin:0;
    pointer-events:none
}
.vis-panel,.vis-timeline {
    padding:0;
    box-sizing:border-box
}
input.vis-configuration.vis-config-range {
    -webkit-appearance:none;
    border:0 solid #fff;
    background-color:transparent;
    width:300px;
    height:20px
}
input.vis-configuration.vis-config-range::-webkit-slider-runnable-track {
    width:300px;
    height:5px;
    background:#dedede;
    background:-moz-linear-gradient(top,#dedede 0,#c8c8c8 99%);
    background:-webkit-gradient(linear,left top,left bottom,color-stop(0,#dedede),color-stop(99%,#c8c8c8));
    background:-webkit-linear-gradient(top,#dedede 0,#c8c8c8 99%);
    background:-o-linear-gradient(top,#dedede 0,#c8c8c8 99%);
    background:-ms-linear-gradient(top,#dedede 0,#c8c8c8 99%);
    background:linear-gradient(to bottom,#dedede 0,#c8c8c8 99%);
    filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#dedede', endColorstr='#c8c8c8', GradientType=0 );
    border:1px solid #999;
    box-shadow:#aaa 0 0 3px 0;
    border-radius:3px
}
input.vis-configuration.vis-config-range::-webkit-slider-thumb {
    -webkit-appearance:none;
    border:1px solid #14334b;
    height:17px;
    width:17px;
    border-radius:50%;
    background:#3876c2;
    background:-moz-linear-gradient(top,#3876c2 0,#385380 100%);
    background:-webkit-gradient(linear,left top,left bottom,color-stop(0,#3876c2),color-stop(100%,#385380));
    background:-webkit-linear-gradient(top,#3876c2 0,#385380 100%);
    background:-o-linear-gradient(top,#3876c2 0,#385380 100%);
    background:-ms-linear-gradient(top,#3876c2 0,#385380 100%);
    background:linear-gradient(to bottom,#3876c2 0,#385380 100%);
    filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#3876c2', endColorstr='#385380', GradientType=0 );
    box-shadow:#111927 0 0 1px 0;
    margin-top:-7px
}
input.vis-configuration.vis-config-range:focus {
    outline:0
}
input.vis-configuration.vis-config-range:focus::-webkit-slider-runnable-track {
    background:#9d9d9d;
    background:-moz-linear-gradient(top,#9d9d9d 0,#c8c8c8 99%);
    background:-webkit-gradient(linear,left top,left bottom,color-stop(0,#9d9d9d),color-stop(99%,#c8c8c8));
    background:-webkit-linear-gradient(top,#9d9d9d 0,#c8c8c8 99%);
    background:-o-linear-gradient(top,#9d9d9d 0,#c8c8c8 99%);
    background:-ms-linear-gradient(top,#9d9d9d 0,#c8c8c8 99%);
    background:linear-gradient(to bottom,#9d9d9d 0,#c8c8c8 99%);
    filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#9d9d9d', endColorstr='#c8c8c8', GradientType=0 )
}
input.vis-configuration.vis-config-range::-moz-range-track {
    width:300px;
    height:10px;
    background:#dedede;
    background:-moz-linear-gradient(top,#dedede 0,#c8c8c8 99%);
    background:-webkit-gradient(linear,left top,left bottom,color-stop(0,#dedede),color-stop(99%,#c8c8c8));
    background:-webkit-linear-gradient(top,#dedede 0,#c8c8c8 99%);
    background:-o-linear-gradient(top,#dedede 0,#c8c8c8 99%);
    background:-ms-linear-gradient(top,#dedede 0,#c8c8c8 99%);
    background:linear-gradient(to bottom,#dedede 0,#c8c8c8 99%);
    filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#dedede', endColorstr='#c8c8c8', GradientType=0 );
    border:1px solid #999;
    box-shadow:#aaa 0 0 3px 0;
    border-radius:3px
}
input.vis-configuration.vis-config-range::-moz-range-thumb {
    border:none;
    height:1pc;
    width:1pc;
    border-radius:50%;
    background:#385380
}
input.vis-configuration.vis-config-range:-moz-focusring {
    outline:#fff solid 1px;
    outline-offset:-1px
}
input.vis-configuration.vis-config-range::-ms-track {
    width:300px;
    height:5px;
    background:0 0;
    border-color:transparent;
    border-width:6px 0;
    color:transparent
}
input.vis-configuration.vis-config-range::-ms-fill-lower {
    background:#777;
    border-radius:10px
}
input.vis-configuration.vis-config-range::-ms-fill-upper {
    background:#ddd;
    border-radius:10px
}
input.vis-configuration.vis-config-range::-ms-thumb {
    border:none;
    height:1pc;
    width:1pc;
    border-radius:50%;
    background:#385380
}
input.vis-configuration.vis-config-range:focus::-ms-fill-lower {
    background:#888
}
input.vis-configuration.vis-config-range:focus::-ms-fill-upper {
    background:#ccc
}
.vis-configuration-popup {
    position:absolute;
    background:rgba(57,76,89,.85);
    border:2px solid #f2faff;
    line-height:30px;
    height:30px;
    width:150px;
    text-align:center;
    color:#fff;
    font-size:14px;
    border-radius:4px;
    -webkit-transition:opacity .3s ease-in-out;
    -moz-transition:opacity .3s ease-in-out;
    transition:opacity .3s ease-in-out
}
.vis-configuration-popup:after,.vis-configuration-popup:before {
    left:100%;
    top:50%;
    border:solid transparent;
    content:" ";
    height:0;
    width:0;
    position:absolute;
    pointer-events:none
}
.vis-configuration-popup:after {
    border-color:rgba(136,183,213,0);
    border-left-color:rgba(57,76,89,.85);
    border-width:8px;
    margin-top:-8px
}
.vis-configuration-popup:before {
    border-color:rgba(194,225,245,0);
    border-left-color:#f2faff;
    border-width:9pt;
    margin-top:-9pt
}
.vis-timeline {
    position:relative;
    border:1px solid #bfbfbf;
    border-radius:3px;
    margin:0
}
.vis-panel {
    position:absolute;
    margin:0
}
.vis-panel.vis-bottom,.vis-panel.vis-center,.vis-panel.vis-left,.vis-panel.vis-right,.vis-panel.vis-top {
    border:1px #bfbfbf
}
.vis-panel.vis-center,.vis-panel.vis-left,.vis-panel.vis-right {
    border-top-style:solid;
    border-bottom-style:solid;
    overflow:hidden
}
.vis-panel.vis-bottom,.vis-panel.vis-center,.vis-panel.vis-top {
    border-left-style:solid;
    border-right-style:solid
}
.vis-panel>.vis-content {
    position:relative
}
.vis-panel .vis-shadow {
    position:absolute;
    width:100%;
    height:1px;
    box-shadow:0 0 10px rgba(0,0,0,.8)
}
.vis-itemset,.vis-labelset,.vis-labelset .vis-label {
    position:relative;
    box-sizing:border-box
}
.vis-panel .vis-shadow.vis-top {
    top:-1px;
    left:0
}
.vis-panel .vis-shadow.vis-bottom {
    bottom:-1px;
    left:0
}
.vis-labelset .vis-label {
    left:0;
    top:0;
    width:100%;
    color:#4d4d4d;
    border-bottom:1px solid #bfbfbf
}
.vis-labelset .vis-label.draggable {
    cursor:pointer
}
.vis-labelset .vis-label:last-child {
    border-bottom:none
}
.vis-labelset .vis-label .vis-inner {
    display:inline-block;
    padding:5px
}
.vis-labelset .vis-label .vis-inner.vis-hidden {
    padding:0
}
.vis-itemset {
    padding:0;
    margin:0
}
.vis-itemset .vis-background,.vis-itemset .vis-foreground {
    position:absolute;
    width:100%;
    height:100%;
    overflow:visible
}
.vis-axis {
    position:absolute;
    width:100%;
    height:0;
    left:0;
    z-index:1
}
.vis-foreground .vis-group {
    position:relative;
    box-sizing:border-box;
    border-bottom:1px solid #bfbfbf
}
.vis-foreground .vis-group:last-child {
    border-bottom:none
}
.vis-overlay {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:10
}
.vis-item {
    position:absolute;
    color:#1A1A1A;
    border-color:#97B0F8;
    border-width:1px;
    background-color:#D5DDF6;
    display:inline-block
}
.vis-item.vis-point.vis-selected,.vis-item.vis-selected {
    background-color:#FFF785;
}
.vis-item.vis-selected {
    border-color:#FFC200;
    z-index:2
}
.vis-editable.vis-selected {
    cursor:move
}
.vis-item.vis-box {
    text-align:center;
    border-style:solid;
    border-radius:2px
}
.vis-item.vis-point {
    background:0 0
}
.vis-item.vis-dot {
    position:absolute;
    padding:0;
    border-width:4px;
    border-style:solid;
    border-radius:4px
}
.vis-item.vis-range {
    border-style:solid;
    box-sizing:border-box
}
.vis-item.vis-background {
    border:none;
    background-color:rgba(213,221,246,.4);
    box-sizing:border-box;
    padding:0;
    margin:0
}
.vis-item .vis-item-overflow {
    position:relative;
    width:100%;
    height:auto;
    padding:0;
    margin:0px 0px 0px;
    overflow:hidden
}
.vis-item.vis-range .vis-item-content {
    position:relative;
    display:inline-block
}
.vis-item.vis-background .vis-item-content {
    position:absolute;
    display:inline-block
}
.vis-item.vis-line {
    padding:0;
    position:absolute;
    width:0;
    border-left-width:1px;
    border-left-style:solid
}
.vis-item .vis-item-content {
    white-space:nowrap;
    box-sizing:border-box;
    padding:0px 0px 5px;
}
.vis-item .vis-delete {
    background:url(/assets/images/delete.png) center no-repeat;
    position:absolute;
    width:24px;
    height:24px;
    top:-5px;
    right:-25px;
    cursor:pointer
}
.vis-item.vis-range .vis-drag-left {
    position:absolute;
    width:24px;
    max-width:20%;
    min-width:2px;
    height:100%;
    top:0;
    left:-4px;
    cursor:w-resize
}
.vis-item.vis-range .vis-drag-right {
    position:absolute;
    width:24px;
    max-width:20%;
    min-width:2px;
    height:100%;
    top:0;
    right:-4px;
    cursor:e-resize
}
.vis-time-axis {
    position:relative;
    overflow:hidden
}
.vis-time-axis.vis-foreground {
    top:0;
    left:0;
    width:100%
}
.vis-time-axis.vis-background {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%
}
.vis-time-axis .vis-text {
    position:absolute;
    color:#4d4d4d;
    padding:3px;
    font-size:14px;
    font-weight:600;
    overflow:hidden;
    box-sizing:border-box;
    white-space:nowrap
}
.vis-time-axis .vis-text.vis-measure {
    position:absolute;
    padding-left:0;
    padding-right:0;
    margin-left:0;
    margin-right:0;
    visibility:hidden
}
.vis-time-axis .vis-grid.vis-vertical {
    position:absolute;
    border-left:1px solid
}
.vis-time-axis .vis-grid.vis-minor {
    border-color:#e5e5e5
}
.vis-time-axis .vis-grid.vis-major {
    border-color:#bfbfbf
}
.vis-current-time {
    background-color:#FF7F6E;
    width:2px;
    z-index:1
}
.vis-custom-time {
    background-color:#6E94FF;
    width:2px;
    cursor:move;
    z-index:1
}
div.vis-network div.vis-close,div.vis-network div.vis-edit-mode div.vis-button,div.vis-network div.vis-manipulation div.vis-button {
    cursor:pointer;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    -webkit-touch-callout:none;
    -khtml-user-select:none
}
.vis-panel.vis-background.vis-horizontal .vis-grid.vis-horizontal {
    position:absolute;
    width:100%;
    height:0;
    border-bottom:1px solid
}
.vis-panel.vis-background.vis-horizontal .vis-grid.vis-minor {
    border-color:#e5e5e5
}
.vis-panel.vis-background.vis-horizontal .vis-grid.vis-major {
    border-color:#bfbfbf
}
.vis-data-axis .vis-y-axis.vis-major {
    width:100%;
    position:absolute;
    color:#4d4d4d;
    white-space:nowrap
}
.vis-data-axis .vis-y-axis.vis-major.vis-measure {
    padding:0;
    margin:0;
    border:0;
    visibility:hidden;
    width:auto
}
.vis-data-axis .vis-y-axis.vis-minor {
    position:absolute;
    width:100%;
    color:#bebebe;
    white-space:nowrap
}
.vis-data-axis .vis-y-axis.vis-minor.vis-measure {
    padding:0;
    margin:0;
    border:0;
    visibility:hidden;
    width:auto
}
.vis-data-axis .vis-y-axis.vis-title {
    position:absolute;
    color:#4d4d4d;
    white-space:nowrap;
    bottom:20px;
    text-align:center
}
.vis-data-axis .vis-y-axis.vis-title.vis-measure {
    padding:0;
    margin:0;
    visibility:hidden;
    width:auto
}
.vis-data-axis .vis-y-axis.vis-title.vis-left {
    bottom:0;
    -webkit-transform-origin:left top;
    -moz-transform-origin:left top;
    -ms-transform-origin:left top;
    -o-transform-origin:left top;
    transform-origin:left bottom;
    -webkit-transform:rotate(-90deg);
    -moz-transform:rotate(-90deg);
    -ms-transform:rotate(-90deg);
    -o-transform:rotate(-90deg);
    transform:rotate(-90deg)
}
.vis-data-axis .vis-y-axis.vis-title.vis-right {
    bottom:0;
    -webkit-transform-origin:right bottom;
    -moz-transform-origin:right bottom;
    -ms-transform-origin:right bottom;
    -o-transform-origin:right bottom;
    transform-origin:right bottom;
    -webkit-transform:rotate(90deg);
    -moz-transform:rotate(90deg);
    -ms-transform:rotate(90deg);
    -o-transform:rotate(90deg);
    transform:rotate(90deg)
}
.vis-legend {
    background-color:rgba(247,252,255,.65);
    padding:5px;
    border:1px solid #b3b3b3;
    box-shadow:2px 2px 10px rgba(154,154,154,.55)
}
.vis-legend-text {
    white-space:nowrap;
    display:inline-block
}
.vis-graph-group0 {
    fill:#4f81bd;
    fill-opacity:0;
    stroke-width:2px;
    stroke:#4f81bd
}
.vis-graph-group1 {
    fill:#f79646;
    fill-opacity:0;
    stroke-width:2px;
    stroke:#f79646
}
.vis-graph-group2 {
    fill:#8c51cf;
    fill-opacity:0;
    stroke-width:2px;
    stroke:#8c51cf
}
.vis-graph-group3 {
    fill:#75c841;
    fill-opacity:0;
    stroke-width:2px;
    stroke:#75c841
}
.vis-graph-group4 {
    fill:#ff0100;
    fill-opacity:0;
    stroke-width:2px;
    stroke:#ff0100
}
.vis-graph-group5 {
    fill:#37d8e6;
    fill-opacity:0;
    stroke-width:2px;
    stroke:#37d8e6
}
.vis-graph-group6 {
    fill:#042662;
    fill-opacity:0;
    stroke-width:2px;
    stroke:#042662
}
.vis-graph-group7 {
    fill:#00ff26;
    fill-opacity:0;
    stroke-width:2px;
    stroke:#00ff26
}
.vis-graph-group8 {
    fill:#f0f;
    fill-opacity:0;
    stroke-width:2px;
    stroke:#f0f
}
.vis-graph-group9 {
    fill:#8f3938;
    fill-opacity:0;
    stroke-width:2px;
    stroke:#8f3938
}
.vis-timeline .vis-fill {
    fill-opacity:.1;
    stroke:none
}
.vis-timeline .vis-bar {
    fill-opacity:.5;
    stroke-width:1px
}
.vis-timeline .vis-point {
    stroke-width:2px;
    fill-opacity:1
}
.vis-timeline .vis-legend-background {
    stroke-width:1px;
    fill-opacity:.9;
    fill:#fff;
    stroke:#c2c2c2
}
.vis-timeline .vis-outline {
    stroke-width:1px;
    fill-opacity:1;
    fill:#fff;
    stroke:#e5e5e5
}
.vis-timeline .vis-icon-fill {
    fill-opacity:.3;
    stroke:none
}
div.vis-network div.vis-manipulation {
    border-width:0;
    border-bottom:1px;
    border-style:solid;
    border-color:#d6d9d8;
    background:#fff;
    background:-moz-linear-gradient(top,#fff 0,#fcfcfc 48%,#fafafa 50%,#fcfcfc 100%);
    background:-webkit-gradient(linear,left top,left bottom,color-stop(0,#fff),color-stop(48%,#fcfcfc),color-stop(50%,#fafafa),color-stop(100%,#fcfcfc));
    background:-webkit-linear-gradient(top,#fff 0,#fcfcfc 48%,#fafafa 50%,#fcfcfc 100%);
    background:-o-linear-gradient(top,#fff 0,#fcfcfc 48%,#fafafa 50%,#fcfcfc 100%);
    background:-ms-linear-gradient(top,#fff 0,#fcfcfc 48%,#fafafa 50%,#fcfcfc 100%);
    background:linear-gradient(to bottom,#fff 0,#fcfcfc 48%,#fafafa 50%,#fcfcfc 100%);
    filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#fcfcfc', GradientType=0 );
    padding-top:4px;
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:28px
}
div.vis-network div.vis-edit-mode {
    position:absolute;
    left:0;
    top:5px;
    height:30px
}
div.vis-network div.vis-close {
    position:absolute;
    right:0;
    top:0;
    width:30px;
    height:30px;
    background-position:20px 3px;
    background-repeat:no-repeat;
    background-image:url(img/network/cross.png);
    user-select:none
}
div.vis-network div.vis-close:hover {
    opacity:.6
}
div.vis-network div.vis-edit-mode div.vis-button,div.vis-network div.vis-manipulation div.vis-button {
    float:left;
    font-family:verdana;
    font-size:9pt;
    -moz-border-radius:15px;
    border-radius:15px;
    display:inline-block;
    background-position:0 0;
    background-repeat:no-repeat;
    height:24px;
    margin-left:10px;
    padding:0 8px;
    user-select:none
}
div.vis-network div.vis-manipulation div.vis-button:hover {
    box-shadow:1px 1px 8px rgba(0,0,0,.2)
}
div.vis-network div.vis-manipulation div.vis-button:active {
    box-shadow:1px 1px 8px rgba(0,0,0,.5)
}
div.vis-network div.vis-manipulation div.vis-button.vis-back {
    background-image:url(img/network/backIcon.png)
}
div.vis-network div.vis-manipulation div.vis-button.vis-none:hover {
    box-shadow:1px 1px 8px transparent;
    cursor:default
}
div.vis-network div.vis-manipulation div.vis-button.vis-none:active {
    box-shadow:1px 1px 8px transparent
}
div.vis-network div.vis-manipulation div.vis-button.vis-none {
    padding:0
}
div.vis-network div.vis-manipulation div.notification {
    margin:2px;
    font-weight:700
}
div.vis-network div.vis-manipulation div.vis-button.vis-add {
    background-image:url(img/network/addNodeIcon.png)
}
div.vis-network div.vis-edit-mode div.vis-button.vis-edit,div.vis-network div.vis-manipulation div.vis-button.vis-edit {
    background-image:url(img/network/editIcon.png)
}
div.vis-network div.vis-edit-mode div.vis-button.vis-edit.vis-edit-mode {
    background-color:#fcfcfc;
    border:1px solid #ccc
}
div.vis-network div.vis-manipulation div.vis-button.vis-connect {
    background-image:url(img/network/connectIcon.png)
}
div.vis-network div.vis-manipulation div.vis-button.vis-delete {
    background-image:url(img/network/deleteIcon.png)
}
div.vis-network div.vis-edit-mode div.vis-label,div.vis-network div.vis-manipulation div.vis-label {
    margin:0 0 0 23px;
    line-height:25px
}
div.vis-network div.vis-manipulation div.vis-separator-line {
    float:left;
    display:inline-block;
    width:1px;
    height:21px;
    background-color:#bdbdbd;
    margin:0 7px 0 15px
}
div.vis-network-tooltip {
    position:absolute;
    visibility:hidden;
    padding:5px;
    white-space:nowrap;
    font-family:verdana;
    font-size:14px;
    font-color:#000;
    background-color:#f5f4ed;
    -moz-border-radius:3px;
    -webkit-border-radius:3px;
    border-radius:3px;
    border:1px solid #808074;
    box-shadow:3px 3px 10px rgba(0,0,0,.2);
    pointer-events:none
}
div.vis-network div.vis-navigation div.vis-button {
    width:34px;
    height:34px;
    -moz-border-radius:17px;
    border-radius:17px;
    position:absolute;
    display:inline-block;
    background-position:2px 2px;
    background-repeat:no-repeat;
    cursor:pointer;
    -webkit-touch-callout:none;
    -webkit-user-select:none;
    -khtml-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none
}
div.vis-network div.vis-navigation div.vis-button:hover {
    box-shadow:0 0 3px 3px rgba(56,207,21,.3)
}
div.vis-network div.vis-navigation div.vis-button:active {
    box-shadow:0 0 1px 3px rgba(56,207,21,.95)
}
div.vis-network div.vis-navigation div.vis-button.vis-up {
    background-image:url(img/network/upArrow.png);
    bottom:50px;
    left:55px
}
div.vis-network div.vis-navigation div.vis-button.vis-down {
    background-image:url(img/network/downArrow.png);
    bottom:10px;
    left:55px
}
div.vis-network div.vis-navigation div.vis-button.vis-left {
    background-image:url(img/network/leftArrow.png);
    bottom:10px;
    left:15px
}
div.vis-network div.vis-navigation div.vis-button.vis-right {
    background-image:url(img/network/rightArrow.png);
    bottom:10px;
    left:95px
}
div.vis-network div.vis-navigation div.vis-button.vis-zoomIn {
    background-image:url(img/network/plus.png);
    bottom:10px;
    right:15px
}
div.vis-network div.vis-navigation div.vis-button.vis-zoomOut {
    background-image:url(img/network/minus.png);
    bottom:10px;
    right:55px
}
div.vis-network div.vis-navigation div.vis-button.vis-zoomExtends {
    background-image:url(img/network/zoomExtends.png);
    bottom:50px;
    right:15px
}
div.vis-color-picker {
    position:absolute;
    margin-top:-140px;
    margin-left:30px;
    width:293px;
    height:425px;
    padding:10px;
    border-radius:15px;
    background-color:#fff;
    display:none;
    box-shadow:rgba(0,0,0,.5) 0 0 10px 0
}
div.vis-color-picker div.vis-arrow {
    position:absolute;
    top:147px;
    left:5px
}
div.vis-color-picker div.vis-arrow:after,div.vis-color-picker div.vis-arrow:before {
    right:100%;
    top:50%;
    border:solid transparent;
    content:" ";
    height:0;
    width:0;
    position:absolute;
    pointer-events:none
}
div.vis-color-picker div.vis-arrow:after {
    border-color:rgba(255,255,255,0);
    border-right-color:#fff;
    border-width:30px;
    margin-top:-30px
}
div.vis-color-picker div.vis-color {
    position:absolute;
    width:289px;
    height:289px;
    cursor:pointer
}
div.vis-color-picker div.vis-brightness {
    position:absolute;
    top:313px
}
div.vis-color-picker div.vis-opacity {
    position:absolute;
    top:350px
}
div.vis-color-picker div.vis-selector {
    position:absolute;
    top:137px;
    left:137px;
    width:15px;
    height:15px;
    border-radius:15px;
    border:1px solid #fff;
    background:#4c4c4c;
    background:-moz-linear-gradient(top,#4c4c4c 0,#595959 12%,#666 25%,#474747 39%,#2c2c2c 50%,#000 51%,#111 60%,#2b2b2b 76%,#1c1c1c 91%,#131313 100%);
    background:-webkit-gradient(linear,left top,left bottom,color-stop(0,#4c4c4c),color-stop(12%,#595959),color-stop(25%,#666),color-stop(39%,#474747),color-stop(50%,#2c2c2c),color-stop(51%,#000),color-stop(60%,#111),color-stop(76%,#2b2b2b),color-stop(91%,#1c1c1c),color-stop(100%,#131313));
    background:-webkit-linear-gradient(top,#4c4c4c 0,#595959 12%,#666 25%,#474747 39%,#2c2c2c 50%,#000 51%,#111 60%,#2b2b2b 76%,#1c1c1c 91%,#131313 100%);
    background:-o-linear-gradient(top,#4c4c4c 0,#595959 12%,#666 25%,#474747 39%,#2c2c2c 50%,#000 51%,#111 60%,#2b2b2b 76%,#1c1c1c 91%,#131313 100%);
    background:-ms-linear-gradient(top,#4c4c4c 0,#595959 12%,#666 25%,#474747 39%,#2c2c2c 50%,#000 51%,#111 60%,#2b2b2b 76%,#1c1c1c 91%,#131313 100%);
    background:linear-gradient(to bottom,#4c4c4c 0,#595959 12%,#666 25%,#474747 39%,#2c2c2c 50%,#000 51%,#111 60%,#2b2b2b 76%,#1c1c1c 91%,#131313 100%);
    filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c4c4c', endColorstr='#131313', GradientType=0 )
}
div.vis-color-picker div.vis-initial-color,div.vis-color-picker div.vis-new-color {
    width:140px;
    height:20px;
    top:380px;
    font-size:10px;
    color:rgba(0,0,0,.4);
    line-height:20px;
    position:absolute;
    vertical-align:middle
}
div.vis-color-picker div.vis-new-color {
    border:1px solid rgba(0,0,0,.1);
    border-radius:5px;
    left:159px;
    text-align:right;
    padding-right:2px
}
div.vis-color-picker div.vis-initial-color {
    border:1px solid rgba(0,0,0,.1);
    border-radius:5px;
    left:10px;
    text-align:left;
    padding-left:2px
}
div.vis-color-picker div.vis-label {
    position:absolute;
    width:300px;
    left:10px
}
div.vis-color-picker div.vis-label.vis-brightness {
    top:300px
}
div.vis-color-picker div.vis-label.vis-opacity {
    top:338px
}
div.vis-color-picker div.vis-button {
    position:absolute;
    width:68px;
    height:25px;
    border-radius:10px;
    vertical-align:middle;
    text-align:center;
    line-height:25px;
    top:410px;
    border:2px solid #d9d9d9;
    background-color:#f7f7f7;
    cursor:pointer
}
div.vis-color-picker div.vis-button.vis-cancel {
    left:5px
}
div.vis-color-picker div.vis-button.vis-load {
    left:82px
}
div.vis-color-picker div.vis-button.vis-apply {
    left:159px
}
div.vis-color-picker div.vis-button.vis-save {
    left:236px
}
div.vis-color-picker input.vis-range {
    width:290px;
    height:20px
}
