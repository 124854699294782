.clean {
    clear: both;
}

.dayContainer {
    float: left;
    line-height: 20px;
    margin-right: 8px;
    width: 65px;
    font-size: 11px;
    font-weight: bold;
}

.colorBox {
    cursor: pointer;
    height: 45px;
    border: 2px solid #888;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.colorBox.WorkingDayState {
    border: 2px solid #4E8059;
    background-color: #8ade8f;
}

.colorBox.RestDayState {
    border: 2px solid #7a1c44;
    background-color: #de5962;
}

.operationTime .mini-time {
    width: 40px;
    padding: 3px;
    font-size: 12px;
    font-weight: normal;
}

.dayContainer .add-on {
    padding: 4px 2px;
}

.colorBoxLabel {
    clear: both;
    font-size: 12px;
    font-weight: bold;
}

.invisible {
    visibility: hidden;
}

.operationTime {
    margin-top: 5px;
}