html.display, body.display {
    height: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    overflow: hidden;
}

div {
    margin: 0;
    border: 0;
}

img {
    max-width: 100% !important;
    max-height: 100%;
}

.display-wrapper {
    position: relative;
}

.displayWrapper {
    height: 100%;
}

.display-wrapper,
ul.slider,
div.slide-wrapper,
div.static-wrapper,
div.bx-wrapper,
div.bx-viewport {
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    border: none !important;
    box-shadow: none !important;
    overflow: hidden;
}

.display-wrapper * {
    -webkit-backface-visibility: hidden;
    font-smoothing: antialiased !important;
}

video {
    width: 100% !important;
    height: auto;
}

div.slide-wrapper ul.slider img {
    width: 100%;
}

ul.slider li,
div.displaySlideshow,
div.slide-img,
div.slide-vid,
ul.slider .slide-img img,
.royalSlider, .rsOverflow {
    height: 100% !important;
    width: 100% !important;
    position: relative;
    border-radius: 6px;
}

div.slide-img {
    width: 100% !important;
}

div.slide-img img {
    height: 100%;
}

.f-left {
    float: left;
    display: initial;
}

.display .top {
    height: 150px;
}

.displaySlideshow {
    width: 35%;
}

.displayMenu {
    width: 65%;
    height: 100%;
}

.surg-gen {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 100%;
    z-index: 999;
    height: 40px;
    font-size: 17px;
    background: white;
    border-style: solid;
    border-color: black;
    border-width: 2px 0px 2px 0px;
    text-align: center;
    color: black;
}

.surg-gen-inner {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

/*@font-face {*/
    /*font-family: 'Lobster';*/
    /*src: url('http://staging.controlcenter.com/assets/fonts/google/Lobster-Regular.ttf')  format('truetype'); /!* Safari, Android, iOS *!/*/
/*}*/

/************************************************
**
* CSS For Top Area
**
************************************************/
.top-inner {
    height: 100%;
    border-bottom: 1px solid #999;
    box-shadow: 0px 0px 5px #888;
}
.top-inner > div {
    height: 100%;
    position: relative;
}
.logo {
    float: left;
    text-align: left;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 25%;
}
.logo img {
    height: auto;
    display: inline-block;
    padding: .5% 3%;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.time-widget {
    float: right;
    height: auto !important;
    width: 18%;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.time-widget .time-inner {
    display: table;
    height: 100%;
}
.time-widget .time-inner span {
    vertical-align: middle;
    display: table-cell;
    padding-right: 10px;
    text-shadow: 0px 2px 1px white;
}

/************************************************
**
* CSS For Marquee
**
************************************************/

.marquee-widget {
    width: 56%;
    float: left;
    overflow: hidden;
}
.marquee-widget.noTime {
    width: 70%;
}
.marquee-outer {
    float: left;
    width: 65%;
    height: 100%;
}
.marquee {
    font-size: 250%;
    position: relative;
    height: 100%;
}
.marquee.str_wrap {
    background: none;
    color: white;
    height: 100% !important;
}
.marquee .str_move {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.marquee .js-marquee-wrapper {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.marquee .marquee-spacer {
    padding: 0px 40px;
}

.ticker-swap {
    height: 40px;
    overflow: hidden;
}
.slide-in {
    white-space: nowrap;
    width: 3000px;
}
.slide-in-item {
    height: 100%;
    float: left;
    font-size: 27px;
    color: white;
    list-style-type: none;
}

.vertical-center {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.vertical-center {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.slide-in {
    padding: 0;
    position: relative;
}
.slide-in .slide-in-item {
    width: 100%;
    height: 45px;
    list-style-type: none;
    position: absolute;
    top: 0;
    bottom: 0;
    display: none;
    font-weight: bold;
}

.marquee-widget {overflow: visible !important;}
.noLogo .marquee-widget {
    width: 75%;
    margin-left: 2.5%;
}

/************************************************
**
* CSS For Slideshow
**
************************************************/

ul.slider.portrait {
    background-size: 100% 100% !important;
}

/* .bx-controls { */
    /* display: none !important; */
/* } */

/*
ul.slider.portrait {
    margin: 0px auto !important;
    height: 100% !important;
    background: #888;
    background-color: #888;
    background-position: center center !important;
    background-repeat: no-repeat;
    background-size: contain !important;
}

ul.slider.portrait .slide-img {
    max-width: 100% !important;
    height: 100% !important;
    padding: 5% 10%;
    vertical-align: middle;
    position: relative;
}
ul.slider.portrait img {
    width: auto !important;
    height: auto !important;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
    max-width: 100% !important;
}
*/

.nivoSlider img {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100% !important;
    height: 100% !important;
    vertical-align: baseline !important;
    max-width: none !important;
    opacity: 1;
    filter: alpha(opacity=100);
    zoom: 1;
}

.static-ad-wrap {
    width: 100%;
    height: 100%;
    display: block;
}
.static-ad-wrap img.static-adv {
    height: 100%;
    width: 100%;
    border-radius: 6px;
}


/************************************************
**
* CSS For List Menu
**
************************************************/

.list .dCell h1 {
    font-size: 35px;
    margin: 5px 0px 0px;
    padding: 0px;
}

.list .dCell h2 {
    font-size: 40px;
    font-weight: normal;
    margin: 10px 0px 10px;
    padding: 0px;
}

.list .dHeading .dCell {
    border-width: 0px 1px 0px 0px;
}

.list .dCell {
    font-size: 35px;
    padding: 10px 5px 10px 15px;
}

.list .price span {
    font-size: 35px;
    padding: 0px 3px;
}

.list .price sup {
    line-height: 65px;
    font-size: 23px;
    vertical-align: top;
    position: relative;
    top: -0.5em;
}

.list .column-items.h-r {
    color: #8B0000;
}

.list .column-items.h-b {
    color: #191970;
}

.list .prod-img {
    height: 40px !important;
    width: 40px !important;
    float: left;
    line-height: 40px;
}

/************************************************
**
* CSS For Grid Menu
**
************************************************/
/**
* Overlays
**/

/* Black Opacity */
.overlay-dark-1 {
    border: none !important;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNgkAQAABwAGkn5GOoAAAAASUVORK5CYII=);
}
.overlay-dark-2 {
    border: none !important;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNgMAYAADYANKqWpHYAAAAASUVORK5CYII=);
}
.overlay-dark-3 {
    border: none !important;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNg8AEAAE8ATQl3kQIAAAAASUVORK5CYII=);
}
.overlay-dark-4 {
    border: none !important;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNgSAMAAGkAZ7BewkoAAAAASUVORK5CYII=);
}
.overlay-dark-5 {
    border: none !important;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNgqAcAAIIAgLO2btEAAAAASUVORK5CYII=);
}
.overlay-dark-6 {
    border: none !important;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNgmAkAAJwAmsoF3fMAAAAASUVORK5CYII=);
}
.overlay-dark-7 {
    border: none !important;
    border: none !important;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNg2AQAALUAs0e+XlcAAAAASUVORK5CYII=);
}
.overlay-dark-8 {
    border: none !important;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNgOAMAAM8AzbfAeUUAAAAASUVORK5CYII=);
}
.overlay-dark-9 {
    border: none !important;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNgeAoAAOgA5kV69NEAAAAASUVORK5CYII=);
}
.overlay-dark {
    border: none !important;
    background: #000000;
}
/* White Opacity */
.overlay-light-1 {
    border: none !important;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP4LwkAAhoBGfGpT/sAAAAASUVORK5CYII=);
}
.overlay-light-2 {
    border: none !important;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP4bwwAAjQBMwwHCQ0AAAAASUVORK5CYII=);
}
.overlay-light-3 {
    border: none !important;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP47wMAAk0BTO7dblgAAAAASUVORK5CYII=);
}
.overlay-light-4 {
    border: none !important;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP4nwYAAmcBZrwZJSAAAAAASUVORK5CYII=);
}
.overlay-light-5 {
    border: none !important;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP4Xw8AAoABf5/NhYYAAAAASUVORK5CYII=);
}
.overlay-light-6 {
    border: none !important;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP4PxMAApoBmTi3amgAAAAASUVORK5CYII=);
}
.overlay-light-7 {
    border: none !important;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP4vwkAArMBsonMJUgAAAAASUVORK5CYII=);
}
.overlay-light-8 {
    border: none !important;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP4fwYAAs0BzF1VEXcAAAAASUVORK5CYII=);
}
.overlay-light-9 {
    border: none !important;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP4/xQAAuYB5e33aXgAAAAASUVORK5CYII=);
}
.overlay-light {
    border: none !important;
    background: #FFFFFF;
}
.text-shadow-light {
    text-shadow: 0px 1px 2px #FFF;
}
.text-shadow-light-lg {
    text-shadow: 0px 2px 2px #FFF;
}
.text-shadow-dark {
    text-shadow: 0px 1px 2px #000;
}
.text-shadow-dark-lg {
    text-shadow: 0px 3px 2px #000;
}
.strike-slash,
.strike-cross {
    position: relative;
}
.strike-slash:before {
    position: absolute;
    content: "";
    left: 20%;
    top: 45%;
    right: 20%;
    z-index: 25;
    border-top: 4px solid;
    border-color: rgba(204, 0, 0, 0.8);
    -webkit-transform:rotate(-35deg);
    -moz-transform:rotate(-35deg);
    -ms-transform:rotate(-35deg);
    -o-transform:rotate(-35deg);
    transform:rotate(-35deg);
}
.strike-cross:before {
    position: absolute;
    content: "";
    left: 20%;
    top: 45%;
    right: 20%;
    z-index: 25;
    border-top: 4px solid;
    border-color: rgba(204, 0, 0, 0.8);
    -webkit-transform:rotate(-35deg);
    -moz-transform:rotate(-35deg);
    -ms-transform:rotate(-35deg);
    -o-transform:rotate(-35deg);
    transform:rotate(-35deg);
}
.strike-cross:after {
    position: absolute;
    content: "";
    left: 20%;
    top: 45%;
    right: 20%;
    z-index: 25;
    border-top: 4px solid;
    border-color: rgba(204, 0, 0, 0.8);
    -webkit-transform:rotate(35deg);
    -moz-transform:rotate(35deg);
    -ms-transform:rotate(35deg);
    -o-transform:rotate(35deg);
    transform:rotate(35deg);
}
.grid {
    position: relative;
    box-shadow: 0px 0px 20px #888 inset;
    background: rgb(215,222,227); /* Old browsers */
    background: -moz-linear-gradient(top,  rgba(215,222,227,1) 0%, rgba(175,175,174,1) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(215,222,227,1)), color-stop(100%,rgba(175,175,174,1))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  rgba(215,222,227,1) 0%,rgba(175,175,174,1) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  rgba(215,222,227,1) 0%,rgba(175,175,174,1) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  rgba(215,222,227,1) 0%,rgba(175,175,174,1) 100%); /* IE10+ */
    background: linear-gradient(to bottom,  rgba(215,222,227,1) 0%,rgba(175,175,174,1) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d7dee3', endColorstr='#afafae',GradientType=0 ); /* IE6-9 */
}
.bgBox {
    height: 100%;
    width: 100%;
    z-index: 20;
    border-radius: 6px;
    position: absolute;
    background-size: 100% 100% !important;
}
.nameBox {
    /*
    border-radius: 6px 6px 0px 0px;
    width: 100%;
    text-align: left;
    z-index: 23;
    position: absolute;
    top: 0px;
    left: 0px;
    font-size: 1.2em;
    font-weight: 600;
    line-height: 2.2em;
    padding: .5% 0px .5% .2em;
    height: 15%;
    */
    border-radius: 6px 6px 0px 0px;
    font-size: 1.5vw;
    width: 100%;
    height: 18%;
    text-align: left;
    padding: .5% 0px .5% .2em;
    z-index: 23;
    position: absolute;
    top: 0px;
    left: 0px;
    font-weight: bold;
}
.price-hide .nameBox {
    width: 100% !important;
}
.price-hide .priceBox {
    display: none;
}
.imageBox {
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 82%;
    width: 65%;
    z-index: 22;
}
.imageBox img {
    border-radius: 0px 0px 0px 6px;
    z-index: 22;
    position: absolute;
    bottom: 5px;
    margin: auto;
    top:0;
    left:0;
    right:0;
}
.priceBox {
    position: absolute;
    right: 0;
    top: 15%;
    width: auto;
    font-size: .65vw;
    padding: 0 2% 2% 2%;
    text-align: center;
    z-index: 25;
}
.priceBox .price.price1 {
    font-size: 1em;
    margin-bottom: 5px;
    padding: 10px 0px 5px;
}
.priceBox .price.price2 {
    font-size: .8em;
    border-radius: 0 0 0 22%;
    margin: 0 -5px;
}
.priceBox span.gridCaption {
    font-size: 1.2em;
    font-weight: bold;
    font-style: italic;
    display: block;
    line-height:2em;
}
.priceBox .gridPrice {
    color: #C00;
    display: block;
    border-radius: 0px 6px 0px 0px;
    font-size: 5em;
    margin:0px;
    line-height: 0.7em;
}
.priceBox .gridPrice span.dollar {
    /*! font-size: 3.8em; */ /*! line-height: 1em; */
    padding: 0px .05em 0px 0px;
    position: relative;
}
.priceBox .gridPrice sup {
    font-size: 2em;
    line-height: 2.4em;
    vertical-align: top;
    position: relative;
}
.priceBox .gridPrice .decimals {
    font-size: .4em !important;
    font-weight: normal;
    vertical-align: baseline;
    top: -1em;
    position: relative;
}
.force-caption .gridCaption {
    display: block !important;
}

/*
* Time Widget
****************************/
.time-widget {
    text-shadow: 0 3px 3px #000 !important;
}
.time-widget #Date {
    font-size: .9vw;
    margin: .2vw 0px -5px 0px;
    text-align: center;
    font-style: italic;
    color: white;
    font-weight: bold;
}
.time-widget ul {
    padding: 0px;
    list-style: none;
    text-align: center;
    margin: 0px;
}
.time-widget li {
    display: inline;
    font-size: 3.5vw;
    padding: 0px .1vw;
    text-align: center;
    color: #000;
    opacity: 0.8;
    font-weight: bold;
    color: white;
}
.time-widget #point {
    position: relative;
    -moz-animation: mymove 1s ease infinite;
    -webkit-animation: mymove 1s ease infinite;
    padding: 0px 0px;
    margin: 0px -5px;
    color: white;
}
.time-widget #mer {
    font-size: 2.2vw;
}

#tv {
    position: relative;
    width: 250px;
    margin: 0px 0 0px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 50% / 10%;
    color: white;
    text-align: center;
    height: 100%;
}
#tv:before {
    content: '';
    position: absolute;
    top: 10%;
    bottom: 10%;
    right: -5%; left: -5%;
    border-radius: 5% / 50%;
}

/**
* Additional Animation Tests
**/

.rotate-slow {
    -webkit-animation-name: rotate-slow;
    animation-name: rotate-slow;
    animation-duration: 15s;
}

@keyframes rotate-slow {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    50% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }

    to {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}

.slide-img video {
    /* Make video to at least 100% wide and tall */
    min-width: 100%;
    min-height: 100%;

    /* Setting width & height to auto prevents the browser from stretching or squishing the video */
    width: 100%;
    height: 100%;

    /* Center the video */
    /*
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    */
    object-fit: cover;
}