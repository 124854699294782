@font-face {
    font-family: 'icomoon';
    src:url('../fonts/icomoon.eot?hm2c6x');
    src:url('../fonts/icomoon.eot?#iefixhm2c6x') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?hm2c6x') format('truetype'),
    url('../fonts/icomoon.woff?hm2c6x') format('woff'),
    url('../fonts/icomoon.svg?hm2c6x#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-display-icon:before {
    content: "\e6ee";
}
.icon-pos-icon:before {
    content: "\e6ef";
}
.icon-add-menu:before {
    content: "\e601";
}
.icon-add-slides:before {
    content: "\e602";
}
.icon-add-user:before {
    content: "\e603";
}
.icon-main-display:before {
    content: "\e604";
}
.icon-main-slides:before {
    content: "\e605";
}
.icon-main-user:before {
    content: "\e606";
}
.icon-manage-device:before {
    content: "\e607";
}
.icon-manage-display:before {
    content: "\e608";
}
.icon-manage-location:before {
    content: "\e609";
}
.icon-manage-menu:before {
    content: "\e60a";
}
.icon-manage-slides:before {
    content: "\e60b";
}
.icon-manage-user:before {
    content: "\e60c";
}
.icon-add-display:before {
    content: "\e60d";
}
.icon-add-location:before {
    content: "\e60e";
}
.icon-display:before {
    content: "\e600";
}
