.pagination {
	border-radius: 4px;
	display: table;
	margin: 15px 0 0px auto;
	padding-left: 0;
}
.top-subnav {
	border-left: none;
}
.top-subnav li {
	border: 1px solid #CCC;
}
.responsive .panel-default > .panel-heading {
	color: #fff;
	background-color: #27B0E5;
	border-color: #ddd;
	padding: 4px;
}
.row.widgetRow {
	margin-top: 8px;
}
.modal-body {
	height: auto;
}
.menu-settings .modal-body,
.menu-config .modal-body {
	overflow: auto;
}
/****************************
*
* Media Queries
**
****************************/
@media(max-width: 767px) {
	[class*=" calc-w-"].xs-calc {
		width: 100% !important;
	}
	.xs-center, .xs-center[class*=" text-"] {
		text-align: center !important;
	}
	.xs-left, .xs-left[class*=" text-"] {
		text-align: left !important;
	}
	.xs-right, .xs-right[class*=" text-"] {
		text-align: right !important;
	}
}

@media(min-width: 768px) and (max-width: 991px) {
	[class*=" calc-w-"].sm-calc {
		width: 100% !important;
	}
	.sm-center, .sm-center[class*=" text-"] {
		text-align: center !important;
	}
	.sm-left, .sm-left[class*=" text-"] {
		text-align: left !important;
	}
	.xs-right, .xs-right[class*=" text-"] {
		text-align: right !important;
	}
}

@media(min-width: 992px) and (max-width: 1199px) {
    [class*=" calc-w-"].md-calc {
        width: 100% !important;
    }
	.md-center, .md-center[class*=" text-"] {
		text-align: center !important;
	}
	.md-left, .md-left[class*=" text-"] {
		text-align: left !important;
	}
	.md-right, .md-right[class*=" text-"] {
		text-align: right !important;
	}
}


@media (min-width: 768px) {
	.form-horizontal .control-label {
		text-align: left !important;
	}
	.topnav-slider {
		left: 0 !important;
		width: auto !important;
	}
}
@media (max-width: 767px) {
	.navbar-container {
		padding-left: 15px;
	}
	.container-fluid > .navbar-header {
		margin-left: 0;
	}
	.navbar-brand {
		display: inline;
	}
	.navbar-brand img {
		float: left;
		max-width: 100%;
		margin: 6px 0 0 0;
	}
	.col-xs-12.main {
		padding: 20px 0 0 0;
	}
	.main h1 {
		font-size: 19px;
	}
	.main h1 .btn {
		font-size: 9px;
		padding: 3px 4px;
	}
	ul.nav-sidebar {
		margin: 0 20px 0 20px;
	}
	.nav-sidebar li {
		float: left;
		border: 1px solid #CCC;
		background: #EEE;
	}
	.nav-sidebar li.item .col-md-1 {
		padding: 0 10px 0 0;
	}
	h1 {
		font-size: 32px;
	}
	h2 {
		font-size: 27px;
	}
	h3 {
		font-size: 18px;
	}
	h4 {
		font-size: 16px;
	}
	.form-horizontal .checkbox,
	.form-horizontal .checkbox-inline,
	.form-horizontal .radio-inline {
		margin-left: 0;
		padding-top: 0;
	}
	.select2-container {
		width: 100% !important;
	}
	.dTable:not(.dTableNoResponsive) .dCell > div .select2-container {
		width: auto!important
	}
	.col-xs-m-5 {
		margin-top: 5px;
	}
	.flagLegend {
		display: block;
		clear: both;
		font-size: 12px;
	}
	.menu-item-selector {
		z-index: 99;
		position: static;
		float: right;
	}
	.display-content .display-toolbar .toolbar-name {
		font-size: 16px;
		float: none;
		line-height: 16px;
		width: auto;
		margin: 0;
		padding: 5px 5px 10px;
	}
	.menu-settings .font-conf,
	.menu-settings .font-preview-wrapper {
		width: 100%;
	}
	.minimal .priceBox {
		min-height: 0 !important;
	}
	.disabled-menu .gridLinks::after {
		font-size: 9px;
		padding: 2px;
		height: auto;
		line-height: 8px;
		top: auto;
		bottom: 0;
	}
	.slide-links-tools .btn-lg {
		font-size: 11px;
		padding: 5px 10px;
	}
	.display-toolbar {
		position: relative;
	}
	.display-toolbar .btn-group {
		float: left;
	}
	.display-toolbar .btn-group.pull-right {
		float: left !important;
	}
	.display-toolbar .btn-group.template-name {
		padding: 0 2px;
		position: absolute;
		right: 15px;
		text-align: right;
		top: 10px;
		width: auto;
	}
	.display-toolbar span.temp-name {
		line-height: normal;
	}
	.display-toolbar span.temp-name span {
		display: inline-block;
		font-size: 10px !important;
		padding: 5px;
		margin-left: 5px;
	}
	.form-horizontal .control-label {
		font-size: 13px;
	}
	.text-info {
		font-size: 11px;
	}
	.top-subnav li {
		margin: 10px 0 10px;
	}
	.top-subnav li + li {
		margin: 0 0 10px;
	}
	.sidebar {
		width: 100%;
		height: 50px;
	}
	.st-menu-open .main,
	.main {
		margin: 60px 0;
		padding: 0;
	}
	#nav-wrapper {
		height: auto;
		min-width: 50px;
	}
	.logo-surround {
		position: absolute;
		margin-bottom: 0;
		left: 0;
		margin-right: 20px;
	}
	.main-navigation {
		height: 50px;
		padding: 0 80px 0 80px;
		position: relative;
		width: auto;
	}
	.main-navigation ul {
		display: inline-block;
		padding-top: 7px;
	}
	.main-navigation li {
		display: inline;
		margin-bottom: 0;
		margin-right: 10px;
		padding: 0;
	}
	.main-navigation li a {
		display: inline-block;
		padding: 8px 0;
		width: 40px;
	}
	.sub-navigation {
		height: auto;
		position: fixed;
		transform: translateY(-100%);
		width: 100%;
		top: 50px;
	}
	.st-menu-open .sub-navigation {
		transform: translate(0px, 0);
	}
	.pagging-teaser {
		padding: 10px;
	}

	.menu-config .grid-preview-wrapper {
		height: 260px;
	}
	.disabled-menu .gridLinks {
		padding: 2px;
	}
	.disabled-menu .gridLinks::after {
		display: none;
	}
	.disabled-menu .gridLinks::before {
		font-size: 0.8em;
		padding: 1% 5%;
		position: relative;
		right: 5px;
		top: 0;
	}

	.xs-p0 {padding:0px!important;}

	.xs-pt {padding-top:7px!important;}
}
@media (min-width: 1200px) and (max-width: 1500px) {
    .dCell {
        font-size: 11px;
        padding: 4px;
    }
}
@media (min-width: 1200px) and (max-width: 1400px) {
	.menuSort.menuGridSort {
		padding: 0 8% 0;
		margin: 0;
	}
	.menuRow.gridCell .gridCellInner {
		min-height: 240px;
	}
	.menuRow.gridCell #menuItemName {
		font-size: 18px;
	}
	.menuRow.gridCell .priceCaption {
		font-size: 11px;
	}
	.menuRow.gridCell .priceValue {
		font-size: 16px;
	}
	.main.col-md-offset-1 {
		margin-left: 120px;
		width: calc(100% - 120px);
	}
	.nav-tabs li a {
		padding: 8px 9px;
		font-size: 12px;
	}
	.nav-tabs > li {
		padding: 0 6px 0 0;
	}
    .menu-size-tabs li a {
        padding: 8px 6px;
        font-size: 11px;
    }
    .menu-size-tabs li {
        padding: 0 6px 0 0;
    }
    .menu-size-tabs li.active a {
        padding: 8px 6px 15px 6px;
    }

	/*.gridster {
    column-count: 4;
    -moz-column-count: 4;
    -webkit-column-count: 4;
}*/
}

@media (max-width: 1260px) {
	/* DIV TABLE */

	.dTable:not(.dTableNoResponsive),
	.dTable:not(.dTableNoResponsive) .dRow,
	.dTable:not(.dTableNoResponsive) .dCell {
		display: block;
	}
	.dTable:not(.dTableNoResponsive) .dRow {
		float: left;
		width: 49%;
		margin-bottom: 15px;
	}

	.dTable:not(.dTableNoResponsive) .dRow:nth-child(2n) {
		margin-left:2%;
	}

	.dTable:not(.dTableNoResponsive) .dRow,
	.dTable:not(.dTableNoResponsive) .dCell {
		border: none;
	}
	/*
.dTable:not(.dTableNoResponsive) .dCell .visible-sm {
    display: block !important;
}
*/

	.cell-item-name .cell-title {
		display: none !important;
	}
	.cell-item-name .cell-content {
		width: 100% !important;
	}
	.dTable:not(.dTableNoResponsive) .dRow.content-row-wrap {
		background: #eee none repeat scroll 0 0;
		border: 1px solid #ddd;
		border-radius: 5px;
		box-shadow: 0 0 2px #ddd;
		padding: 0;
	}
	.dTable:not(.dTableNoResponsive) .dHeading {
		display: none;
	}
	.dTable:not(.dTableNoResponsive) .dCell {
		float: left;
		width: 100% !important;
		line-height: 20px;
		padding: 0;
		margin: 0;
	}
	.dTable:not(.dTableNoResponsive) .dRow.dFilters .dCell {
		background: #fff none repeat scroll 0 0;
		border-bottom: none;
		padding-bottom: 0;
	}
	.dTable:not(.dTableNoResponsive) .dCell > div {
		min-height: 30px;
		line-height: 25px;
		padding: 6px 10px;
	}
	.dTable:not(.dTableNoResponsive) .dRow.content-row-wrap .dCell:first-child {
		border-radius: 5px 5px 0 0;
	}
	.dTable:not(.dTableNoResponsive) .dRow.content-row-wrap .cell-flag {
		background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
		border: medium none !important;
		position: absolute;
		right: 5px;
		top: 5px;
		width: 35px !important;
	}
	.dTable:not(.dTableNoResponsive) .dRow.content-row-wrap {
		position: relative;
	}
	/*.dCell.cell-item-name {
        border-radius: 6px 6px 0 0;
    }*/

	.dRow.content-row-wrap .dCell:last-child {
		border-bottom: medium none;
		border-bottom-right-radius: 5px;
		border-top-right-radius: 5px;
	}
	.dRow.content-row-wrap .dCell:last-child .cell-title {
		border-bottom-left-radius: 5px;
	}
	.dRow.content-row-wrap .dCell {
		border-bottom: 1px solid #DDD;
		clear: both;
	}
	.dCell .cell-title {
		background: #fff none repeat scroll 0 0;
		border-right: 1px solid #ddd;
		box-shadow: 0 0 2px #ccc;
		float: left;
		font-weight: 600;
		padding: 12px 10px;
		text-align: left;
		width: 170px;
		line-height: 16px;
	}
	.dCell .cell-content {
		float: left;
		width: 65%;
		width: calc(100% - 170px);
		text-align: left;
	}
	.dCell.cell-item-name {
		background: #263456;
		position: relative;
	}
	.dCell.cell-item-name .cell-title {
		display: none !important;
	}
	.dCell.cell-item-name .cell-content a.btn-block {
		color: white;
		font-size: 14px;
		font-weight: 500;
		line-height: 28px;
		/*padding: 6px 15px;*/
	}
	.dCell.cell-item-name .cell-content table {
		background: rgba(255, 255, 255, 0.1) none repeat scroll 0 0;
		color: rgba(255, 255, 255, 0.8);
		font-size: 14px;
		margin: 0;
		position: relative;
		width: 100%;
	}
	.dCell.cell-item-name .cell-content .block {
		padding: 0 15px;
	}
	.dCell.cell-item-name .cell-content table td {
		border-bottom: 1px solid rgba(0, 0, 0, 0.2);
		padding: 2px 10px;
	}
	.dCell.cell-item-name .cell-content table tr td:first-child {
		width: 170px;
	}
	.dCell a.btn:after {
		content: attr(data-title);
		margin-left: 5px;
	}
	.dCell a.btn.delete {
		padding-left: 16px;
	}
	.dCell.cell-item-name a.btn:after {
		content: "";
		margin: 0;
	}
	.dCell a.btn {
		font-size: 11px;
		margin-bottom: 3px !important;
		margin-top: 3px;
		position: relative;
	}
	.dCell a.btn .glyphicon.glyphicon-remove.glyph-sm {
		left: 5px;
		position: absolute;
		top: 4px;
	}
	.dCell a.btn .glyph-sm {
		font-size: 11px !important;
	}
	.dFilters .search-input-table,
	.dFilters .select2 {
		margin: 0 0 10px;
	}
	.responsive .panel-default > .panel-heading {
		color: #fff;
		background-color: #27B0E5;
		border-color: #ddd;
		padding: 4px;
	}
	.responsive .panel-default .panel-heading a {
		display: block;
		padding: 10px 15px;
		line-height: 16px;
	}
	.responsive .panel-default .panel-heading a small {
		color: #4E4E4E;
		float: right;
		font-weight: bold;
		margin-top: 1px;
	}
	.category-navigation li a {
		font-size: 12px;
	}
	.category-navigation li {
		height: 44px;
		margin-right: 5px;
	}
	.category-navigation li i {
		font-size: 13px;
		display: none;
	}
	.category-navigation li.active a {
		padding: 11px 12px 12px;
		font-size: 13px;
	}
	.category-navigation li a {
		padding: 8px 10px;
	}
	.dTable .dRow:nth-child(2n) .dCell {
		background: transparent;
	}
	.dTable .dRow:nth-child(2n) .dCell.cell-item-name {
		background: #263456 none repeat scroll 0 0;
	}
	.dTable {
		border: none;
	}
	.dRow.dFilters {
		background: #fff none repeat scroll 0 0;
		border: 1px solid #ccc;
		clear: both;
		float: left;
		display: inline-block;
		padding: 10px;
		width: 100%;
	}
	.gridster .grid-item {
		margin: 5px 0.5%;
		width: 24%;
	}
}

@media (max-width: 1160px) {
	.displayModal .modal-dialog {
		width: 960px;
	}
	.displayModal .modal-content {
		width: 100%;
	}
}



@media (max-width: 992px) {
	body.admin .sidebar {
		padding-top: 0;
	}
	.dRow.content-row-wrap {
		width: 100% !important;
		margin: 0 0 15px;
	}

	.dTable:not(.dTableNoResponsive) .dRow:nth-child(2n) {margin-left:0px;}

	.displayModal .modal-dialog {
		max-width: 90%;
		margin-left: auto;
		margin-right: auto;
	}
	.displayModal .modal-content {
		width: 100%;
		height: auto;
	}
	#dt_open {
		margin-bottom: 5px;
	}
}

@media (min-width: 960px) and (max-width: 1199px) {
	body {
		font-size: 12px;
	}
	h1 {
		font-size: 32px;
	}
	h2 {
		font-size: 27px;
	}
	h3 {
		font-size: 22px;
	}
	h4 {
		font-size: 17px;
	}
	.navbar-brand img {
		margin-top: 11%;
		max-width: 90%;
	}
	.navbar-brand,
	.sidebar {
		width: 120px;
	}
	.main.col-md-offset-1 {
		margin-left: 120px;
		width: calc(100% - 120px);
	}
	.stats-item {
		height: 100px;
	}
	.stats-item .icon-large,
	.stats-item .text-large {
		font-size: 35px !important;
	}
	.stats-item span {
		font-size: 12px;
	}
	.menuSort.menuGridSort {
		padding: 0 0 0;
		margin: 0;
	}
	.display-toolbar .btn-group a {
		font-size: 10px;
		padding: 5px 5px;
	}
	.display-content .display-toolbar .toolbar-name {
		font-size: 15px;
	}

	.pagination > li > a,
	.pagination > li > span {
		padding: 6px 15px;
	}
}
@media (max-width: 959px) {
	.category-navigation li i {
		margin-right: 0;
		font-size: 15px;
	}
	.category-navigation li .subnav-title {
		display: none;
	}
	.category-navigation li a {
		padding: 7px 10px;
	}
	.category-navigation li {
		height: 38px;
	}
	.category-navigation li a i {
		display: block;
	}
	.category-navigation li.active a {
		padding: 10px 10px 11px;
	}
	.pagination > li > a,
	.pagination > li > span {
		padding: 5px 13px;
	}
	.gridster .grid-item {
		margin: 5px 0.5%;
		width: 32.33%;
	}
}
@media (min-width: 768px) and (max-width: 959px) {
	h1 {
		font-size: 28px;
	}
	.nav-main .nav > li > a {
		font-size: 10px;
		padding: 15px 6px 15px 6px;
	}
	.navbar-brand img {
		margin-top: 15%;
		max-width: 98%;
	}
	.form-horizontal .control-label {
		text-align: left;
		font-size: 13px;
		margin: 0 0 5px;
	}
	.sidebar #quick-links {
		margin: 5px 0;
	}
	.menuSort.menuGridSort {
		padding: 0 0 0;
		margin: 0;
	}
	.menuRow.gridCell .gridCellInner {
		min-height: 180px;
	}
	.menuRow.gridCell #menuItemName {
		font-size: 14px;
	}
	.menuRow.gridCell img {
		max-height: 100px;
		width: auto;
	}
	.menuRow.gridCell .price-container {
		top: 40px;
		width: 37%;
	}
	.menuRow.gridCell .priceCaption {
		font-size: 10px;
	}
	.menuRow.gridCell .priceValue {
		font-size: 14px;
	}
}
@media (min-width: 481px) and (max-width: 767px) {} @media (max-width: 480px) {
	body > .container-fluid {
		padding: 0;
	}
	.navbar-brand {
		padding: 15px 0 15px 10px;
	}
	.nav-sidebar li {
		width: 100%;
	}
	.menuRow.gridCell {
		width: 100%;
	}
	.menuSort.menuGridSort {
		padding: 0 0 0;
		margin: 0;
	}
	.menuRow.gridCell .gridCellInner {
		min-height: 200px;
	}
	.menuRow.gridCell #menuItemName {
		font-size: 13px;
	}
	.menuRow.gridCell img {
		max-height: 120px;
		width: auto;
	}
	.menuRow.gridCell .price-container {
		top: 35px;
		width: 37%;
	}
	.menuRow.gridCell .priceCaption {
		font-size: 10px;
	}
	.menuRow.gridCell .priceValue {
		font-size: 14px;
	}
	.flagLegend .flaglegendItem {
		font-size: 11px;
	}
	.btn-group-sm > .btn,
	.btn-sm {
		padding: 5px 6px;
		font-size: 10px;
		line-height: 1.25;
		border-radius: 3px;
	}
	.dRow .dCell {
		font-size: 11px;
	}
	.dRow .dCell a.btn {
		margin-right: 3px;
		margin-bottom: 5px;
		font-size: 11px;
	}
	.dRow .dCell span.glyphicon {
		font-size: 11px !important;
		padding: 3px 1px;
	}
	.dCell .cell-title {
		width: 100px;
	}
	.dCell .cell-content {
		width: calc(100% - 100px)
	}
	.dCell.cell-item-name .cell-content table tr td:first-child {
		width: 100px;
	}
	.stats-item {
		width: 100%;
		margin-right: 0;
	}
	.stats-item:nth-child(even) {
		margin-right: 0;
	}

	.display-toolbar .btn-group.template-name {
		right: 10px;
		top: 0;
		width: 50%;
	}
	.display-toolbar .btn-group,
	.btn-toolbar .input-group {
		float: left;
		margin: 0;
		width: 50%;
	}
	.display-toolbar .btn-group,
	.btn-toolbar .input-group {
		float: left;
		margin: 0;
		width: 50%;
	}
	.display-toolbar .btn-group.btn-dropdown .btn {
		width: auto;
	}
	.btn-toolbar {
		margin-left: 0;
	}
	.btn-toolbar .btn {
		width: 100%;
	}
	.menu-config .preview-2 .grid-preview .minimal .priceBox,
	.priceBox {
		font-size: 1.5vw;
	}
	.menu-config .preview-2 .grid-preview .minimal .nameBox,
	.nameBox {
		font-size: 3.5vw;
	}
	.menu-config .grid-preview-inner.preview-2 {
		width: auto;
		margin: 0 30px;
	}
	.menu-config .grid-preview-wrapper {
		height: 220px;
	}
}
@media (min-width: 768px) and (max-width: 992px) {
	.sidebar ul.nav-sidebar li {
		float: left;
		width: 100px;
	}
	.sidebar ul.nav-sidebar li .sidebar-icon {
		font-size: 35px;
	}
	.nav-sidebar .sidebar-title {
		font-size: 11px;
	}
	.col-xs-12.main {
		padding: 20px 0 0 0;
	}
	.nav-sidebar .sidebar-link {
		padding: 10px 0 0;
	}
	.nav-sidebar li.item {
		margin-bottom: 0;
	}
	.col-md-offset-1 {
		margin-left: 0 !important;
	}
	.main.col-md-offset-1 {
		width: 100% !important;
	}
}
@media (max-width: 630px) {
	.search-box .col-md-10 {
		width: 100%;
	}
	.search-box .col-md-2 {
		width: 150px;
	}
	.search-box .col-md-2 .searchBy {
		margin: 5px 0 0 13px;
	}
	.menuRow.gridCell {
		width: 100%;
	}
	.gridster {
		/*	column-count: 1;
    -moz-column-count: 1;
    -webkit-column-count: 1;*/
	}
}
/* File Upload */

.fileinput-button {
	position: relative;
	overflow: hidden;
	display: inline-block;
}
.fileinput-button input {
	position: absolute;
	top: 0;
	right: 0;
	margin: 0;
	opacity: 0;
	-ms-filter: 'alpha(opacity=0)';
	font-size: 200px;
	direction: ltr;
	cursor: pointer;
}
/* Fixes for IE < 8 */

@media screen\9 {
	.fileinput-button input {
		filter: alpha(opacity=0);
		font-size: 100%;
		height: 100%;
	}
}
.fileupload-buttonbar {
	padding-top: 20px;
}
.fileupload-buttonbar .btn,
.fileupload-buttonbar .toggle {
	margin-bottom: 5px;
}
.progress-animated .progress-bar,
.progress-animated .bar {
	background: url("../img/progressbar.gif") !important;
	filter: none;
}
.fileupload-process {
	float: right;
	display: none;
}
.fileupload-processing .fileupload-process,
.files .processing .preview {
	display: block;
	width: 32px;
	height: 32px;
	background: url("../images/loading.gif") center no-repeat;
	background-size: contain;
}
.files audio,
.files video {
	max-width: 300px;
}
@media (max-width: 767px) {
	.fileupload-buttonbar .toggle,
	.files .toggle,
	.files .btn span {
		display: none;
	}
	.files .name {
		width: 80px;
		word-wrap: break-word;
	}
	.files audio,
	.files video {
		max-width: 80px;
	}
	.files img,
	.files canvas {
		max-width: 100%;
	}
	.top-subnav li {
		border-right: 1px solid #CCC;
		border-top: 1px solid #CCC;
		display: block;
		width: 100%;
		text-align: center;
	}
	.pagging-teaser .pagination {
		margin: 0 auto;
		transform: translateX(0px);
	}
	.treeview-container {
		display: block;
		min-height: 200px;
		overflow: auto;
	}
	.categoryblock,
	.treeview-sidebar {
		display: block;
	}
	.categoryblock {
	    padding-left: 70px;
	    width: auto;
	}
	.treeview-sidebar {
		bottom: 0;
		left: 0;
		overflow: auto;
		padding-right: 70px;
		position: absolute;
		right: 0;
		top: 0;
		transform: translateX(calc(-100% + 50px));
		width: 100%;
		z-index: 99;
		transition: all 0.5s ease 0s;
		webkit-transition: all 0.5s ease 0s;
	}
	.treeview-container .back-trigger,
	.treeview-container .back-trigger-menu {
		background: rgba(50, 145, 225, 0.6) none repeat scroll 0 0;
		bottom: 0;
		color: rgba(255, 255, 255, 0.8);
		right: 0;
		font-size: 26px;
		line-height: 50px;
		position: absolute;
		text-align: center;
		top: 0;
		font-family: Font Awesome\ 5 Pro;
		width: 50px;
		transition: all 0.5s ease 0s;
		webkit-transition: all 0.5s ease 0s;
	}
	.treeview-container .back-trigger:hover,
	.treeview-container .back-trigger-menu:hover {
		background: rgba(50, 145, 225, 1) none repeat scroll 0 0;
		color: #fff;
	}
	.back-trigger-menu::before {
		content: "\f0a9";
	}
	.treeview-sidebar .back-trigger::before {
		content: "\f0a8";
	}
	.treeview-container.open .treeview-sidebar {
		transform: translateX(0%);
	}
	.treeview-container.open .treeview-sidebar .back-trigger {
		left: auto;
		right: 0;
	}
	.dTable:not(.dTableNoResponsive) .dCell .hidden-xs {
		display: none !important;
	}
	.pagging-teaser {
		background: rgba(0, 0, 0, 0.8) none repeat scroll 0 0;
		border-top: 1px solid #ddd;
		bottom: 0;
		left: 0;
		padding: 15px;
		z-index:9;
		position: fixed;
		width: 100%;
	}
	.pagging-teaser .pagination {
		margin: 0 auto;
	}
}
@media (max-width: 602px) {
	.dCell a.btn:after {
		content: "";
		margin-left: 0;
	}
	.dCell a.btn {
		/*margin-top: 3px;*/
		/*margin-bottom: 2px !important;*/
	}
	.dCell a.btn .glyphicon.glyphicon-remove.glyph-sm {
		position: static;
	}
	.dCell a.btn.delete {
		padding: 1px 4px;
	}
	.gridster .grid-item {
		margin: 5px 1%;
		width: 48%;
	}
	.col-xxs-12 {
		width: 100%;
		position: relative;
	}
	.xs-text-right {text-align:right;}


}


@media (min-width: 1199px) {
	.dRow.dFilters .select2 {
		width: 100% !important;
	}
}

@media (max-width: 1399px) {
	.dRow.dFilters .select-sm.dCell .select2 {
		max-width:90px!important;
	}
	.dRow.dFilters .select-md.dCell .select2 {
		max-width:140px!important;
	}
}



/*@media (max-width: 1398px) {
	.dRow.dFilters .select2 {
		max-width: 130px !important;
		min-width: 70px !important;
	}

}*/

@media (max-width: 1260px) {
	.dRow.content-row-wrap .dCell {
		display: flex;
	}
}



@media (max-width: 440px) {

	.col-xxs-push-1 {
		left: 8.333%;
	}

	.col-xxs-push-4 {
		left: 33.33%;
	}

	.xxs-text-left {
		text-align: left;
	}

	.xs-w140 {width:140px;}

	.xxs-text-right {
		text-align: right;
	}

	.col-xxs-9 {
		width: 75%;
	}

	.col-xxs-5 {
		width: 41.6667%;
	}

	.col-xxs-7 {
		width: 58.3333%;
	}

	.xs-col-12 {
		width: 100%;
	}

	.col-xxs-pull-1 {right:8.33%;}

	.xxs-p0 {padding:0px!important;}

	.xxs-pt-lg {padding-top:24px!important;}

	.b-xxs {
		border: solid 1px #ddd;
		padding: 10px;
		background: #f4f4f4;
		margin: 0 0 10px;
	}

}


@media (max-width: 399px) {
	.alert-sm h4 {
		font-size: 12px !important;
	}
	.alert input[type="radio"] {
		margin-top: 0 !important;
	}
	.main-navigation li a {
		padding: 6px 0;
		width: 32px;
	}
	.main-navigation ul {
		padding-top: 10px;
	}

	.xs-mfw {max-width:100%!important;}



}

@media (max-width: 360px) {
	.main h1 {
		font-size: 14px;
		padding: 0 0 0;
	}
	.pagination > li > a {
		font-size: 11px;
		padding: 6px 10px;
	}
	.filter-toolbar a.btn {
		margin-top: 8px;
	}
	.gridster .grid-item {
		margin: 0;
		width: 100%;
	}
	.xs-w140 {width:auto!important;}
}

