/*************************************
*
* Grid Widths
*
**************************************/

/*
* Column Styles
******************/

.flex-col-span-1-5 {
    width: 15% !important;
}
.flex-col-span-1-65 {
    width: -webkit-calc(33.3% / 2) !important;
    width: -moz-calc(33.3% / 2) !important;
    width: calc(33.3% / 2) !important;
}
.flex-col-span-1-75 {
    width: 17.5% !important;
}
.flex-col-span-2 {
    width: 20% !important;
}
.flex-col-span-2-5 {
    width: 25% !important;
}
.flex-col-span-3 {
    width: 30% !important;
}
.flex-col-span-3-5 {
    width: 35% !important;
}
.flex-col-span-4 {
    width: 40% !important;
}
.flex-col-span-4-5 {
    width: 45% !important;
}
.flex-col-span-5 {
    width: 50% !important;
    height: 100%;
}
.flex-col-span-5-5 {
    width: 55% !important;
}
.flex-col-span-6 {
    width: 60% !important;
}
.flex-col-span-6-6 {
    width: -webkit-calc((100% / 3) * 2) !important;
    width: -moz-calc((100% / 3) * 2) !important;
    width: calc((100% / 3) * 2) !important;
}
.flex-col-span-6-5 {
    width: 65% !important;
}
.flex-col-span-7 {
    width: 70% !important;
}
.flex-col-span-7-5 {
    width: 75% !important;
}
.flex-col-span-8 {
    width: 80% !important;
}
.flex-col-span-8-5 {
    width: 85% !important;
}
.flex-col-span-9 {
    width: 90% !important;
}
.flex-col-span-9-5 {
    width: 95% !important;
}
.flex-col-span-10 {
    width: 100% !important;
}

/*************************************
*
* Flex Basics
*
**************************************/

.menu-flex-container {
    height: 100%;
    padding: 2px 3px 2px 3px;

    /*Flex Props*/
    display: -webkit-box;
    -webkit-box-orient:vertical;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-flow: column wrap;
    -moz-flex-flow: column wrap;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
}
.menu-flex-column {
    position: relative;
    height: 100%;
    width: 33.3%;
    /*Flex Props*/
    display: -webkit-box;
    -webkit-box-orient:vertical;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-flow: column wrap;
    -moz-flex-flow: column wrap;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
}
.menu-flex-item {
    height: 33.3334% !important;
    width: 100%;
    padding: 3px;
}
.menu-flex-item-inner {
    position: relative;
    border-radius: 6px;
    height: 100%;
}
.menu-flex-placeholder {
    background: rgba(255, 255, 255, 0.55);
    height: 33.3334%;
    padding: 3px;
    border-radius: 6px;
}
.menu-flex-row-below {
    height: 33.3%;
    padding: 0 3px 2px 3px;
    /*Flex Props*/
    display: -webkit-box;
    -webkit-box-orient:vertical;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-flow: row wrap;
    -moz-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
}
.menu-flex-row-below .menu-flex-item {
    height: 100% !important;
}
.flex-row-sub-container {
    padding: 0px;
}
.menu-flex-sub {
    height: 100%;
    /*Flex Props*/
    display: -webkit-box;
    -webkit-box-orient:vertical;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-flow: row wrap;
    -moz-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
}
.menu-flex-sub-item {
    padding: 3px;
}
.menu-flex-sub-item-inner  {
    position: relative;
    background: white;
    border-radius: 6px;
    box-shadow: 0 0 6px #888;
    height: 100%;
}
.menu-flex-sub-item-inner,
.menu-flex-item-inner,
.viewBox {
    width: 100% !important;
    height: 100% !important;
}
.drop-hover .menu-flex-item-inner,
.drop-hover .menu-flex-sub-item-inner,
.drop-hover .slide-wrapper {
    border: 3px dashed darkblue !important;
}
.viewBox {
    position: relative;
    border-radius: 6px;
    background-repeat: no-repeat;
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    background-size: 100% 100%;
    /*box-shadow: 0px 0px 3px #888;*/
    z-index: 1;
}
.viewBox:before,
.viewBox:after {
    border-radius: 6px;
}

/*
* Grid Row Styles
******************/

.flex-row-span-3 {
    height: 100% !important;
}
.flex-row-span-2 {
    height: 66.6666% !important;
}
.flex-row-span-25 {
    height: 25% !important;
}
.flex-row-span-40 {
    height: 40% !important;
}
.flex-row-span-45 {
    height: 45% !important;
}
.flex-row-span-50 {
    height: 50% !important;
}
.flex-row-span-60 {
    height: 60% !important;
}
.flex-row-span-75 {
    height: 75% !important;
}
.menu-flex-item .bx-wrapper,
.bx-wrapper .bx-viewport,
.bx-wrapper .bx-viewport ul.slider,
.bx-wrapper .bx-viewport ul.slider img {
    border-radius: 6px !important;
}

/*
* Flex Adjustments (Sub Rows / Padding)
*
******************/
.menu-flex-row-below .flex-col-span-3 {
    width: 33.3% !important;
}
.flex-row-span-5 {
    height: 50% !important;
}
.flex-altrow-count-1,
.flex-altrow-count-2 {
    height: 66.6%;
}
.flex-addrow-count-2 {
    height: 16.65%;
    height: -webkit-calc(33.3% / 2) !important;
    height: -moz-calc(33.3% / 2) !important;
    height: calc(33.3% / 2) !important;
}
.flex-altrow-count-1 .menu-flex-item,
.flex-altrow-count-2 .menu-flex-item {
    height: 50% !important;
    width: 100%;
}
.flex-altrow-count-1 .flex-row-span-2,
.flex-altrow-count-2 .flex-row-span-2 {
    height: 100% !important;
    width: 100%;
}
.flex-altrow-count-3 {
    height: 50%;
}
.flex-altrow-count-3 .flex-row-span-2 {
    height: 100% !important;
}
.flex-addrow-count-3 {
    height: 16.6667%;
    height: -webkit-calc(50% / 3) !important;
    height: -moz-calc(50% / 3) !important;
    height: calc(50% / 3) !important;
}
.menu-flex-sub-item.flex-col-span-3 {
    width: 33.3% !important;
    height: 100%;
}
.display-wrapper .flex-no-padding,
.display-wrapper .flex-no-padding *,
.display-wrapper .flex-no-padding .bx-wrapper ul.slider,
.display-wrapper .flex-no-padding .bx-wrapper ul.slider img {
    padding: 0px !important;
    border-radius: 0px !important;
}
/* flex-div-3 */
.flex-div-3 .menuRow:not(.minimal) .image-size-245_290 .nameBox,
.flex-div-3 .menuRow:not(.minimal) .image-size-664_352 .nameBox {
    width: 59%;
}
.flex-div-3 .menuRow:not(.minimal) .image-size-245_290 .imageBox,
.flex-div-3 .menuRow:not(.minimal) .image-size-664_352 .imageBox {
    width: 60%;
}
.flex-div-3 .menuRow:not(.minimal) .image-size-245_290 .priceBox .price1,
.flex-div-3 .menuRow:not(.minimal) .image-size-664_352 .priceBox .price1 {
    font-size: .75vw;
}
.flex-div-3 .menuRow:not(.minimal) .image-size-245_290 .priceBox,
.flex-div-3 .menuRow:not(.minimal) .image-size-664_352 .priceBox {
    width: 41%;
    margin: 0px;
    padding: 2px 3px 20px 1px;
    font-size: .6vw;
}

.has-top-bar .flex-altrow-count-1,
.has-top-bar .flex-altrow-count-2 {
    height: -webkit-calc(66.6% + 40px) !important;
    height: -moz-calc(66.6% + 40px) !important;
    height: calc(66.6% + 40px) !important;
}
.has-top-bar .menu-flex-row-below {
    height: -webkit-calc(33.3% - 40px) !important;
    height: -moz-calc(33.3% - 40px) !important;
    height: calc(33.3% - 40px) !important;
}


/*************************************
*
* Menu Grid Layouts
*
**************************************/
/*
* Layout Adjustments - Basic / Reverse
*
******************/
.flex-col-span-2 .nameBox {
    font-size: 1.6vw;
    padding-top: 2%;
}
.flex-col-span-2 .imageBox {
    width: 57%;
}
.flex-col-span-2 .priceBox {
    width: 43%;
    font-size: .575vw
}

/*
* Menus - Layout Minimal
*
******************/
.basic-reverse .imageBox{
    float: right;
}
.basic-reverse .imageBox img {
    right: 0px;
    left: auto;
}
.basic-reverse .priceBox {
    left: 0px;
}
.basic-reverse .priceBox {
    padding-left: 3%;
}

/*
* Menus - Layout Minimal
*
******************/
.minimal .nameBox {
    border-radius: 6px 0px 0px 0px;
    width: 68%;
    font-size: 1.5vw;
    height: auto !important;
}
.minimal .priceBox {
    top: 0 !important;
    right: 0 !important;
    margin: 0px;
    border-radius: 0px 6px 0px 25%;
    overflow: hidden;
   /* min-height: 75px;*/
    padding: 2% 0% 2% 0%;
    border-width: 0px;
    width: 32%;
    z-index: 23;
    text-align: center;
    -webkit-border-image:  -webkit-gradient(linear, 0 0, 0 100%, from(#999), to(transparent)) 1 100%;
    -webkit-border-image: -webkit-linear-gradient(#999, transparent) 1 100%;
    -moz-border-image: -moz-linear-gradient(#999, transparent) 1 100%;
    -o-border-image: -o-linear-gradient(#999, transparent) 1 100%;
    border-image: linear-gradient(to bottom, #999, transparent) 1 100%;
}
.minimal .priceBox .price.price1 {
    padding: 5px 0;
}
.minimal .imageBox {
    width: 67%;
}
.minimal .price1 .gridCaption {
    display: none;
}
/*
.menu-flex-item .minimal .priceBox .price:not(.price1) {
    font-size: .6vw;
}
.menu-flex-item .minimal .priceBox .price1:not(.gridPrice) {
    font-size: .7vw;
}
*/
.flex-col-span-1-65 .minimal .priceBox .price:not(.price1) {
    font-size: .5vw;
}
.menu-flex-sub-item.flex-col-span-1-65 .minimal .priceBox .price:not(.price1) .gridCaption {
    font-size: .6vw;
}

/*
* Layout Adjustments - Minimal
*
******************/
/**
* flex-col-span-1-65
**/
.flex-col-span-1-65 .minimal .nameBox {
    width: 60%;
    font-size: 1.15vw;
    line-height: 1.6vw;
    height: auto;
    padding-bottom: 2%;
}
.flex-col-span-1-65 .minimal .imageBox {
    width: 60%;
}
.flex-col-span-1-65 .minimal .priceBox {
    width: 40%;
}

.flex-col-span-1-65 .minimal .priceBox .price:not(.price1) {

}
.flex-col-span-1-65 .minimal .priceBox .price1:not(.gridPrice) {

}


/**
* flex-col-span-2
**/
.flex-col-span-2 .minimal .nameBox {
    width: 60%;
    font-size: .9vw;
    line-height: 1.6vw;
    height: auto;
    padding-bottom: 2%;
}
.flex-col-span-2 .minimal .imageBox {
    width: 60%;
}
.flex-col-span-2 .minimal .priceBox {
    width: 40%;
    font-size: .52vw;
}
.menu-flex-sub-item.flex-col-span-3 .minimal .priceBox
.flex-col-span-2 .minimal .priceBox {
    padding: 0 1% 0 1%;
    border-width: 0 0 0 2px;
}
.menu-flex-sub-item.flex-col-span-3 .minimal .priceBox .price:not(.price1) .gridCaption,
.flex-col-span-2 .minimal .priceBox .price:not(.price1) .gridCaption {
    font-size: .6vw;
}

/**
* flex-col-span-3
**/
.flex-col-span-3 .menuRow:not(.minimal) .nameBox,
.flex-col-span-3 .viewBox:not(.minimal) .nameBox {
    font-size: 1.3vw;
    line-height: 2vw;
}

.menu-flex-sub-item.flex-col-span-3 .minimal .nameBox {
    width: 60%;
    font-size: 1.1vw;
    line-height: 2vw;
    height: auto;
    padding-bottom: 2%;
}
.menu-flex-sub-item.flex-col-span-3 .minimal .imageBox {
    width: 60%;
}
.menu-flex-sub-item.flex-col-span-3 .minimal .priceBox {
    width: 40%;
}

/**
* flex-col-span-5
**/
.displayWrapper .flex-col-span-5:not(.menu-flex-sub-item) .nameBox {
    font-size: 2.5vw;
}
.displayWrapper .flex-col-span-5:not(.menu-flex-sub-item) .viewBox.minimal .priceBox {
    font-size: 1.2vw;
}

/**
* flex-row-span-5
**/
.flex-row-span-5 .priceBox {
    font-size: .8vw;
}
.flex-row-span-5 .minimal .priceBox .price.price1 {
    margin: 10px 0 0 !important;
}
.flex-row-span-5 .priceBox span.gridCaption {
    line-height: 3em;
}

/***** Display Menu Grid Paddings ****/

/* No Padding */
.displayWrapper.padding-no .bx-wrapper,
.displayWrapper.padding-no .bx-wrapper .bx-viewport,
.displayWrapper.padding-no .bx-viewport ul.slider,
.displayWrapper.padding-no .bx-viewport ul.slider img,
.displayWrapper.padding-no .menu-flex-item-inner,
.displayWrapper.padding-no .menuRow,
.displayWrapper.padding-no .menuRow.gridCell,
.displayWrapper.padding-no .bgBox,
.displayWrapper.padding-no .viewBox,
.displayWrapper.padding-no .menu-flex-item,
.displayWrapper.padding-no .menu-flex-sub-item,
.displayWrapper.padding-no .menu-flex-container,
.displayWrapper.padding-no .design-block .layer-output {
    padding: 0 !important;
    border-radius: 0 !important;
}
.displayWrapper.padding-no .priceBox {
    border-radius: 0 0 0 25% !important;
}

/* Medium Padding */
.displayWrapper.padding-md .menu-flex-sub-item,
.displayWrapper.padding-md .menu-flex-item:not(.flex-row-sub-container) {
    padding: 1px !important;
}

/* Large Padding */
.displayWrapper.padding-lg .menu-flex-sub-item,
.displayWrapper.padding-lg .menu-flex-item:not(.flex-row-sub-container) {
    padding: 3px !important;
}