/*Custom Style CC*/

/*
** Base Styling
****************************/

html {
	height: 100%;
}
body {
	min-height: 100%;
}
body {
	font-size: 13px;
}
a, a:hover, table.a, .table a:not(.btn) {
	text-decoration: none;
}
a:focus {
	outline: none;
}
.middle-panel {
	margin-top: 25%;
}
.hidden {
	display: none;
}
img {
	max-width: 100%;
	height: auto;
}
iframe {
	max-width: 100%;
}
.em {
	font-style: italic;
}
.strong {
	font-weight: bold;
}
.block {
	display: block;
}
.inline {
	display: inline;
}
.inline.hide,
.inline.hidden {
	display: none !important;
}
.hasCursor {
	cursor: pointer;
}
.unselectable {
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}
canvas {
	max-width: 100%;
}
.scroll {
	overflow: scroll;
}
.scroll-y {
	overflow-y: scroll;
}
.scroll-y-auto {
	overflow-y: auto;
}
.scroll-x {
	overflow-x: scroll;
}
.scroll-x-auto {
	overflow-x: auto;
}
.pointer {
	cursor: pointer;
}
.list-relations {
	max-height: 250px;
	overflow-y: auto;
}
.navbar-container {
	padding-left: 0;
	border-bottom: 1px solid #5BC0EE;
	box-shadow: 0 0 5px #666;
}
.navbar-brand, .sidebar {
	max-width: 100%;
}
.navbar-brand {
	padding: 0;
	display: block;
	float: none;
	margin: 0 !important;
}
.navbar-brand img {
	display: initial;
	margin-top: 5%;
	max-width: 95%;
}
.navbar-header {
	text-align: center;
	padding: 0 0;
}
ul.nav li.dropdown > ul.dropdown-menu {
	margin: 0;
	visibility: hidden;
	display: block;
	opacity: 0;
	-webkit-transition: opacity 0.5s ease-in-out;
	-moz-transition: opacity 0.5s ease-in-out;
	-o-transition: opacity 0.5s ease-in-out;
	transition: opacity 0.5s ease-in-out;
}
ul.nav li.dropdown:hover > ul.dropdown-menu {
	visibility: visible;
	opacity: 1;
	display: block;
	margin: 0;
	background: #E3E3E3;
	box-shadow: 0 3px 3px #888;
}
.clear {
	height: 0;
	width: 0;
	margin: 0;
	padding: 0;
	clear: both;
}
.label a {
	color: white;
}
body {
	background: #f3f2f7;
	font-family: 'Roboto', sans-serif;
	color: #585f69;
	padding-right: 0 !important;
}
a:hover {
	text-decoration: none;
}
a:focus {
	outline: none;
	text-decoration: none;
}
ul, li {
	list-style: none;
	margin: 0;
	padding: 0;
}
.h4, .h5, .h6, h4, h5, h6 {
	color: #333;
}
.video-thumbnail {
	float: left;
	width: 200px;
}
.video-desc {
	float: left;
	width: -webkit-calc(100% - 200px);
	width: -moz-calc(100% - 200px);
	width: calc(100% - 200px);
}
.grey-thumbnail {
	background: #999;
	padding: 15px;
}
.input-group .input-sm + .input-group-btn .btn {
    padding: 5px 10px 5px 10px;
}
.slow .toggle-group { transition: left 0.7s; -webkit-transition: left 0.7s; }
.medium .toggle-group { transition: left 0.4s; -webkit-transition: left 0.4s; }
.fast .toggle-group { transition: left 0.1s; -webkit-transition: left 0.1s; }
.quick .toggle-group { transition: none; -webkit-transition: none; }

.btn-mini {
    padding: 1px 5px;
    font-size: 13px;
    line-height: 1.7;
}
.toggle.btn-mini {
    min-width: 60px;
    min-height: 26px;
    margin: 0 6px 0 5px;
}
.toggle-off.btn.btn-mini {
    padding-left: 16px
}
.toggle-on.btn.btn-mini {
    padding-right: 14px
}

/*
** Transition CSS
****************************/

.main-navigation li a, .sab-Navigation li a, .sab-Navigation > ul > li, #nav-Accordion, .category-navigation li a, .main, .category-navigation li a, .main .title-toolbar .btn {
	transition: all 0.5s ease 0s;
	-webkit-transition: all 0.5s ease 0s;
}

/*
** Custom Css Theme Class
****************************/
/*.panel {
border-radius: 0px;
}*/

.custom-row {
	margin-bottom: 30px;
}
.lb-xs {
	font-size: 8px !important;
}
.lb-sm {
	font-size: 10px !important;
}
.lb-md {
	font-size: 14px !important;
}
.lb-lg {
	font-size: 18px !important;
}
.well-sm {
	border-radius: 3px;
	padding: 10px;
}
.ftx-well {
	background: #3291e1;
	border-radius: 0;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}
.ftx-panel .panel-heading.blue {
	background: #3291e1 none repeat scroll 0 0;
	border-radius: 0;
	color: rgba(255, 255, 255, 0.7);
	padding: 10px 15px 20px;
}
.ftx-panel .panel-heading .panel-title {
	font-size: 20px;
}
.panel-thick-sm {
	border-width: 5px;
}
.panel-thick-md {
	border-width: 10px;
}
.panel-response {
	border-radius: 6px;
	overflow: hidden;
}
.main .panel-response .panel-body {
	background: #f4f4f4;
}
.panel-response .panel-heading .senderinfo-teaser {
	margin-bottom: 0;
}
.panel-response .panel-heading {
	background: #ddd;
}

/*
** Special Alert Classes
****************************/
.alert.sp-alert {
    border-radius: 0;
    -webkit-border-radius: 0;
    box-shadow: 0 1px 2px rgba(0,0,0,0.11);
    display: table;
    width: 100%;
}
.alert.sp-alert strong {
    margin-right: 6px;
}
.sp-alert.alert-white {
    background-image: linear-gradient(to bottom, #fff, #f9f9f9);
    border-top-color: #d8d8d8;
    border-bottom-color: #bdbdbd;
    border-left-color: #cacaca;
    border-right-color: #cacaca;
    color: #404040;
    padding-left: 61px;
    position: relative;
}
.sp-alert.alert-white.rounded {
    border-radius: 3px;
    -webkit-border-radius: 3px;
}
.sp-alert.alert-white.rounded .icon {
    border-radius: 3px 0 0 3px;
    -webkit-border-radius: 3px 0 0 3px;
}
.sp-alert.alert-white .icon {
    text-align: center;
    width: 45px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid #bdbdbd;
    padding-top: 15px;
}
.sp-alert.alert-white .icon:after {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    display: block;
    content: '';
    width: 10px;
    height: 10px;
    border: 1px solid #bdbdbd;
    position: absolute;
    border-left: 0;
    border-bottom: 0;
    top: 50%;
    right: -6px;
    margin-top: -3px;
    background: #fff;
}
.sp-alert.alert-white .icon i {
    font-size: 20px;
    color: #fff;
    left: 12px;
    margin-top: -10px;
    position: absolute;
    top: 50%;
}
/*============ colors ========*/
.sp-alert.alert-success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}
.sp-alert.alert-white.alert-success .icon,
.sp-alert.alert-white.alert-success .icon:after {
    border-color: #54a754;
    background: #60c060;
}
.sp-alert.alert-info {
    background-color: #d9edf7;
    border-color: #98cce6;
    color: #3a87ad;
}
.sp-alert.alert-white.alert-info .icon,
.sp-alert.alert-white.alert-info .icon:after {
    border-color: #3a8ace;
    background: #4d90fd;
}
.sp-alert.alert-white.alert-warning .icon,
.sp-alert.alert-white.alert-warning .icon:after {
    border-color: #d68000;
    background: #fc9700;
}
.sp-alert.alert-warning {
    background-color: #fcf8e3;
    border-color: #f1daab;
    color: #c09853;
}
.sp-alert.alert-danger {
    background-color: #f2dede;
    border-color: #e0b1b8;
    color: #b94a48;
}
.sp-alert.alert-white.alert-danger .icon,
.sp-alert.alert-white.alert-danger .icon:after {
    border-color: #ca452e;
    background: #da4932;
}

/*
** Header Classes
****************************/
.main > .row > .col-md-12 > .panel {
	border-radius: 0;
	box-shadow: 0 0 5px #bbb;
}
.main h1 {
	margin: 0 0 0 0;
}
.main .title-bar {
	display: block;
	padding-bottom: 5px;
	margin: 0 0 15px;
	border-bottom: 1px solid #ddd;
}
.main .title-bar h1 {
	font-size: 30px;
	font-weight: 300;
	color: #263556;
	float: left;
	padding-right:90px;
}
.main .title-bar h1 i, .main .title-bar h1 span.glyphicon {
	color: #289dcd;
}
.main .title-toolbar {
	float: right;
	position: relative;
	top: 5px;
}
h3.margin-small {
	margin: 0 0 0 0;
}
.top-subnav li span {
	line-height: 25px;
	font-size: 15px;
	font-weight: normal;
}
.top-subnav i, .top-subnav .glyphicon {
	font-size: 18px;
	line-height: 18px;
	margin-right: 3px;
}
.top-subnav i.fa {
	font-size: 15px
}
.display-container .well h3, .display-container .well h4, .display-container .module-title, .panel-module-title {
	color: #363636;
	font-size: 18px;
	font-weight: 500;
	padding-bottom: 10px;
}
.well {
	box-shadow: none;
}
/*
** Margin Classes
****************************/

.nomargin {
	margin: 0 0 0 0 !important;
}
.margin-vertical-5 {
	margin: 5px 0;
}
.margin-vertical-10 {
	margin: 10px 0;
}
.margin-all-5 {
	margin: 5px;
}
.margin-all-10 {
	margin: 10px;
}
.margin-left-5 {
	margin-left: 5px;
}
.margin-left-15 {
	margin-left: 15px;
}
.margin-right-30 {
	margin-right: 30px;
}
.margin-right-15 {
	margin-right: 15px;
}
.margin-right-10 {
	margin-right: 10px;
}
.margin-right-5 {
	margin-right: 5px;
}
.margin-bottom-30 {
	margin-bottom: 30px;
}
.margin-bottom-12 {
	margin-bottom: 12px;
}
.margin-bottom-5 {
	margin-bottom: 5px;
}
.margin-top-0 {
	margin-top: 0px;
}
.margin-top-10 {
	margin-top: 10px;
}
.margin-top-5 {
	margin-top: 5px;
}
.margin-left-12 {
	margin-left: 12px;
}

.m-auto {
	margin-left: auto !important;
	margin-right: auto !important;
}

/*
** Width Classes
****************************/

.w10 {
	width: 10px !important;
}
.w25 {
	width: 25px !important;
}
.w30 {
    width: 30px !important;
}
.w35 {
    width: 35px !important;
}
.w40 {
    width: 40px !important;
}
.w45 {
    width: 45px !important;
}
.w50 {
	width: 50px !important;
}
.w60 {
	width: 60px !important;
}
.w65 {
    width: 65px !important;
}
.w70 {
    width: 70px !important;
}
.w75 {
	width: 75px !important;
}
.w80 {
    width: 80px !important;
}
.w85 {
    width: 85px !important;
}
.w90 {
	width: 90px !important;
}
.w100 {
	width: 100px !important;
}
.w110 {
    width: 110px !important;
}
.w115 {
    width: 115px !important;
}
.w120 {
    width: 120px !important;
}
.w125 {
	width: 125px !important;
}
.w130 {
	width: 130px !important;
}
.w135 {
	width: 135px !important;
}
.w140 {
	width: 140px !important;
}
.w145 {
	width: 145px !important;
}
.w150 {
	width: 150px !important;
}
.w160 {
    width: 160px !important;
}
.w165 {
    width: 165px !important;
}
.w170 {
	width: 170px !important;
}
.w175 {
    width: 175px !important;
}
.w180 {
    width: 180px !important;
}
.w185 {
    width: 185px !important;
}
.w200 {
	width: 200px !important;
}
.w210 {
    width: 210px !important;
}
.w215 {
    width: 215px !important;
}
.w220 {
    width: 220px !important;
}
.w225 {
    width: 225px !important;
}
.w230 {
    width: 230px !important;
}
.w240 {
    width: 240px !important;
}
.w250 {
	width: 250px !important;
}
.w260 {
	width: 260px !important;
}
.w270 {
	width: 270px !important;
}
.w275 {
	width: 275px !important;
}
.w280 {
	width: 280px !important;
}
.w290 {
	width: 290px !important;
}
.w300 {
	width: 300px !important;
}
.w325 {
	width: 325px !important;
}
.w350 {
	width: 350px !important;
}
.w400 {
	width: 400px !important;
}
.w450 {
	width: 450px !important;
}
.w475 {
	width: 475px !important;
}
.w500 {
	width: 500px !important;
}
.w550 {
    width: 550px !important;
}
/**
** Percent Width Classes
**/
.w10p {
	width: 10% !important;
}
.w15p {
	width: 15% !important;
}
.w20p {
	width: 20% !important;
}
.w25p {
	width: 25% !important;
}
.w30p {
	width: 30% !important;
}
.w40p {
	width: 40% !important;
}
.w50p {
	width: 50% !important;
}
.w60p {
	width: 60% !important;
}
.w70p {
	width: 70% !important;
}
.w75p {
	width: 75% !important;
}
.w80p {
	width: 80% !important;
}
.w90p {
	width: 90% !important;
}
.w100p {
	width: 100% !important;
}

/*
** Height Classes
****************************/

.h10 {
	height: 10px !important;
}
.h15 {
	height: 15px !important;
}
.h20 {
	height: 20px !important;
}
.h25 {
	height: 25px !important;
}
.h30 {
	height: 30px !important;
}
.h35 {
	height: 35px !important;
}
.h40 {
	height: 40px !important;
}
.h50 {
	height: 50px !important;
}
.h75 {
	height: 75px !important;
}
.h80 {
	height: 80px !important;
}
.h100 {
	height: 100px !important;
}
.h110 {
	height: 110px !important;
}
.h125 {
	height: 125px !important;
}
.h150 {
	height: 150px !important;
}
.h160 {
	height: 160px !important;
}
.h170 {
	height: 170px !important;
}
.h180 {
	height: 180px !important;
}
.h190 {
	height: 190px !important;
}
.h200 {
	height: 200px !important;
}
.h300 {
	height: 300px !important;
}
.h350 {
	height: 350px !important;
}
.h375 {
	height: 375px !important;
}
.h400 {
	height: 400px !important;
}
.h420 {
	height: 420px !important;
}
.h450 {
	height: 450px !important;
}
.h550 {
	height: 550px !important;
}
.h600 {
	height: 600px !important;
}

/*
** Calc Height Classes
****************************/
.calc-h-20 {
	height: -webkit-calc(100% - 20px);
	height: -moz-calc(100% - 20px);
	height: calc(100% - 20px);
}
.calc-h-25 {
	height: -webkit-calc(100% - 25px);
	height: -moz-calc(100% - 25px);
	height: calc(100% - 25px);
}
.calc-h-30 {
	height: -webkit-calc(100% - 30px);
	height: -moz-calc(100% - 30px);
	height: calc(100% - 30px);
}
.calc-h-35 {
	height: -webkit-calc(100% - 35px);
	height: -moz-calc(100% - 35px);
	height: calc(100% - 35px);
}
.calc-h-40 {
	height: -webkit-calc(100% - 40px);
	height: -moz-calc(100% - 40px);
	height: calc(100% - 40px);
}
.calc-h-45 {
	 height: -webkit-calc(100% - 45px);
	 height: -moz-calc(100% - 45px);
	 height: calc(100% - 45px);
}
.calc-h-50 {
	height: -webkit-calc(100% - 50px);
	height: -moz-calc(100% - 50px);
	height: calc(100% - 50px);
}
.calc-h-60 {
	height: -webkit-calc(100% - 60px);
	height: -moz-calc(100% - 60px);
	height: calc(100% - 60px);
}
.calc-h-70 {
	height: -webkit-calc(100% - 70px);
	height: -moz-calc(100% - 70px);
	height: calc(100% - 70px);
}
.calc-h-80 {
	height: -webkit-calc(100% - 80px);
	height: -moz-calc(100% - 80px);
	height: calc(100% - 80px);
}
.calc-h-90 {
	height: -webkit-calc(100% - 90px);
	height: -moz-calc(100% - 90px);
	height: calc(100% - 90px);
}
.calc-h-100 {
	height: -webkit-calc(100% - 100px);
	height: -moz-calc(100% - 100px);
	height: calc(100% - 100px);
}
.calc-w-20 {
    width: -webkit-calc(100% - 20px);
    width: -moz-calc(100% - 20px);
    width: calc(100% - 20px);
}
.calc-w-25 {
    width: -webkit-calc(100% - 25px);
    width: -moz-calc(100% - 25px);
    width: calc(100% - 25px);
}
.calc-w-30 {
    width: -webkit-calc(100% - 30px);
    width: -moz-calc(100% - 30px);
    width: calc(100% - 30px);
}
.calc-w-35 {
    width: -webkit-calc(100% - 35px);
    width: -moz-calc(100% - 35px);
    width: calc(100% - 35px);
}
.calc-w-40 {
    width: -webkit-calc(100% - 40px);
    width: -moz-calc(100% - 40px);
    width: calc(100% - 40px);
}
.calc-w-45 {
    width: -webkit-calc(100% - 45px);
    width: -moz-calc(100% - 45px);
    width: calc(100% - 45px);
}
.calc-w-50 {
    width: -webkit-calc(100% - 50px);
    width: -moz-calc(100% - 50px);
    width: calc(100% - 50px);
}
.calc-w-60 {
    width: -webkit-calc(100% - 60px);
    width: -moz-calc(100% - 60px);
    width: calc(100% - 60px);
}
.calc-w-70 {
    width: -webkit-calc(100% - 70px);
    width: -moz-calc(100% - 70px);
    width: calc(100% - 70px);
}
.calc-w-80 {
    width: -webkit-calc(100% - 80px);
    width: -moz-calc(100% - 80px);
    width: calc(100% - 80px);
}
.calc-w-90 {
    width: -webkit-calc(100% - 90px);
    width: -moz-calc(100% - 90px);
    width: calc(100% - 90px);
}
.calc-w-100 {
    width: -webkit-calc(100% - 100px);
    width: -moz-calc(100% - 100px);
    width: calc(100% - 100px);
}
.calc-w-110 {
	width: -webkit-calc(100% - 110px);
	width: -moz-calc(100% - 110px);
	width: calc(100% - 110px);
}
.calc-w-120 {
    width: -webkit-calc(100% - 120px);
    width: -moz-calc(100% - 120px);
    width: calc(100% - 120px);
}
.calc-w-130 {
	width: -webkit-calc(100% - 130px);
	width: -moz-calc(100% - 130px);
	width: calc(100% - 130px);
}
.calc-w-150 {
	width: -webkit-calc(100% - 150px);
	width: -moz-calc(100% - 150px);
	width: calc(100% - 150px);
}
.calc-w-160 {
	width: -webkit-calc(100% - 160px);
	width: -moz-calc(100% - 160px);
	width: calc(100% - 160px);
}
.calc-w-170 {
	width: -webkit-calc(100% - 170px);
	width: -moz-calc(100% - 170px);
	width: calc(100% - 170px);
}
.calc-w-200 {
    width: -webkit-calc(100% - 200px);
    width: -moz-calc(100% - 200px);
    width: calc(100% - 200px);
}
.calc-w-220 {
	width: -webkit-calc(100% - 220px);
	width: -moz-calc(100% - 220px);
	width: calc(100% - 220px);
}
.calc-w-230 {
	width: -webkit-calc(100% - 230px);
	width: -moz-calc(100% - 230px);
	width: calc(100% - 230px);
}
.calc-w-250 {
	width: -webkit-calc(100% - 250px);
	width: -moz-calc(100% - 250px);
	width: calc(100% - 250px);
}
.calc-w-300 {
	width: -webkit-calc(100% - 300px);
	width: -moz-calc(100% - 300px);
	width: calc(100% - 300px);
}
.calc-w-340 {
	width: -webkit-calc(100% - 340px);
	width: -moz-calc(100% - 340px);
	width: calc(100% - 340px);
}
.calc-w-350 {
	width: -webkit-calc(100% - 350px);
	width: -moz-calc(100% - 350px);
	width: calc(100% - 350px);
}
.calc-w-360 {
	width: -webkit-calc(100% - 360px);
	width: -moz-calc(100% - 360px);
	width: calc(100% - 360px);
}

.calc-w-400 {
	width: -webkit-calc(100% - 400px);
	width: -moz-calc(100% - 400px);
	width: calc(100% - 400px);
}

.calc-w-450 {
	width: -webkit-calc(100% - 450px);
	width: -moz-calc(100% - 450px);
	width: calc(100% - 450px);
}

.calc-w-500 {
	width: -webkit-calc(100% - 500px);
	width: -moz-calc(100% - 500px);
	width: calc(100% - 500px);
}

.calc-w-600 {
	width: -webkit-calc(100% - 600px);
	width: -moz-calc(100% - 600px);
	width: calc(100% - 600px);
}

.calc-w-700 {
	width: -webkit-calc(100% - 700px);
	width: -moz-calc(100% - 700px);
	width: calc(100% - 700px);
}


.calc-w-800 {
	width: -webkit-calc(100% - 800px);
	width: -moz-calc(100% - 800px);
	width: calc(100% - 800px);
}

/*
** Width Limit Classes
****************************/

.limit50 {
	max-width: 50px;
}
.limit60 {
	max-width: 60px;
}
.limit70 {
	max-width: 70px;
}
.limit100 {
	max-width: 100px;
}
.limit110 {
	max-width: 110px;
}
.limit120 {
	max-width: 120px;
}
.limit130 {
	max-width: 130px;
}
.limit140 {
	max-width: 140px;
}
.limit150 {
	max-width: 150px;
}
.limit160 {
	max-width: 160px;
}
.limit170 {
	max-width: 170px;
}
.limit180 {
	max-width: 180px;
}
.limit190 {
	max-width: 190px;
}
.limit200 {
	max-width: 200px;
}
.limit225 {
	max-width: 225px;
}
.limit250 {
	max-width: 250px;
}
.limit275 {
	max-width: 275px;
}
.limit300 {
	max-width: 300px;
}
.limit350 {
	max-width: 350px;
}
.limit400 {
	max-width: 400px;
}
.limit450 {
	max-width: 450px;
}
.limit500 {
	max-width: 500px;
}
.limit600 {
	max-width: 600px;
}
.limit700 {
	max-width: 700px;
}
/*
** Height Limit Classes
****************************/
.hlimit50 {
	max-height: 50px;
}
.hlimit60 {
	max-height: 60px;
}
.hlimit70 {
	max-height: 70px;
}
.hlimit100 {
	max-height: 100px;
}
.hlimit110 {
	max-height: 110px;
}
.hlimit120 {
	max-height: 120px;
}
.hlimit130 {
	max-height: 130px;
}
.hlimit140 {
	max-height: 140px;
}
.hlimit150 {
	max-height: 150px;
}
.hlimit160 {
	max-height: 160px;
}
.hlimit170 {
	max-height: 170px;
}
.hlimit180 {
	max-height: 180px;
}
.hlimit190 {
	max-height: 190px;
}
.hlimit200 {
	max-height: 200px;
}
.hlimit225 {
	max-height: 225px;
}
.hlimit250 {
	max-height: 250px;
}
.hlimit275 {
	max-height: 275px;
}
.hlimit300 {
	max-height: 300px;
}
.hlimit350 {
	max-height: 350px;
}
.hlimit400 {
	max-height: 400px;
}
.hlimit450 {
	max-height: 450px;
}
.hlimit500 {
	max-height: 500px;
}

/*
** Padding Classes
****************************/

.nopadding {
	padding: 0px !important;
}
.padding-top-0 {
	padding-top: 0px !important;
}
.padding-top-10 {
	padding-top: 10px !important;
}
.padding-top-20 {
	padding-top: 20px !important;
}
.padding-all-2 {
	padding: 2px !important;
}
.padding-all-5 {
	padding: 5px !important;
}
.padding-all-10 {
	padding: 10px !important;
}
.padding-vertical-5 {
	padding: 5px 0px !important;
}
.padding-vertical-10 {
	padding: 10px 0px !important;
}
.padding-horizontal-5 {
	padding: 0px 5px !important;
}
.padding-horizontal-10 {
	padding: 0px 10px !important;
}
/*
** Border Classes
****************************/

.borderUnder {
	border-bottom: 1px solid #CFCFCF;
	padding-bottom: 5px;
	margin-bottom: 20px;
}

/*
** Data Table Classes
***************************/
table.table-dt {
    border-collapse: collapse !important;
}
table.table-dt th,
table.table-dt.dataTable th {
    padding: 5px 8px 7px 8px !important;
}
table.table-dt td {
	font-size: 13px;
}
table.table-dt {
    white-space: nowrap;
}
table.table-dt tbody tr.selected td {
    background-color: rgba(70, 156, 74, 0.88) !important;
    color: white;
}
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody {
    max-height: none !important;
}
table.table-dt thead {
    height: 0;
}

/*
** Form Classes
****************************/
.margin-center {
    margin-right: auto;
    margin-left: auto;
}
.form-button {
	cursor: pointer;
}
.form-error, .form-control.form-error {
	border: 1px solid red;
}
input[type="file"] {
	height: 30px;
	padding: 0;
	box-sizing: content-box;
}
.form-horizontal .control-label {
	font-size: 12px;
}
.select2-container {
	max-width: 100%;
}
.input-group .select2-container {
	width: 100% !important;
}
.input-group .select2-container .select2-selection--single {
	height: 33px;
}
.select2-dropdown {
	background: #EEE !important;
}
.dropdown-menu.columns-2 {
    min-width: 550px;
}
.dropdown-menu.columns-3 {
    min-width: 650px;
}
.dropdown-menu.multi-column li a {
    padding: 5px 15px;
    font-weight: 300;
}
.multi-column-dropdown {
    list-style: none;
    margin: 0;
    padding: 0;
}
.multi-column-dropdown li a {
    display: block;
    clear: both;
    line-height: 1.428571429;
    color: #333;
    white-space: normal;
}
.multi-column-dropdown li a:hover {
    text-decoration: none;
    color: #262626;
    background-color: #EEE;
}

@media (max-width: 767px) {
    .dropdown-menu.multi-column {
        min-width: 240px !important;
        overflow-x: hidden;
    }
}
.isEnabled {
	color: green;
}
.notEnabled {
	color: red;
}
.fileField {
	height: 700px !important;
}
.alert-sm {
	padding: 5px 0px;
}
.alert-sm h4 {
	font-size: 15px;
}
.colorpicker-element .input-group-addon {
	padding: 3px;
}
.colorpicker-element .input-group-addon i {
	height: 25px !important;
	width: 30px !important;
	border: 1px solid #888;
}
.colorpicker-alpha {
    display:none !important;
}
.colorpicker {
    min-width:128px !important;
}
.spectrum.form-control {
	display: none;
}
/*
** Form Addons
*/

.item-tools {
	margin-bottom: 10px;
}
.item-tools .select2-selection {
	height: 30px !important;
}
.item-tools .col-md-10 {
	padding-right: 2px;
}
.item-tools .col-md-2 {
	padding-left: 2px;
}
.paginateSelect {
	margin: 0px 0px 10px;
}
.tableTools .btn {
	font-size: 11px;
}
.steps .select2-container {
	display: block;
	max-width: 100%;
	min-width: 300px;
}
.inputLine {
	margin-bottom: 5px;
}
.no-gutter [class*="col-"] {
	padding: 0;
}
.gutter-sm [class*="col-"] {
	padding: 5px;
}
.gutter-md [class*="col-"] {
	padding: 10px;
}
/*
aria-multiselectable="true"
*/

.select2-results__options[aria-multiselectable="true"] .select2-results__option {
	position: relative;
}
.select2-results__options[aria-multiselectable="true"] .select2-results__option:after {
	font-family: "Font Awesome\ 5 Pro";
	content: "\f055";
	position: absolute;
	right: 10px;
	color: green;
}
.select2-results__options[aria-multiselectable="true"] .select2-results__option[aria-selected="true"] {
	background-color: #0a6aa1 !important;
	color:#FFF;
	position: relative;
}
.select2-results__options[aria-multiselectable="true"] .select2-results__option[aria-selected="true"]:after {
	font-family: "Font Awesome\ 5 Pro";
	content: "\f056";
	position: absolute;
	right: 10px;
	color: #CC0000;
}
::-webkit-input-placeholder, :-moz-placeholder, ::-moz-placeholder, :-ms-input-placeholder {
	/* Firefox 19+ */

	font-family: Helvetica, "Helvetica Neue", Arial, sans-serif !important;
}
.colorpicker-2x .colorpicker-saturation {
	width: 170px;
	height: 170px;
}
.colorpicker-2x .colorpicker-hue, .colorpicker-2x .colorpicker-alpha {
	width: 30px;
	height: 170px;
}
.colorpicker-2x .colorpicker-color, .colorpicker-2x .colorpicker-color div {
	height: 30px;
}
/*
** Navbar Classes
****************************/

.nav-main .navbar-nav {
	border-left: 1px solid #333;
}
.nav-main .nav > li > a {
	padding: 15px 10px;
	font-size: 11px;
	border-right: 1px solid #333;
}
.nav-main .navbar-nav.navbar-right, .nav-main .navbar-nav.navbar-right > li > a {
	border: none;
}
.nav-main .navbar.dropdown-user a:hover {
	text-decoration: none;
}
.nav-main .navbar-top-links li a {
	padding: 15px;
	min-height: 50px;
}
#dropdown-profile {
	padding-top: 10px;
	padding-bottom: 10px;
	line-height: 30px;
}
#nav-email {
	margin-left: 3px;
}
[ui-sref], [data-ui-sref] {
 cursor: pointer;
}
.nav-pills, .pagination, .carousel, .panel-title a {
	cursor: pointer;
}
*:focus {
	outline: 0 !important;
}
a {
	outline: none !important;
}
a:hover, a:focus {
	text-decoration: none;
}
h1 > a.h-link, h2 > a.h-link, h3 > a.h-link, h4 > a.h-link, h5 > a.h-link, h6 > a.h-link {
	color: inherit;
}
.list-group {
	line-height: 1.3;
}
.list-group .list-group-item {
	padding: 10px 15px;
}
/*.panel .list-group .list-group-item:first-child {
border-top: 0;
}*/

.page-header {
	border-bottom-color: #e6e9ee;
}
.thumbnail {
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}
.nav-tabs > li > a {
	font-weight: 400;
	margin: 0;
	border: 1px solid #e6e9ee;
	border-radius: 0;
	padding: 8px 16px;
	cursor: pointer;
}
.nav-tabs > li.active > a {
	padding: 8px 16px 15px;
}
.nav-sm.nav-tabs > li > a {
    padding: 4px 8px;
}
.nav-sm.nav-tabs > li.active > a {
    padding: 4px 8px 3px;
    line-height: 16px;
}
.nav-tabs > li {
	padding: 4px;
}
.nav-tabs > li {
	padding: 0 8px 0 0;
}
.nav-tabs > li:first-child {
	padding-left: 0;
}
.nav-tabs > li.active + li {
	padding-left: 0;
}
.tab-content {
	padding: 10px 20px;
	border-style: solid;
	border-width: 0 1px 1px 1px;
}
.nav-pills + .tab-content {
	border: 0;
	padding: 0;
}
.nav-tabs.nav-justified > .active > a, .nav-tabs.nav-justified > .active > a:hover, .nav-tabs.nav-justified > .active > a:focus {
	border-bottom: 0;
}

/** Button Tabs **/
.button-tabs.nav-tabs {
    border: none;
    margin-bottom: 10px;
}
.button-tabs.nav-tabs > li {
    margin: 0 7px 0 0;
    padding: 0;
}
.button-tabs.nav-tabs > li > a {
    border: 1px solid #CDCDCD;
    border-radius: 4px;
    background: white;
    color: #337ab7 !important;
}
.button-tabs.nav-tabs > li.active > a {
    padding: 8px 16px;
    color: white !important;
}
.button-tabs.nav-tabs > li.active > a,
.button-tabs.nav-tabs > li.active > a:focus,
.button-tabs.nav-tabs > li.active > a:hover,
.button-tabs.nav-pills > li.active > a,
.button-tabs.nav-pills > li.active > a:focus,
.button-tabs.nav-pills > li.active > a:hover {
    border: 1px solid transparent;
    color: white !important;
    background: #428bca !important;
}
.button-tabs.tab-content {
	border: solid 1px #ddd;
}

.actionContents  .button-tabs.tab-content {
    border: solid 1px #ddd;
    overflow: auto;
    height: calc(100% - 40px);
    margin-bottom: 0px;
}

.button-tabs.nav-tabs.nav-sm > li {
	margin: 0 5px 5px 0;
}

.button-tabs.nav-pills.nav-pills-sm > li > a {
    padding: 6px 10px;
    border: none!important;
    color: #337ab7;
}
.button-tabs.nav-pills.nav-pills-xs > li > a {
	padding: 5px 8px;
	border: 1px solid #DDD;
	color: #337ab7;
	font-size: 11px;
}
.button-tabs.nav-pills.nav-pills-xs > li > a {
	padding: 5px 9px;
	border: none!important;
	color: #337ab7;
}
.button-tabs.nav-pills > li + li {
    margin-bottom: 10px;
}

.button-tabs.nav-pills> li.disabled a {
	color: #a8a8a8 !important;
}

.button-tabs.nav-tabs.nav-sm > li > a {
	font-size: 10px !important;
}
.button-tabs.nav-tabs > li.active > a {
	padding: 4px 8px 3px;
	color: white !important;
}
.upload-image-placeholder div {
	text-align: center;
	font-size: 20px;
	height: auto !important;
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	transform: translateY(-50%);
}
.upload-image-placeholder {
	background: #e6e9ee;
	height: 100%;
}

.form-control, .input-group-addon {
	border-color: #dbd9d9;
}
.input-group-addon {
	background-color: #f8f9fb;
}
.form-control.input-sm, select.form-control.input-sm {
	height: 32px;
}
.form-control.input-xs, select.form-control.input-xs {
	height: 20px;
	margin-top: -1px;
	padding: 2px 4px;
	font-size: 12px;
}
fieldset {
	padding-bottom: 20px;
	border-bottom: 1px dashed #eee;
	margin-bottom: 20px;
}
fieldset.last-child, fieldset:last-child {
	border-bottom: 0;
}
fieldset .form-group {
	margin-bottom: 0;
}
input[type="text"], input[type="email"], input[type="search"], input[type="password"], input[type="url"], input[type="number"] {
	-webkit-appearance: none;
}
.table > thead > tr > th {
	border-bottom-width: 1px;
	padding-top: 20px !important;
	padding-bottom: 20px !important;
}

.table > thead.sm-head > tr > th {
	border-bottom-width: 1px;
	padding-top: 10px !important;
	padding-bottom: 10px !important;
}

.table > thead.xs-head > tr > th {
    border-bottom-width: 1px;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.table > tbody + tbody {
	border-bottom-width: 1px;
}
.table-bordered > thead > tr > th, .table-bordered > thead > tr > td {
	border-bottom-width: 1px;
}
.progress {
	-webkit-box-shadow: 0 0 0 #000000;
	box-shadow: 0 0 0 #000000;
	border-radius: 2px;
	border: 1px solid #cfcfcf;
	background-color: #fff;
}
.progress .progress-bar {
	-webkit-box-shadow: 0 0 0 #000000;
	box-shadow: 0 0 0 #000000;
}
.popover {
	box-shadow: 0 0 0 #000;
	border-color: #eee;
	border-bottom: 2px solid #e6e9ee;
	border-radius: 2px;
}
.popover .popover-title {
	border: 0;
}
.dropdown-menu {
	border-radius: 2px;
}
.dropdown-header {
	color: #a1a2a3;
}
.panel > .table-responsive {
	max-width: 100%;
	overflow: auto;
}
.media-heading a {
	text-decoration: none;
}
/**
Component: layout.less
*/

html {
	height: 100%;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
}
body {
	overflow-x: hidden;
	height: 100%;
	direction: ltr;
}
.footer-hidden .container-fh {
	bottom: 0;
}
.footer-hidden .app {
	padding-bottom: 0;
}
.footer-hidden .app-container > footer {
	display: none;
}
.app-fh {
	position: absolute;
	width: auto;
	overflow: auto;
	top: 70px;
	left: 0;
	bottom: 0;
	right: 0;
	padding: 0;
	-webkit-transition: left 0.35s ease;
	-o-transition: left 0.35s ease;
	transition: left 0.35s ease;
	will-change: left;
	-webkit-overflow-scrolling: touch;
}
@media only screen and (min-width: 768px) {
.app-fh {
	left: 240px;
}
}
.aside-offscreen .app-fh, .layout-boxed .app-fh {
	left: 0;
}
.container-fh {
	height: 100%;
}
.l-table, .l-table-fixed {
	display: table;
	width: 100%;
	height: 100%;
	min-height: 240px;
	border-spacing: 0;
}
.l-table-fixed {
	table-layout: fixed;
}
.l-row {
	display: table-row;
	height: 100%;
}
.l-cell, .l-cell-wrapper {
	position: relative;
	display: table-cell;
	height: 100%;
	width: 100%;
	vertical-align: top;
	overflow: auto;
}
.l-cell-wrapper {
	display: block;
}
.l-cell-wrapper .l-cell-inner {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}
@media only screen and (max-width: 767px) {
.l-table-fixed {
	display: block;
}
.l-table-fixed > .l-cell {
	display: block;
	width: auto;
	height: auto;
}
.nav-tabs > li.active > a {
	padding: 8px 16px 8px;
	border-bottom: 1px solid #3291e1 !important;
}
.nav-tabs > li {
	width: 50%;
	margin-bottom: 6px;
}
.nav-tabs > li:first-child {
	padding-left: 0;
}
.nav-tabs > li:nth-child(2n+2) {
	padding-right: 0;
}
}


/* Target Firefox only */

@-moz-document url-prefix() {
 .l-table, .l-table-desktop {
 height: 240px;
 min-height: 100%;
}
 .l-cell-inner {
 overflow: auto;
}
}
.fh {
	height: 100% !important;
}
.fw {
	width: 100% !important;
}
.mfh {
	max-height: 100% !important;
}
.mfw {
	max-width: 100% !important;
}
.v-middle {
	vertical-align: middle !important;
}
.v-top {
	vertical-align: top !important;
}
.scroll {
	overflow: auto;
}
.scroll-y {
	overflow-y: auto;
}
.scroll-y {
	overflow-y: auto;
}
.layout-material .app-container > aside, .layout-material .app-container > header {
	position: fixed;
}
.layout-material .app-container > header {
	width: 100%;
}
.layout-material .app-container > section {
	padding-top: 70px;
}
@media only screen and (min-width: 768px) {
.layout-material .app-container > header {
	z-index: 109;
}
.layout-material .app-container > header > nav {
	padding-top: 50px;
}
.layout-material .app-container > header > nav .navbar-nav {
	margin-top: -50px;
}
.layout-material .app-container > aside {
	top: 120px;
	z-index: 108;
	margin-left: 0 !important;
}
.layout-material .app-container > section {
	position: absolute;
	padding-top: 0;
	top: 0;
	right: 100px;
	left: 240px;
	margin-top: 50px;
	margin-left: 0;
	max-width: none;
	height: calc(100% - 50px);
}
.layout-material .app-container > section > .app {
	padding-bottom: 0;
	background-color: #fff;
	box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 6px rgba(0, 0, 0, 0.12) !important;
}
.layout-material .app-container > footer {
	display: none;
}
.layout-material .app-container .btn-offset {
	padding-top: 50px;
	right: 30px;
}
.layout-material .app {
	min-height: 100%;
	padding-bottom: 20px;
}
.layout-material .app-fh {
	top: 0;
	left: 0;
}
}
.layout-material .hidden-material {
	display: none !important;
}
@media only screen and (min-width: 768px) {
.aside-mini .app-container > section,  .aside-mini .app-container > footer {
	margin-left: 64px;
}
.aside-mini .app-container > aside {
	width: 64px;
	-webkit-transition: all 0.35s ease 0s;
	-o-transition: all 0.35s ease 0s;
	transition: all 0.35s ease 0s;
}
.aside-mini .app-container > aside:hover {
	width: 240px;
}
.aside-mini .app-fh {
	left: 0;
}
.aside-mini.aside-offscreen .app-container > section,  .aside-mini.aside-offscreen .app-container > footer {
	margin-left: 0;
}
.csstransforms3d .aside-mini.aside-offscreen .app-container > aside {
	margin: 0;
	-webkit-transform: translate3d(-64px, 0, 0);
	transform: translate3d(-64px, 0, 0);
}
}
@media only screen and (min-width: 768px) {
.aside-mini .sidebar-wrapper {
	width: 100%;
}
.aside-mini .sidebar .sidebar-nav > .nav {
	padding-top: 10px;
}
.aside-mini .sidebar .sidebar-nav > .nav li,  .aside-mini .sidebar .sidebar-nav > .nav li > a {
	width: 100%;
}
.aside-mini .sidebar .sidebar-nav > .nav li.nav-heading,  .aside-mini .sidebar .sidebar-nav > .nav li .sidebar-subnav {
	display: none;
}
.aside-mini .sidebar .sidebar-nav > .nav li > a > span {
	opacity: 0;
	-webkit-transition: opacity 0.2s ease;
	-o-transition: opacity 0.2s ease;
	transition: opacity 0.2s ease;
}
.aside-mini .sidebar .sidebar-nav > .nav li > a {
	width: 100%;
}
.aside-mini .sidebar .sidebar-nav > .nav li > a > .sidebar-item-caret {
	display: none;
}
.aside-mini .sidebar .sidebar-nav > .nav li > a > em.sidebar-item-icon {
	width: 2em;
	text-align: center;
}
.aside-mini .sidebar .sidebar-nav > .nav li > a > .label {
	position: relative;
	margin: -5px -5px 0 0;
	-webkit-transition: margin 0.2s linear;
	-o-transition: margin 0.2s linear;
	transition: margin 0.2s linear;
}
.aside-mini .sidebar:hover .sidebar-nav > .nav li .sidebar-subnav {
	display: block;
}
.aside-mini .sidebar:hover .sidebar-nav > .nav li > a > span {
	opacity: 1;
}
.aside-mini .sidebar:hover .sidebar-nav > .nav li > a > .label {
	margin: 2px 0 0 0;
}
}
.ipad .sidebar > .sidebar-nav > .nav > li > a > .pull-right, .iphone .sidebar > .sidebar-nav > .nav > li > a > .pull-right {
	margin-right: 10px;
}
.animated {
	-webkit-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}
.fadeInLeft2 {
	-webkit-animation-name: fadeInLeft2;
	animation-name: fadeInLeft2;
}
@-webkit-keyframes fadeInLeft2 {
 0% {
 opacity: 0;
 -webkit-transform: translate3d(-18px, 0, 0);
 transform: translate3d(-18px, 0, 0);
}
 100% {
 opacity: 1;
 -webkit-transform: none;
 transform: none;
}
}
@keyframes fadeInLeft2 {
 0% {
 opacity: 0;
 -webkit-transform: translate3d(-18px, 0, 0);
 -ms-transform: translate3d(-18px, 0, 0);
 transform: translate3d(-18px, 0, 0);
}
 100% {
 opacity: 1;
 -webkit-transform: none;
 -ms-transform: none;
 transform: none;
}
}
/* Roboto @font-face kit */
/**
Component: print.less
*/

@media print {
.sidebar,  .topnavbar,  .settings,  .btn {
	display: none !important;
	width: 0 !important;
	height: 0 !important;
}
.app-container,  .app-container > section,  .app {
	margin: 0 !important;
	/* remove margin used for sidebar and expand the content */

	width: 100% !important;
}
.app-container > aside,  .app-container > footer {
	display: none;
}
.app {
	overflow: hidden !important;
}
.text-muted {
	color: #e6e9ee !important;
}
}
/**
Component: form-extras.less
*/

.form-control-rounded {
	border-radius: 100px;
}
.form-control-feedback {
	top: 10px;
}
.input-sm + .form-control-feedback {
	top: 0;
}
.input-md {
	width: 260px;
}
.input-huge {
	background: transparent;
	border: 0;
	height: auto;
	font-weight: 500;
	font-size: 30px;
	letter-spacing: -1.1px;
	padding-left: 0 !important;
	color: #fff;
}
.input-huge::-moz-placeholder {
 color: rgba(255, 255, 255, 0.6);
 opacity: 1;
}
.input-huge:-ms-input-placeholder {
 color: rgba(255, 255, 255, 0.6);
}
.input-huge::-webkit-input-placeholder {
 color: rgba(255, 255, 255, 0.6);
}
@media only screen and (min-width: 768px) {
.input-huge {
	font-size: 42px;
}
}
@media only screen and (min-width: 992px) {
.input-huge {
	font-size: 82px;
}
}
/**
Component: form-imgcrop.less
*/

.imgcrop-area {
	width: 100%;
	height: 410px;
	overflow: hidden;
	background: #e6e9ee;
}
.imgcrop-preview {
	position: relative;
	width: 100%;
	height: 200px;
	margin: 0 auto;
	background: #e6e9ee;
	text-align: center;
}
.imgcrop-preview:after {
	content: attr(data-text);
	display: block;
	position: absolute;
	height: 50%;
	text-align: center;
	margin: auto;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 0;
	color: #8394a9;
}
.imgcrop-preview > img {
	position: relative;
	z-index: 1;
	max-width: 100%;
}
/**
Component: form-input.less
*/
/**
* Custom form elements
*    - Checkbox
*    - Radios
*/

.c-checkbox, .c-radio {
	margin-right: 4px;
}
.c-checkbox *, .c-radio * {
	cursor: pointer;
}
.c-checkbox input, .c-radio input {
	opacity: 0;
	position: absolute;
	margin-left: 0 !important;
}
.c-checkbox span, .c-radio span {
	position: relative;
	display: inline-block;
	vertical-align: top;
	margin-left: -20px;
	width: 20px;
	height: 20px;
	border-radius: 2px;
	border: 1px solid #ccc;
	margin-right: 5px;
	text-align: center;
}
.c-checkbox span:before, .c-radio span:before {
	margin-left: 1px;
	color: #fff;
}
.c-checkbox:hover span, .c-radio:hover span {
	border-color: #3f51b5;
}
.form-inline .c-checkbox span, .form-inline .c-radio span {
	margin-left: 0;
}
.c-checkbox.c-checkbox-rounded span, .c-radio.c-checkbox-rounded span, .c-checkbox.c-radio-rounded span, .c-radio.c-radio-rounded span {
	border-radius: 500px;
}
/* override for radio */

.c-radio span {
	border-radius: 500px;
}
/* the icon */

.c-checkbox span:before, .c-radio span:before {
	position: absolute;
	top: 0;
	left: -1px;
	right: 0;
	bottom: 0;
	opacity: 0;
	-webkit-transform: scale(0.2);
	-ms-transform: scale(0.2);
	-o-transform: scale(0.2);
	transform: scale(0.2);
	text-align: center !important;
	font-size: 12px;
	line-height: 19px;
	vertical-align: middle;
}
/* Checked state */

.c-checkbox, .c-radio {
/* override for radio */
	/* Disable state */
	/* override for radio */
}
.c-checkbox input[type=checkbox]:checked + span:before, .c-radio input[type=checkbox]:checked + span:before, .c-checkbox input[type=radio]:checked + span:before, .c-radio input[type=radio]:checked + span:before {
	opacity: 1;
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
	-webkit-transition: -webkit-transform 0.2s ease-out;
	-moz-transition: -moz-transform 0.2s ease-out;
	-o-transition: -o-transform 0.2s ease-out;
	transition: transform 0.2s ease-out;
}
.c-checkbox input[type=checkbox]:checked + span, .c-radio input[type=checkbox]:checked + span, .c-checkbox input[type=radio]:checked + span, .c-radio input[type=radio]:checked + span {
	border-color: #3f51b5;
	background-color: #3f51b5;
}
.c-checkbox input[type=radio]:checked + span, .c-radio input[type=radio]:checked + span {
	background-color: #fff;
}
.c-checkbox input[type=radio]:checked + span:before, .c-radio input[type=radio]:checked + span:before {
	color: #3f51b5;
}
.c-checkbox input[type=checkbox]:disabled + span, .c-radio input[type=checkbox]:disabled + span, .c-checkbox input[type=radio]:disabled + span, .c-radio input[type=radio]:disabled + span {
	border-color: #dddddd !important;
	background-color: #dddddd !important;
}
.c-checkbox input[type=radio]:disabled + span, .c-radio input[type=radio]:disabled + span {
	background-color: #fff !important;
}
.c-checkbox input[type=radio]:disabled + span:before, .c-radio input[type=radio]:disabled + span:before {
	color: #dddddd;
}
.block-note {
	background-image: -webkit-linear-gradient(#eeeeee 0.1em, transparent 0.1em);
	background-image: linear-gradient(#eeeeee 0.1em, transparent 0.1em);
	background-size: 100% 20px;
	background-color: #fff;
	line-height: 20px;
	margin-top: 5px;
	padding: 0;
	padding-bottom: 1px;
	border: none;
}
.block-note.block-note-margin {
	background-image: -webkit-linear-gradient(0deg, transparent 49px, #fcc9c5 49px, #fcc9c5 51px, transparent 51px), -webkit-linear-gradient(#eeeeee 0.1em, transparent 0.1em);
	background-image: linear-gradient(90deg, transparent 49px, #fcc9c5 49px, #fcc9c5 51px, transparent 51px), linear-gradient(#eeeeee 0.1em, transparent 0.1em);
	padding-left: 55px;
}
/**
Component: form-switch.less
*/
/**
* Custom form elements
*    - Switch
*/

.switch .form-control {
	padding-top: 7px;
	margin-bottom: 0;
}
.switch * {
	cursor: pointer;
}
.switch input {
	opacity: 0;
	position: absolute;
	z-index: -1;
}
.switch span {
	position: relative;
	display: inline-block;
	width: 40px;
	height: 20px;
	background-color: transparent;
	border-radius: 100px;
	transition: all .5s;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25) inset;
}
.switch span:after {
	content: "";
	position: absolute;
	background-color: #fff;
	top: 1px;
	left: 0;
	height: 18px;
	width: 18px;
	border: 1px solid #ddd;
	border-radius: 400px;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
	-webkit-transition: all .2s;
}
.switch.switch-lg span {
	width: 50px;
	height: 25px;
}
.switch.switch-lg span:after {
	height: 23px;
	width: 23px;
}
.switch.switch-sm span {
	width: 30px;
	height: 15px;
}
.switch.switch-sm span:after {
	height: 13px;
	width: 13px;
}
.switch input:checked + span {
	background-color: #3f51b5;
	border-color: #3f51b5;
	transition: all .5s;
}
.switch input:checked + span:after {
	left: 50%;
	transition: all .2s;
}
.switch input:disabled + span {
	background-color: #e6e9ee !important;
	border-color: #e6e9ee !important;
}
.switch-primary.switch input:checked + span {
	background-color: #3f51b5;
	border-color: #3f51b5;
}
.switch-success.switch input:checked + span {
	background-color: #4caf50;
	border-color: #4caf50;
}
.switch-info.switch input:checked + span {
	background-color: #2095f2;
	border-color: #2095f2;
}
.switch-warning.switch input:checked + span {
	background-color: #fe9700;
	border-color: #fe9700;
}
.switch-danger.switch input:checked + span {
	background-color: #f34235;
	border-color: #f34235;
}
.switch-inverse.switch input:checked + span {
	background-color: #363f45;
	border-color: #363f45;
}
.switch-amber.switch input:checked + span {
	background-color: #ffc107;
	border-color: #ffc107;
}
.switch-pink.switch input:checked + span {
	background-color: #e91e63;
	border-color: #e91e63;
}
.switch-purple.switch input:checked + span {
	background-color: #6639b6;
	border-color: #6639b6;
}
.switch-orange.switch input:checked + span {
	background-color: #fe5621;
	border-color: #fe5621;
}
/**
Component: form-validation.less
*/

.form-validate .form-control.ng-dirty.ng-invalid {
	border-color: #f34235;
}
.form-validate .form-control.ng-dirty.ng-valid, .form-validate .form-control.ng-dirty.ng-valid:focus {
	border-color: #4caf50;
}
/**
Component: form-wizard.less
*/

.steps-bar {
	margin-bottom: 30px;
	overflow: hidden;
	counter-reset: step;
	display: table;
	table-layout: fixed;
	width: 100%;
}
.steps-bar > li {
	display: table-cell;
	list-style-type: none;
	text-transform: uppercase;
	font-size: 10px;
	position: relative;
	text-align: center;/* paint the steps progress */
}
.steps-bar > li > span {
	cursor: pointer;
}
.steps-bar > li:before {
	content: counter(step);
	position: relative;
	counter-increment: step;
	width: 30px;
	line-height: 30px;
	display: block;
	font-size: 14px;
	color: #333;
	background: white;
	border-radius: 3px;
	margin: 0 auto 5px auto;
	z-index: 2;
}
.steps-bar > li:after {
	content: '';
	width: 0;
	height: 2px;
	background: white;
	position: absolute;
	left: -50%;
	top: 14px;
	z-index: 1;
	/*put it behind the numbers*/

	-webkit-transition: width 0.3s ease-out;
	-o-transition: width 0.3s ease-out;
	transition: width 0.3s ease-out;
}
.steps-bar > li:first-child:after {
	content: none;
}
.steps-bar > li.active:before, .steps-bar > li.active:after {
	background: #3f51b5;
	color: white;
}
.steps-bar > li.active:after {
	width: 100%;
}
/**
Component: uiselect.less
*/

.ui-select-bootstrap > .ui-select-match {
	text-align: left;
}
.ui-select-toggle {
	font-size: 14px;
}
/**
Component: form-validation.less
(http://mdo.github.io/table-grid)
*/

@media only screen and (min-width: 768px) {
.table-grid {
	display: table;
	width: 100%;
	height: 100%;
	table-layout: fixed;
}
.col {
	display: table-cell;
	vertical-align: top;
	height: 100%;
}
.col.col-full-center {
	vertical-align: middle;
}
.col-1 {
	width: 8.333333%;
}
.col-2 {
	width: 16.666667%;
}
.col-3 {
	width: 25%;
}
.col-4 {
	width: 33.333333%;
}
.col-5 {
	width: 41.666667%;
}
.col-6 {
	width: 50%;
}
.col-7 {
	width: 58.333333%;
}
.col-8 {
	width: 66.666667%;
}
.col-9 {
	width: 75%;
}
.col-10 {
	width: 83.333333%;
}
.col-11 {
	width: 91.666667%;
}
.col-12 {
	width: 100%;
}
.table-grid-padded {
	margin-left: -1rem;
	margin-right: -1rem;
}
.table-grid-padded .table-grid {
	border-spacing: 1rem 0;
}
.table-grid-align-middle .col {
	vertical-align: middle;
}
}
/**
Component: masonry-grid.less
*/

.row-masonry {
	position: relative;
	margin: 0;
	padding: 0;
	width: 100%;
}
.row-masonry > .col-masonry {
	display: inline-block;
	width: 100%;
	min-height: 1em;
	margin-bottom: 15px;
}
/* Columns definition by devices */

@media only screen and (min-width: 480px) {
.row-masonry-sm-1 {
	-moz-column-count: 1;
	-webkit-column-count: 1;
	column-count: 1;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 1;
	-webkit-columns: 1;
	columns: 1;
}
.no-csscolumns .row-masonry-sm-1 {
	text-align: justify;
}
.no-csscolumns .row-masonry-sm-1:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-sm-1 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 99%;
}
.row-masonry-sm-2 {
	-moz-column-count: 2;
	-webkit-column-count: 2;
	column-count: 2;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 2;
	-webkit-columns: 2;
	columns: 2;
}
.no-csscolumns .row-masonry-sm-2 {
	text-align: justify;
}
.no-csscolumns .row-masonry-sm-2:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-sm-2 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 49%;
}
.row-masonry-sm-3 {
	-moz-column-count: 3;
	-webkit-column-count: 3;
	column-count: 3;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 3;
	-webkit-columns: 3;
	columns: 3;
}
.no-csscolumns .row-masonry-sm-3 {
	text-align: justify;
}
.no-csscolumns .row-masonry-sm-3:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-sm-3 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 32%;
}
.row-masonry-sm-4 {
	-moz-column-count: 4;
	-webkit-column-count: 4;
	column-count: 4;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 4;
	-webkit-columns: 4;
	columns: 4;
}
.no-csscolumns .row-masonry-sm-4 {
	text-align: justify;
}
.no-csscolumns .row-masonry-sm-4:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-sm-4 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 24%;
}
.row-masonry-sm-5 {
	-moz-column-count: 5;
	-webkit-column-count: 5;
	column-count: 5;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 5;
	-webkit-columns: 5;
	columns: 5;
}
.no-csscolumns .row-masonry-sm-5 {
	text-align: justify;
}
.no-csscolumns .row-masonry-sm-5:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-sm-5 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 19%;
}
.row-masonry-sm-6 {
	-moz-column-count: 6;
	-webkit-column-count: 6;
	column-count: 6;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 6;
	-webkit-columns: 6;
	columns: 6;
}
.no-csscolumns .row-masonry-sm-6 {
	text-align: justify;
}
.no-csscolumns .row-masonry-sm-6:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-sm-6 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 15%;
}
.row-masonry-sm-7 {
	-moz-column-count: 7;
	-webkit-column-count: 7;
	column-count: 7;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 7;
	-webkit-columns: 7;
	columns: 7;
}
.no-csscolumns .row-masonry-sm-7 {
	text-align: justify;
}
.no-csscolumns .row-masonry-sm-7:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-sm-7 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 13%;
}
.row-masonry-sm-8 {
	-moz-column-count: 8;
	-webkit-column-count: 8;
	column-count: 8;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 8;
	-webkit-columns: 8;
	columns: 8;
}
.no-csscolumns .row-masonry-sm-8 {
	text-align: justify;
}
.no-csscolumns .row-masonry-sm-8:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-sm-8 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 11%;
}
.row-masonry-sm-9 {
	-moz-column-count: 9;
	-webkit-column-count: 9;
	column-count: 9;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 9;
	-webkit-columns: 9;
	columns: 9;
}
.no-csscolumns .row-masonry-sm-9 {
	text-align: justify;
}
.no-csscolumns .row-masonry-sm-9:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-sm-9 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 10%;
}
.row-masonry-sm-10 {
	-moz-column-count: 10;
	-webkit-column-count: 10;
	column-count: 10;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 10;
	-webkit-columns: 10;
	columns: 10;
}
.no-csscolumns .row-masonry-sm-10 {
	text-align: justify;
}
.no-csscolumns .row-masonry-sm-10:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-sm-10 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 9%;
}
.row-masonry-sm-11 {
	-moz-column-count: 11;
	-webkit-column-count: 11;
	column-count: 11;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 11;
	-webkit-columns: 11;
	columns: 11;
}
.no-csscolumns .row-masonry-sm-11 {
	text-align: justify;
}
.no-csscolumns .row-masonry-sm-11:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-sm-11 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 8%;
}
.row-masonry-sm-12 {
	-moz-column-count: 12;
	-webkit-column-count: 12;
	column-count: 12;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 12;
	-webkit-columns: 12;
	columns: 12;
}
.no-csscolumns .row-masonry-sm-12 {
	text-align: justify;
}
.no-csscolumns .row-masonry-sm-12:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-sm-12 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 7%;
}
}
@media only screen and (min-width: 768px) {
.row-masonry-md-1 {
	-moz-column-count: 1;
	-webkit-column-count: 1;
	column-count: 1;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 1;
	-webkit-columns: 1;
	columns: 1;
}
.no-csscolumns .row-masonry-md-1 {
	text-align: justify;
}
.no-csscolumns .row-masonry-md-1:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-md-1 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 99%;
}
.row-masonry-md-2 {
	-moz-column-count: 2;
	-webkit-column-count: 2;
	column-count: 2;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 2;
	-webkit-columns: 2;
	columns: 2;
}
.no-csscolumns .row-masonry-md-2 {
	text-align: justify;
}
.no-csscolumns .row-masonry-md-2:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-md-2 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 49%;
}
.row-masonry-md-3 {
	-moz-column-count: 3;
	-webkit-column-count: 3;
	column-count: 3;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 3;
	-webkit-columns: 3;
	columns: 3;
}
.no-csscolumns .row-masonry-md-3 {
	text-align: justify;
}
.no-csscolumns .row-masonry-md-3:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-md-3 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 32%;
}
.row-masonry-md-4 {
	-moz-column-count: 4;
	-webkit-column-count: 4;
	column-count: 4;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 4;
	-webkit-columns: 4;
	columns: 4;
}
.no-csscolumns .row-masonry-md-4 {
	text-align: justify;
}
.no-csscolumns .row-masonry-md-4:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-md-4 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 24%;
}
.row-masonry-md-5 {
	-moz-column-count: 5;
	-webkit-column-count: 5;
	column-count: 5;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 5;
	-webkit-columns: 5;
	columns: 5;
}
.no-csscolumns .row-masonry-md-5 {
	text-align: justify;
}
.no-csscolumns .row-masonry-md-5:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-md-5 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 19%;
}
.row-masonry-md-6 {
	-moz-column-count: 6;
	-webkit-column-count: 6;
	column-count: 6;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 6;
	-webkit-columns: 6;
	columns: 6;
}
.no-csscolumns .row-masonry-md-6 {
	text-align: justify;
}
.no-csscolumns .row-masonry-md-6:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-md-6 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 15%;
}
.row-masonry-md-7 {
	-moz-column-count: 7;
	-webkit-column-count: 7;
	column-count: 7;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 7;
	-webkit-columns: 7;
	columns: 7;
}
.no-csscolumns .row-masonry-md-7 {
	text-align: justify;
}
.no-csscolumns .row-masonry-md-7:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-md-7 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 13%;
}
.row-masonry-md-8 {
	-moz-column-count: 8;
	-webkit-column-count: 8;
	column-count: 8;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 8;
	-webkit-columns: 8;
	columns: 8;
}
.no-csscolumns .row-masonry-md-8 {
	text-align: justify;
}
.no-csscolumns .row-masonry-md-8:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-md-8 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 11%;
}
.row-masonry-md-9 {
	-moz-column-count: 9;
	-webkit-column-count: 9;
	column-count: 9;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 9;
	-webkit-columns: 9;
	columns: 9;
}
.no-csscolumns .row-masonry-md-9 {
	text-align: justify;
}
.no-csscolumns .row-masonry-md-9:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-md-9 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 10%;
}
.row-masonry-md-10 {
	-moz-column-count: 10;
	-webkit-column-count: 10;
	column-count: 10;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 10;
	-webkit-columns: 10;
	columns: 10;
}
.no-csscolumns .row-masonry-md-10 {
	text-align: justify;
}
.no-csscolumns .row-masonry-md-10:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-md-10 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 9%;
}
.row-masonry-md-11 {
	-moz-column-count: 11;
	-webkit-column-count: 11;
	column-count: 11;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 11;
	-webkit-columns: 11;
	columns: 11;
}
.no-csscolumns .row-masonry-md-11 {
	text-align: justify;
}
.no-csscolumns .row-masonry-md-11:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-md-11 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 8%;
}
.row-masonry-md-12 {
	-moz-column-count: 12;
	-webkit-column-count: 12;
	column-count: 12;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 12;
	-webkit-columns: 12;
	columns: 12;
}
.no-csscolumns .row-masonry-md-12 {
	text-align: justify;
}
.no-csscolumns .row-masonry-md-12:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-md-12 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 7%;
}
}
@media only screen and (min-width: 992px) {
.row-masonry-lg-1 {
	-moz-column-count: 1;
	-webkit-column-count: 1;
	column-count: 1;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 1;
	-webkit-columns: 1;
	columns: 1;
}
.no-csscolumns .row-masonry-lg-1 {
	text-align: justify;
}
.no-csscolumns .row-masonry-lg-1:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-lg-1 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 99%;
}
.row-masonry-lg-2 {
	-moz-column-count: 2;
	-webkit-column-count: 2;
	column-count: 2;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 2;
	-webkit-columns: 2;
	columns: 2;
}
.no-csscolumns .row-masonry-lg-2 {
	text-align: justify;
}
.no-csscolumns .row-masonry-lg-2:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-lg-2 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 49%;
}
.row-masonry-lg-3 {
	-moz-column-count: 3;
	-webkit-column-count: 3;
	column-count: 3;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 3;
	-webkit-columns: 3;
	columns: 3;
}
.no-csscolumns .row-masonry-lg-3 {
	text-align: justify;
}
.no-csscolumns .row-masonry-lg-3:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-lg-3 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 32%;
}
.row-masonry-lg-4 {
	-moz-column-count: 4;
	-webkit-column-count: 4;
	column-count: 4;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 4;
	-webkit-columns: 4;
	columns: 4;
}
.no-csscolumns .row-masonry-lg-4 {
	text-align: justify;
}
.no-csscolumns .row-masonry-lg-4:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-lg-4 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 24%;
}
.row-masonry-lg-5 {
	-moz-column-count: 5;
	-webkit-column-count: 5;
	column-count: 5;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 5;
	-webkit-columns: 5;
	columns: 5;
}
.no-csscolumns .row-masonry-lg-5 {
	text-align: justify;
}
.no-csscolumns .row-masonry-lg-5:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-lg-5 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 19%;
}
.row-masonry-lg-6 {
	-moz-column-count: 6;
	-webkit-column-count: 6;
	column-count: 6;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 6;
	-webkit-columns: 6;
	columns: 6;
}
.no-csscolumns .row-masonry-lg-6 {
	text-align: justify;
}
.no-csscolumns .row-masonry-lg-6:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-lg-6 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 15%;
}
.row-masonry-lg-7 {
	-moz-column-count: 7;
	-webkit-column-count: 7;
	column-count: 7;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 7;
	-webkit-columns: 7;
	columns: 7;
}
.no-csscolumns .row-masonry-lg-7 {
	text-align: justify;
}
.no-csscolumns .row-masonry-lg-7:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-lg-7 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 13%;
}
.row-masonry-lg-8 {
	-moz-column-count: 8;
	-webkit-column-count: 8;
	column-count: 8;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 8;
	-webkit-columns: 8;
	columns: 8;
}
.no-csscolumns .row-masonry-lg-8 {
	text-align: justify;
}
.no-csscolumns .row-masonry-lg-8:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-lg-8 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 11%;
}
.row-masonry-lg-9 {
	-moz-column-count: 9;
	-webkit-column-count: 9;
	column-count: 9;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 9;
	-webkit-columns: 9;
	columns: 9;
}
.no-csscolumns .row-masonry-lg-9 {
	text-align: justify;
}
.no-csscolumns .row-masonry-lg-9:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-lg-9 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 10%;
}
.row-masonry-lg-10 {
	-moz-column-count: 10;
	-webkit-column-count: 10;
	column-count: 10;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 10;
	-webkit-columns: 10;
	columns: 10;
}
.no-csscolumns .row-masonry-lg-10 {
	text-align: justify;
}
.no-csscolumns .row-masonry-lg-10:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-lg-10 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 9%;
}
.row-masonry-lg-11 {
	-moz-column-count: 11;
	-webkit-column-count: 11;
	column-count: 11;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 11;
	-webkit-columns: 11;
	columns: 11;
}
.no-csscolumns .row-masonry-lg-11 {
	text-align: justify;
}
.no-csscolumns .row-masonry-lg-11:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-lg-11 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 8%;
}
.row-masonry-lg-12 {
	-moz-column-count: 12;
	-webkit-column-count: 12;
	column-count: 12;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 12;
	-webkit-columns: 12;
	columns: 12;
}
.no-csscolumns .row-masonry-lg-12 {
	text-align: justify;
}
.no-csscolumns .row-masonry-lg-12:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-lg-12 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 7%;
}
}
@media only screen and (min-width: 1200px) {
.row-masonry-xl-1 {
	-moz-column-count: 1;
	-webkit-column-count: 1;
	column-count: 1;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 1;
	-webkit-columns: 1;
	columns: 1;
}
.no-csscolumns .row-masonry-xl-1 {
	text-align: justify;
}
.no-csscolumns .row-masonry-xl-1:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-xl-1 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 99%;
}
.row-masonry-xl-2 {
	-moz-column-count: 2;
	-webkit-column-count: 2;
	column-count: 2;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 2;
	-webkit-columns: 2;
	columns: 2;
}
.no-csscolumns .row-masonry-xl-2 {
	text-align: justify;
}
.no-csscolumns .row-masonry-xl-2:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-xl-2 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 49%;
}
.row-masonry-xl-3 {
	-moz-column-count: 3;
	-webkit-column-count: 3;
	column-count: 3;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 3;
	-webkit-columns: 3;
	columns: 3;
}
.no-csscolumns .row-masonry-xl-3 {
	text-align: justify;
}
.no-csscolumns .row-masonry-xl-3:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-xl-3 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 32%;
}
.row-masonry-xl-4 {
	-moz-column-count: 4;
	-webkit-column-count: 4;
	column-count: 4;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 4;
	-webkit-columns: 4;
	columns: 4;
}
.no-csscolumns .row-masonry-xl-4 {
	text-align: justify;
}
.no-csscolumns .row-masonry-xl-4:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-xl-4 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 24%;
}
.row-masonry-xl-5 {
	-moz-column-count: 5;
	-webkit-column-count: 5;
	column-count: 5;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 5;
	-webkit-columns: 5;
	columns: 5;
}
.no-csscolumns .row-masonry-xl-5 {
	text-align: justify;
}
.no-csscolumns .row-masonry-xl-5:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-xl-5 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 19%;
}
.row-masonry-xl-6 {
	-moz-column-count: 6;
	-webkit-column-count: 6;
	column-count: 6;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 6;
	-webkit-columns: 6;
	columns: 6;
}
.no-csscolumns .row-masonry-xl-6 {
	text-align: justify;
}
.no-csscolumns .row-masonry-xl-6:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-xl-6 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 15%;
}
.row-masonry-xl-7 {
	-moz-column-count: 7;
	-webkit-column-count: 7;
	column-count: 7;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 7;
	-webkit-columns: 7;
	columns: 7;
}
.no-csscolumns .row-masonry-xl-7 {
	text-align: justify;
}
.no-csscolumns .row-masonry-xl-7:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-xl-7 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 13%;
}
.row-masonry-xl-8 {
	-moz-column-count: 8;
	-webkit-column-count: 8;
	column-count: 8;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 8;
	-webkit-columns: 8;
	columns: 8;
}
.no-csscolumns .row-masonry-xl-8 {
	text-align: justify;
}
.no-csscolumns .row-masonry-xl-8:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-xl-8 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 11%;
}
.row-masonry-xl-9 {
	-moz-column-count: 9;
	-webkit-column-count: 9;
	column-count: 9;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 9;
	-webkit-columns: 9;
	columns: 9;
}
.no-csscolumns .row-masonry-xl-9 {
	text-align: justify;
}
.no-csscolumns .row-masonry-xl-9:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-xl-9 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 10%;
}
.row-masonry-xl-10 {
	-moz-column-count: 10;
	-webkit-column-count: 10;
	column-count: 10;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 10;
	-webkit-columns: 10;
	columns: 10;
}
.no-csscolumns .row-masonry-xl-10 {
	text-align: justify;
}
.no-csscolumns .row-masonry-xl-10:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-xl-10 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 9%;
}
.row-masonry-xl-11 {
	-moz-column-count: 11;
	-webkit-column-count: 11;
	column-count: 11;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 11;
	-webkit-columns: 11;
	columns: 11;
}
.no-csscolumns .row-masonry-xl-11 {
	text-align: justify;
}
.no-csscolumns .row-masonry-xl-11:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-xl-11 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 8%;
}
.row-masonry-xl-12 {
	-moz-column-count: 12;
	-webkit-column-count: 12;
	column-count: 12;
	-moz-column-gap: 15px;
	-webkit-column-gap: 15px;
	column-gap: 15px;
	-moz-columns: 12;
	-webkit-columns: 12;
	columns: 12;
}
.no-csscolumns .row-masonry-xl-12 {
	text-align: justify;
}
.no-csscolumns .row-masonry-xl-12:after {
	content: '';
	display: inline-block;
	width: 100%;
}
.no-csscolumns .row-masonry-xl-12 > .col-masonry {
	vertical-align: top;
	display: inline-block;
	width: 7%;
}
}
/**
Component: row-extra.less
*/

.container-lg {
	max-width: 1200px;
	width: auto;
}
.container-md {
	max-width: 768px;
	width: auto;
}
.container-sm {
	max-width: 640px;
	width: auto;
}
@media only screen and (min-width: 480px) {
.row-table {
	display: table;
	table-layout: fixed;
	height: 100%;
	width: 100%;
	margin: 0;
}
 .row-table > [class*="col-"] {
 display: table-cell;
 float: none;
 table-layout: fixed;
 vertical-align: middle;
}
}
.row-flush > [class*="col-"] {
 padding-left: 0;
 padding-right: 0;
}
.container-flush .row {
	margin-left: -5px;
	margin-right: -5px;
}
.container-flush .row > [class*="col-"] {
 padding-left: 5px;
 padding-right: 5px;
}
/**
Component: table-extras.less
*/

.table > thead > tr > th {
	padding: 14px 8px;
	color: #888;
}

.table > tbody > tr > td {
	vertical-align: middle;
}
.table > tbody > tr > td > .media img {
	width: 36px;
	height: 36px;
	margin: 0 auto;
}
.table .checkbox {
	margin: 0 auto;
	width: 20px;
}
.table .progress {
	margin-bottom: 0;
}
.table .radial-bar {
	margin-bottom: 0;
	margin: 0 auto;
}
.jqstooltip {
	box-sizing: content-box;
}
#table-res-1 th:nth-child(1) {
	width: 3%;
}
#table-res-1 th:nth-child(2) {
	width: 5%;
}
#table-res-1 th:nth-child(7) {
	width: 5%;
}
#table-res-1 th:nth-child(9) {
	width: 5%;
}
#table-res-2 th:nth-child(1) {
	width: 5%;
}
#table-res-2 th:nth-child(3) {
	width: 10%;
}
table.dataTable {
	border-bottom: 1px solid #e6e9ee !important;
}

table.b {
	border: 1px solid #e6e9ee !important;
}

table.dataTable thead th, table.dataTable thead td {
	border-bottom: 1px solid #e6e9ee !important;
}
/**
Component: alerts.less
*/

.alerts {
	position: fixed;
	top: 60px;
	left: 60px;
	z-index: 9090;
	width: 350px;
	left: auto;
	right: 60px;
}
.alerts:hover .alert .close {
	opacity: 1;
	visibility: visible;
}
.alerts-top-right, .alerts-bottom-right {
	left: auto;
	right: 60px;
}
.alerts-top-center, .alerts-bottom-center {
	left: 50%;
	margin-left: -175px;
}
.alerts-bottom-left, .alerts-bottom-right, .alerts-bottom-center {
	top: auto;
	bottom: 60px;
}
@media (max-width: 480px) {
.alerts {
	left: 10px;
	right: 10px;
	width: auto;
	margin: 0;
}
}
/**
Component: button-extra.less
*/

.btn {
	-webkit-appearance: none;
	outline: none !important;
	-webkit-transition: all 0.1s;
	-o-transition: all 0.1s;
	transition: all 0.1s;
}
.btn.btn-link {
	-webkit-box-shadow: 0 0 0 #000000 !important;
	box-shadow: 0 0 0 #000000 !important;
	border: 0;
}
.btn.btn-default {
	border-color: #ccc;
}
.input-group .input-sm + .input-group-btn .btn {
	font-size: 13px;
}
.btn:active, .btn.active, .btn:hover, .btn:focus {
	box-shadow: 0 3px 10px rgba(0, 0, 0, 0.23), 0 3px 10px rgba(0, 0, 0, 0.16);
}
.btn.disabled, .btn[disabled], fieldset[disabled] .btn, .btn.disabled:hover, .btn[disabled]:hover, fieldset[disabled] .btn:hover, .btn.disabled:focus, .btn[disabled]:focus, fieldset[disabled] .btn:focus, .btn.disabled.focus, .btn[disabled].focus, fieldset[disabled] .btn.focus, .btn.disabled:active, .btn[disabled]:active, fieldset[disabled] .btn:active, .btn.disabled.active, .btn[disabled].active, fieldset[disabled] .btn.active {
/*  box-shadow: 0 0 0 #000;*/
}
.btn-group.open .dropdown-toggle {
	box-shadow: 0 3px 10px rgba(0, 0, 0, 0.23), 0 3px 10px rgba(0, 0, 0, 0.16);
}
.btn-group-small .btn {
	padding: 10px;
	margin-bottom: 5px;
	font-size: 11px;
}
.btn-image {
	padding: 0 !important;
	border: 0;
}
.btn-image:hover, .btn-image:focus {
	box-shadow: 0 3px 10px rgba(0, 0, 0, 0.23), 0 3px 10px rgba(0, 0, 0, 0.16);
}
.btn-group-xxs > .btn, .btn-xxs {
	padding: 1px 5px;
	font-size: 10px;
	line-height: 1.6;
	border-radius: 3px;
}
.btn-inverse {
	color: #ffffff !important;
	background-color: #363f45;
	border-color: #363f45;
}
.btn-inverse:focus, .btn-inverse.focus {
	color: #ffffff;
	background-color: #2d353a;
	border-color: #2d353a;
}
.btn-inverse:hover {
	color: #ffffff;
	background-color: #2d353a;
	border-color: #2d353a;
}
.btn-inverse:active, .btn-inverse.active, .open > .dropdown-toggle.btn-inverse {
	color: #ffffff;
}
.btn-inverse:active:hover, .btn-inverse.active:hover, .open > .dropdown-toggle.btn-inverse:hover, .btn-inverse:active:focus, .btn-inverse.active:focus, .open > .dropdown-toggle.btn-inverse:focus, .btn-inverse:active.focus, .btn-inverse.active.focus, .open > .dropdown-toggle.btn-inverse.focus {
	color: #ffffff;
}
.btn-inverse:active, .btn-inverse.active, .open > .dropdown-toggle.btn-inverse {
	background-image: none;
}
.btn-inverse.disabled, .btn-inverse[disabled], fieldset[disabled] .btn-inverse, .btn-inverse.disabled:hover, .btn-inverse[disabled]:hover, fieldset[disabled] .btn-inverse:hover, .btn-inverse.disabled:focus, .btn-inverse[disabled]:focus, fieldset[disabled] .btn-inverse:focus, .btn-inverse.disabled.focus, .btn-inverse[disabled].focus, fieldset[disabled] .btn-inverse.focus, .btn-inverse.disabled:active, .btn-inverse[disabled]:active, fieldset[disabled] .btn-inverse:active, .btn-inverse.disabled.active, .btn-inverse[disabled].active, fieldset[disabled] .btn-inverse.active {
	background-color: #363f45;
	border-color: #363f45;
}
.btn-inverse .badge {
	color: #363f45;
	background-color: #ffffff;
}
.btn-amber {
	color: #ffffff !important;
	background-color: #ffc107;
	border-color: transparent;
}
.btn-amber:focus, .btn-amber.focus {
	color: #ffffff;
	background-color: #f2b500;
	border-color: rgba(0, 0, 0, 0);
}
.btn-amber:hover {
	color: #ffffff;
	background-color: #f2b500;
	border-color: rgba(0, 0, 0, 0);
}
.btn-amber:active, .btn-amber.active, .open > .dropdown-toggle.btn-amber {
	color: #ffffff;
}
.btn-amber:active:hover, .btn-amber.active:hover, .open > .dropdown-toggle.btn-amber:hover, .btn-amber:active:focus, .btn-amber.active:focus, .open > .dropdown-toggle.btn-amber:focus, .btn-amber:active.focus, .btn-amber.active.focus, .open > .dropdown-toggle.btn-amber.focus {
	color: #ffffff;
}
.btn-amber:active, .btn-amber.active, .open > .dropdown-toggle.btn-amber {
	background-image: none;
}
.btn-amber.disabled, .btn-amber[disabled], fieldset[disabled] .btn-amber, .btn-amber.disabled:hover, .btn-amber[disabled]:hover, fieldset[disabled] .btn-amber:hover, .btn-amber.disabled:focus, .btn-amber[disabled]:focus, fieldset[disabled] .btn-amber:focus, .btn-amber.disabled.focus, .btn-amber[disabled].focus, fieldset[disabled] .btn-amber.focus, .btn-amber.disabled:active, .btn-amber[disabled]:active, fieldset[disabled] .btn-amber:active, .btn-amber.disabled.active, .btn-amber[disabled].active, fieldset[disabled] .btn-amber.active {
	background-color: #ffc107;
	border-color: transparent;
}
.btn-amber .badge {
	color: #ffc107;
	background-color: #ffffff;
}
.btn-purple {
	color: #ffffff !important;
	background-color: #6639b6;
	border-color: transparent;
}
.btn-purple:focus, .btn-purple.focus {
	color: #ffffff;
	background-color: #5d34a6;
	border-color: rgba(0, 0, 0, 0);
}
.btn-purple:hover {
	color: #ffffff;
	background-color: #5d34a6;
	border-color: rgba(0, 0, 0, 0);
}
.btn-purple:active, .btn-purple.active, .open > .dropdown-toggle.btn-purple {
	color: #ffffff;
}
.btn-purple:active:hover, .btn-purple.active:hover, .open > .dropdown-toggle.btn-purple:hover, .btn-purple:active:focus, .btn-purple.active:focus, .open > .dropdown-toggle.btn-purple:focus, .btn-purple:active.focus, .btn-purple.active.focus, .open > .dropdown-toggle.btn-purple.focus {
	color: #ffffff;
}
.btn-purple:active, .btn-purple.active, .open > .dropdown-toggle.btn-purple {
	background-image: none;
}
.btn-purple.disabled, .btn-purple[disabled], fieldset[disabled] .btn-purple, .btn-purple.disabled:hover, .btn-purple[disabled]:hover, fieldset[disabled] .btn-purple:hover, .btn-purple.disabled:focus, .btn-purple[disabled]:focus, fieldset[disabled] .btn-purple:focus, .btn-purple.disabled.focus, .btn-purple[disabled].focus, fieldset[disabled] .btn-purple.focus, .btn-purple.disabled:active, .btn-purple[disabled]:active, fieldset[disabled] .btn-purple:active, .btn-purple.disabled.active, .btn-purple[disabled].active, fieldset[disabled] .btn-purple.active {
	background-color: #6639b6;
	border-color: transparent;
}
.btn-purple .badge {
	color: #6639b6;
	background-color: #ffffff;
}
.btn-pink {
	color: #ffffff !important;
	background-color: #e91e63;
	border-color: transparent;
}
.btn-pink:focus, .btn-pink.focus {
	color: #ffffff;
	background-color: #dd1659;
	border-color: rgba(0, 0, 0, 0);
}
.btn-pink:hover {
	color: #ffffff;
	background-color: #dd1659;
	border-color: rgba(0, 0, 0, 0);
}
.btn-pink:active, .btn-pink.active, .open > .dropdown-toggle.btn-pink {
	color: #ffffff;
}
.btn-pink:active:hover, .btn-pink.active:hover, .open > .dropdown-toggle.btn-pink:hover, .btn-pink:active:focus, .btn-pink.active:focus, .open > .dropdown-toggle.btn-pink:focus, .btn-pink:active.focus, .btn-pink.active.focus, .open > .dropdown-toggle.btn-pink.focus {
	color: #ffffff;
}
.btn-pink:active, .btn-pink.active, .open > .dropdown-toggle.btn-pink {
	background-image: none;
}
.btn-pink.disabled, .btn-pink[disabled], fieldset[disabled] .btn-pink, .btn-pink.disabled:hover, .btn-pink[disabled]:hover, fieldset[disabled] .btn-pink:hover, .btn-pink.disabled:focus, .btn-pink[disabled]:focus, fieldset[disabled] .btn-pink:focus, .btn-pink.disabled.focus, .btn-pink[disabled].focus, fieldset[disabled] .btn-pink.focus, .btn-pink.disabled:active, .btn-pink[disabled]:active, fieldset[disabled] .btn-pink:active, .btn-pink.disabled.active, .btn-pink[disabled].active, fieldset[disabled] .btn-pink.active {
	background-color: #e91e63;
	border-color: transparent;
}
.btn-pink .badge {
	color: #e91e63;
	background-color: #ffffff;
}
.btn-orange {
	color: #ffffff !important;
	background-color: #fe5621;
	border-color: transparent;
}
.btn-orange:focus, .btn-orange.focus {
	color: #ffffff;
	background-color: #fe470d;
	border-color: rgba(0, 0, 0, 0);
}
.btn-orange:hover {
	color: #ffffff;
	background-color: #fe470d;
	border-color: rgba(0, 0, 0, 0);
}
.btn-orange:active, .btn-orange.active, .open > .dropdown-toggle.btn-orange {
	color: #ffffff;
}
.btn-orange:active:hover, .btn-orange.active:hover, .open > .dropdown-toggle.btn-orange:hover, .btn-orange:active:focus, .btn-orange.active:focus, .open > .dropdown-toggle.btn-orange:focus, .btn-orange:active.focus, .btn-orange.active.focus, .open > .dropdown-toggle.btn-orange.focus {
	color: #ffffff;
}
.btn-orange:active, .btn-orange.active, .open > .dropdown-toggle.btn-orange {
	background-image: none;
}
.btn-orange.disabled, .btn-orange[disabled], fieldset[disabled] .btn-orange, .btn-orange.disabled:hover, .btn-orange[disabled]:hover, fieldset[disabled] .btn-orange:hover, .btn-orange.disabled:focus, .btn-orange[disabled]:focus, fieldset[disabled] .btn-orange:focus, .btn-orange.disabled.focus, .btn-orange[disabled].focus, fieldset[disabled] .btn-orange.focus, .btn-orange.disabled:active, .btn-orange[disabled]:active, fieldset[disabled] .btn-orange:active, .btn-orange.disabled.active, .btn-orange[disabled].active, fieldset[disabled] .btn-orange.active {
	background-color: #fe5621;
	border-color: transparent;
}
.btn-orange .badge {
	color: #fe5621;
	background-color: #ffffff;
}
.btn-outline {
	background-color: transparent;
	border-color: #fff;
}
.btn-outline:hover, .btn-outline:focus {
	background-color: #fff;
	color: #3f51b5;
}
.btn-flat {
	border-bottom-width: 1px;
	border-radius: 0;
	box-shadow: 0 0 0 #000;
}
.btn-xl {
	padding: 20px 16px;
	font-size: 18px;
}
.btn-square {
	border-radius: 0;
}
.btn-pill-left, .btn-oval {
	border-top-left-radius: 50px;
	border-bottom-left-radius: 50px;
	padding-left: 18px;
}
.btn-pill-right, .btn-oval {
	border-top-right-radius: 50px;
	border-bottom-right-radius: 50px;
	padding-right: 18px;
}
.btn-labeled {
	padding-top: 0;
	padding-bottom: 0;
}
.btn-label {
	position: relative;
	display: inline-block;
	min-width: 30px;
	background: transparent;
	padding: 8px 18px;
	left: -24px;
	border-radius: 2px 0 0 2px;
}
.btn-label.btn-label-right {
	left: auto;
	right: -24px;
	border-radius: 0 2px 2px 0;
}
.btn-label:after {
	content: "";
	position: absolute;
	top: 2px;
	bottom: 2px;
	right: 0;
	width: 1px;
	background: rgba(0, 0, 0, 0.1);
}
.btn-lg .btn-label {
	padding: 14px 18px;
	left: -26px;
	border-radius: 3px 0 0 3px;
}
.btn-lg .btn-label.btn-label-right {
	left: auto;
	right: -26px;
	border-radius: 0 3px 3px 0;
}
.btn-sm .btn-label {
	padding: 5px 18px;
	left: -18px;
	border-radius: 1px 0 0 1px;
}
.btn-sm .btn-label.btn-label-right {
	left: auto;
	right: -18px;
	border-radius: 0 1px 1px 0;
}
.btn-xs .btn-label {
	padding: 1px 18px;
	left: -5px;
	border-radius: 1px 0 0 1px;
}
.btn-xs .btn-label.btn-label-right {
	left: auto;
	right: -5px;
	border-radius: 0 1px 1px 0;
}
.btn-fw {
	min-width: 120px;
}
.btn-fw.btn-sm {
	min-width: 80px;
}
.btn-fw.btn-lg {
	min-width: 150px;
}
.btn-circle {
	width: 35px;
	height: 35px;
	text-align: center;
	padding: 6px 0;
	font-size: 12px;
	line-height: 1.428571429;
	border-radius: 500px;
}
.btn-circle.btn-lg {
	width: 50px;
	height: 50px;
	padding: 10px 16px;
	font-size: 18px;
	line-height: 1.33;
	border-radius: 25px;
}
.btn-circle.btn-xl {
	width: 70px;
	height: 70px;
	padding: 10px 16px;
	font-size: 24px;
	line-height: 1.33;
	border-radius: 35px;
}
.btn-offset {
	position: absolute;
	right: 60px;
	height: 35px;
	z-index: 99999;
	top: 40px;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}
.btn-offset .layout-fixed, .btn-offset .layout-material {
	position: fixed;
}
.btn-switch {
	position: relative;
	display: inline-block;
	cursor: pointer;
}
.btn-switch > input[type="checkbox"] {
	position: absolute;
	opacity: 0;
	visibility: hidden;
	cursor: pointer;
	left: -100%;
	top: -100%;
}
.btn-switch > input[type="checkbox"] + .btn {
	background-color: transparent;
	border-color: #3f51b5;
	color: #3f51b5;
	text-align: left;
}
.btn-switch > input[type="checkbox"] + .btn > em {
	position: relative;
	top: -1px;
	display: inline-block;
	border: 1px solid #3f51b5;
	border-radius: 50%;
	padding: 3px;
	margin: 0 4px 0 0;
	font-size: 10px;
	text-align: center;
}
.btn-switch > input[type="checkbox"] + .btn > em:before {
	position: relative;
	top: 1px;
	opacity: 0;
}
.btn-switch > input[type="checkbox"]:checked + .btn {
	background-color: #3f51b5;
	color: #fff;
}
.btn-switch > input[type="checkbox"]:checked + .btn > em {
	background-color: #fff;
	color: #3f51b5;
}
.btn-switch > input[type="checkbox"]:checked + .btn > em:before {
	opacity: 1;
}
.btn-switch-primary > input[type="checkbox"] + .btn {
	border-color: #3f51b5;
	color: #3f51b5 !important;
}
.btn-switch-primary > input[type="checkbox"] + .btn > em {
	border-color: #3f51b5;
}
.btn-switch-primary > input[type="checkbox"]:checked + .btn {
	background-color: #3f51b5;
	color: #fff !important;
}
.btn-switch-primary > input[type="checkbox"]:checked + .btn > em {
	color: #3f51b5 !important;
}
.btn-switch-success > input[type="checkbox"] + .btn {
	border-color: #4caf50;
	color: #4caf50 !important;
}
.btn-switch-success > input[type="checkbox"] + .btn > em {
	border-color: #4caf50;
}
.btn-switch-success > input[type="checkbox"]:checked + .btn {
	background-color: #4caf50;
	color: #fff !important;
}
.btn-switch-success > input[type="checkbox"]:checked + .btn > em {
	color: #4caf50 !important;
}
.btn-switch-info > input[type="checkbox"] + .btn {
	border-color: #2095f2;
	color: #2095f2 !important;
}
.btn-switch-info > input[type="checkbox"] + .btn > em {
	border-color: #2095f2;
}
.btn-switch-info > input[type="checkbox"]:checked + .btn {
	background-color: #2095f2;
	color: #fff !important;
}
.btn-switch-info > input[type="checkbox"]:checked + .btn > em {
	color: #2095f2 !important;
}
.btn-switch-warning > input[type="checkbox"] + .btn {
	border-color: #fe9700;
	color: #fe9700 !important;
}
.btn-switch-warning > input[type="checkbox"] + .btn > em {
	border-color: #fe9700;
}
.btn-switch-warning > input[type="checkbox"]:checked + .btn {
	background-color: #fe9700;
	color: #fff !important;
}
.btn-switch-warning > input[type="checkbox"]:checked + .btn > em {
	color: #fe9700 !important;
}
.btn-switch-danger > input[type="checkbox"] + .btn {
	border-color: #f34235;
	color: #f34235 !important;
}
.btn-switch-danger > input[type="checkbox"] + .btn > em {
	border-color: #f34235;
}
.btn-switch-danger > input[type="checkbox"]:checked + .btn {
	background-color: #f34235;
	color: #fff !important;
}
.btn-switch-danger > input[type="checkbox"]:checked + .btn > em {
	color: #f34235 !important;
}
.btn-switch-inverse > input[type="checkbox"] + .btn {
	border-color: #363f45;
	color: #363f45 !important;
}
.btn-switch-inverse > input[type="checkbox"] + .btn > em {
	border-color: #363f45;
}
.btn-switch-inverse > input[type="checkbox"]:checked + .btn {
	background-color: #363f45;
	color: #fff !important;
}
.btn-switch-inverse > input[type="checkbox"]:checked + .btn > em {
	color: #363f45 !important;
}
.btn-switch-amber > input[type="checkbox"] + .btn {
	border-color: #ffc107;
	color: #ffc107 !important;
}
.btn-switch-amber > input[type="checkbox"] + .btn > em {
	border-color: #ffc107;
}
.btn-switch-amber > input[type="checkbox"]:checked + .btn {
	background-color: #ffc107;
	color: #fff !important;
}
.btn-switch-amber > input[type="checkbox"]:checked + .btn > em {
	color: #ffc107 !important;
}
.btn-switch-pink > input[type="checkbox"] + .btn {
	border-color: #e91e63;
	color: #e91e63 !important;
}
.btn-switch-pink > input[type="checkbox"] + .btn > em {
	border-color: #e91e63;
}
.btn-switch-pink > input[type="checkbox"]:checked + .btn {
	background-color: #e91e63;
	color: #fff !important;
}
.btn-switch-pink > input[type="checkbox"]:checked + .btn > em {
	color: #e91e63 !important;
}
.btn-switch-purple > input[type="checkbox"] + .btn {
	border-color: #6639b6;
	color: #6639b6 !important;
}
.btn-switch-purple > input[type="checkbox"] + .btn > em {
	border-color: #6639b6;
}
.btn-switch-purple > input[type="checkbox"]:checked + .btn {
	background-color: #6639b6;
	color: #fff !important;
}
.btn-switch-purple > input[type="checkbox"]:checked + .btn > em {
	color: #6639b6 !important;
}
.btn-switch-orange > input[type="checkbox"] + .btn {
	border-color: #fe5621;
	color: #fe5621 !important;
}
.btn-switch-orange > input[type="checkbox"] + .btn > em {
	border-color: #fe5621;
}
.btn-switch-orange > input[type="checkbox"]:checked + .btn {
	background-color: #fe5621;
	color: #fff !important;
}
.btn-switch-orange > input[type="checkbox"]:checked + .btn > em {
	color: #fe5621 !important;
}
/**
Component: dropdown-extras.less
*/

.dropdown-lg > .dropdown-menu {
	min-width: 200px;
}
.navbar-nav .open .dropdown-menu {
	background-color: #fff;
}
.dropdown-list > .dropdown-menu {
	padding: 0;
	min-width: 220px;
}
.dropdown-list .list-group {
	margin: 0;
}
.dropdown-list .list-group-item {
	border-radius: 0;
	border-left: 0;
	border-right: 0;
}
.dropdown-list .list-group-item:first-child {
	border-top: 0;
}
.dropdown-list .list-group-item:last-child {
	border-bottom: 0;
}
.dropdown > a {
	position: relative;
}
.dropdown > a > .label {
	position: absolute;
	top: 10px;
	right: 10px;
	padding: 2px 5px;
}
@media only screen and (min-width: 768px) {
.dropdown > a > .label {
	right: 0;
}
}
.dropdown-menu-header {
	padding: 10px 15px;
	background-color: #fafafa;
	border-bottom: 1px solid #e1e1e1;
}
.dropdown-menu[offcanvas-dropdown], .dropdown-menu[data-offcanvas-dropdown] {
	overflow: hidden;
}
.dropdown-menu[offcanvas-dropdown] .offcanvas-content, .dropdown-menu[data-offcanvas-dropdown] .offcanvas-content {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	background-color: #fff;
	overflow-y: auto;
	transition: all ease .2s;
	-webkit-transition: all ease .2s;
	z-index: 1;
	color: #585f69;
}
.dropdown-menu[offcanvas-dropdown] .offcanvas-content.ng-hide, .dropdown-menu[data-offcanvas-dropdown] .offcanvas-content.ng-hide {
	-webkit-transform: translate3d(90%, 0, 0);
	transform: translate3d(90%, 0, 0);
}
.offcanvas-toggle {
	font-weight: bold;
	cursor: pointer;
}
@media only screen and (min-width: 768px) {
.dropdown .dropdown-menu {
	display: block;
	-webkit-transform-origin: 0 0;
	-moz-transform-origin: 0 0;
	-ms-transform-origin: 0 0;
	transform-origin: 0 0;
	-webkit-transform: scale(0);
	-ms-transform: scale(0);
	-o-transform: scale(0);
	transform: scale(0);
	-webkit-transition: -webkit-transform 0.2s ease-out;
	-moz-transition: -moz-transform 0.2s ease-out;
	-o-transition: -o-transform 0.2s ease-out;
	transition: transform 0.2s ease-out;
}
.dropdown.open .dropdown-menu,
.btn-group.open .dropdown-menu {
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
	-webkit-transition: -webkit-transform 0.2s ease-out;
	-moz-transition: -moz-transform 0.2s ease-out;
	-o-transition: -o-transform 0.2s ease-out;
	transition: transform 0.2s ease-out;
}
.navbar-right .dropdown .dropdown-menu {
	-webkit-transform-origin: 100% 0;
	-moz-transform-origin: 100% 0;
	-ms-transform-origin: 100% 0;
	transform-origin: 100% 0;
}
}
/**
Component: panels.less
*/
/*.panel {
position: relative;
box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
border-radius: 0;
border: 0;
}*/

.panel {
	border-radius: 2px;
}
.panel .panel-heading {
	position: relative;
	/*border-bottom-width: 0;*/

	border-radius: 0;
	z-index: 2;
}
/*.panel .panel-heading .panel-title {
font-size: 20px;
padding-top: 20px;
}*/

.panel .panel-heading .panel-title > em {
	position: absolute;
	top: 10px;
	right: 15px;
}
.panel .panel-heading:after {
	content: "";
	display: block;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
	height: 100%;
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}
.panel .panel-heading.no-shadow:after {
	display: none;
}
.panel .panel-heading a {
	text-decoration: none !important;
}
.panel.panel-default .panel-heading {
	box-shadow: 0 0 0 0 #000;
}
.panel.panel-default .panel-heading:after {
	display: none;
}
.panel.panel-transparent {
	border: 0;
	background-color: transparent;
	box-shadow: 0 0 0 #000;
}
.panel.panel-transparent .panel-heading, .panel.panel-transparent .panel-body {
	background-color: transparent;
	padding-left: 0;
	padding-right: 0;
	box-shadow: 0 0 0 0 #000;
}
.panel.panel-transparent .panel-heading:after {
	box-shadow: 0 0 0 0 #000 !important;
}
.panel .panel-footer {
	border-radius: 0;
}
.panel .table {
	margin-bottom: 0;
	border: 0;
}
.panel-flat {
	margin: 0 !important;
	border: 0;
}
.panel-group .panel-flat {
	box-shadow: 0 0 0 #000;
}
.panel-group .panel-flat:first-child {
	border-radius: 4px 4px 0 0;
}
.panel-group .panel-flat:last-child {
	border-radius: 0 0 4px 4px;
}
.panel-heading > .btn, .panel-heading > .btn-group {
	margin-top: -5px;
}
.panel-heading > .btn-sm, .panel-heading > .btn-group-sm {
	margin-top: -3px;
}
.panel-heading > .btn-xs, .panel-heading > .btn-group-xs {
	margin-top: -1px;
}
.panel-heading > .label.pull-right {
	margin-top: 3px;
}
.panel-heading > .label.pull-right + .label.pull-right {
	margin-right: 10px;
}
.panel-footer .pagination {
	margin: 0;
}
.panel-footer .radial-bar {
	margin-bottom: 0;
}
.panel-footer p {
	margin-bottom: 0;
}
.panel-heading-active {
	background-color: #4caf50 !important;
	font-weight: bold;
	font-size: 16px;
}
.panel-heading-active > a {
	padding: 15px 0;
	color: #fff !important;
	-webkit-transition: padding 0.5s ease;
	-o-transition: padding 0.5s ease;
	transition: padding 0.5s ease;
}
.panel-heading-active + .panel-collapse:after {
	content: "";
	display: block;
	width: 60%;
	margin: 0 auto;
	height: 0;
	border-bottom: 1px solid #e1e2e3;
}
.panel-inverse {
	border-color: #e6e9ee;
}
.panel-inverse > .panel-heading {
	color: #ffffff;
	background-color: #363f45;
	border-color: #363f45;
}
.panel-inverse > .panel-heading + .panel-collapse > .panel-body {
	border-top-color: #e6e9ee;
}
.panel-inverse > .panel-heading .badge {
	color: #363f45;
	background-color: #ffffff;
}
.panel-inverse > .panel-footer + .panel-collapse > .panel-body {
	border-bottom-color: #e6e9ee;
}
.panel-inverse > .panel-footer {
	border: 0;
}
.panel-amber {
	border-color: #e6e9ee;
}
.panel-amber > .panel-heading {
	color: #ffffff;
	background-color: #ffc107;
	border-color: #ffc107;
}
.panel-amber > .panel-heading + .panel-collapse > .panel-body {
	border-top-color: #e6e9ee;
}
.panel-amber > .panel-heading .badge {
	color: #ffc107;
	background-color: #ffffff;
}
.panel-amber > .panel-footer + .panel-collapse > .panel-body {
	border-bottom-color: #e6e9ee;
}
.panel-amber > .panel-footer {
	border: 0;
}
.panel-pink {
	border-color: #e6e9ee;
}
.panel-pink > .panel-heading {
	color: #ffffff;
	background-color: #e91e63;
	border-color: #e91e63;
}
.panel-pink > .panel-heading + .panel-collapse > .panel-body {
	border-top-color: #e6e9ee;
}
.panel-pink > .panel-heading .badge {
	color: #e91e63;
	background-color: #ffffff;
}
.panel-pink > .panel-footer + .panel-collapse > .panel-body {
	border-bottom-color: #e6e9ee;
}
.panel-pink > .panel-footer {
	border: 0;
}
.panel-purple {
	border-color: #e6e9ee;
}
.panel-purple > .panel-heading {
	color: #ffffff;
	background-color: #6639b6;
	border-color: #6639b6;
}
.panel-purple > .panel-heading + .panel-collapse > .panel-body {
	border-top-color: #e6e9ee;
}
.panel-purple > .panel-heading .badge {
	color: #6639b6;
	background-color: #ffffff;
}
.panel-purple > .panel-footer + .panel-collapse > .panel-body {
	border-bottom-color: #e6e9ee;
}
.panel-purple > .panel-footer {
	border: 0;
}
.panel-orange {
	border-color: #e6e9ee;
}
.panel-orange > .panel-heading {
	color: #ffffff;
	background-color: #fe5621;
	border-color: #fe5621;
}
.panel-orange > .panel-heading + .panel-collapse > .panel-body {
	border-top-color: #e6e9ee;
}
.panel-orange > .panel-heading .badge {
	color: #fe5621;
	background-color: #ffffff;
}
.panel-orange > .panel-footer + .panel-collapse > .panel-body {
	border-bottom-color: #e6e9ee;
}
.panel-orange > .panel-footer {
	border: 0;
}
/**
Component: placeholder.less
*/

.box-placeholder {
	margin-bottom: 12px;
	padding: 15px;
	border: 1px dashed #a0aab2;
	background: #f4f5f5;
	color: #444444;
}
.box-placeholder >:last-child {
	margin-bottom: 0;
}
.box-placeholder-lg {
	padding-top: 80px;
	padding-bottom: 80px;
}
/**
Component: Pagination.less
*/

.pagination {
	border: solid 1px #ddd;
}
.pagination > li > a, .pagination > li > span {
	border: 1px solid #eeeeee;
	color: #585f69;
	font-weight: 500;
	line-height: 1.52857;
	padding: 8px 24px;
}
.pagination > li > a:hover, .pagination > li > span:hover, .pagination > li > a:focus, .pagination > li > span:focus {
	background-color: #fafafa;
	border-color: #eeeeee;
	color: #2b387c;
	z-index: 3;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
	background-color: #3f51b5;
	border-color: #3f51b5;
	color: #ffffff;
	cursor: default;
	z-index: 2;
}
.pagination > .disabled > span, .pagination > .disabled > span:hover, .pagination > .disabled > span:focus, .pagination > .disabled > a, .pagination > .disabled > a:hover, .pagination > .disabled > a:focus {
	background-color: #ffffff;
	border-color: #eeeeee;
	color: #aaa;
	cursor: not-allowed;
}
.pagination-lg > li > a, .pagination-lg > li > span {
	font-size: 17px;
	line-height: 1.33;
	padding: 14px 26px;
}
.pagination-sm > li > a, .pagination-sm > li > span {
	font-size: 12px;
	line-height: 1.5;
	padding: 5px 18px;
}
/**
Component: progress-extra.less
*/

.progress-sm {
	height: 14px;
}
.progress-xs {
	height: 8px;
}
.progress-xxs {
	height: 2px;
}
.progress-bar-purple {
	background-color: #6639b6;
}
.progress-striped .progress-bar-purple {
	background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-inverse {
	background-color: #363f45;
}
.progress-striped .progress-bar-inverse {
	background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-amber {
	background-color: #ffc107;
}
.progress-striped .progress-bar-amber {
	background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-pink {
	background-color: #e91e63;
}
.progress-striped .progress-bar-pink {
	background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
/**
Component: utils-background.less
*/

.bg-gray {
	background-color: #a0aab2 !important;
	color: #ffffff;
}
.bg-gray a {
	color: #e3e6e8;
}
.bg-gray a:focus, .bg-gray a:hover {
	color: #ffffff;
}
.bg-gray .nav > li > a {
	color: #ffffff;
}
.bg-gray .nav > li.active {
	background-color: #95a0a9 !important;
}
.bg-gray .nav > li:hover > a, .bg-gray .nav > li.active > a {
	color: #ffffff;
}
.bg-gray .nav > li:hover > a, .bg-gray .nav > li.active > a {
	background-color: #8996a0;
}
.bg-gray .sidebar-subnav {
	background-color: #a0aab2;
}
.bg-gray .sidebar-subnav > li.active > a {
	font-weight: bold;
	background-color: transparent;
}
.bg-gray .navbar-form .form-control {
	background-color: #a0aab2;
	color: #ffffff;
}
.bg-gray .navbar-form .form-control::-moz-placeholder {
 color: #e9eced;
 opacity: 1;
}
.bg-gray .navbar-form .form-control:-ms-input-placeholder {
 color: #e9eced;
}
.bg-gray .navbar-form .form-control::-webkit-input-placeholder {
 color: #e9eced;
}
.ie9 .bg-gray .navbar-form .form-control {
	border-bottom: 1px solid rgba(233, 236, 237, 0.15);
}
.bg-gray .navbar-form .form-control-feedback, .bg-gray .navbar-form .input-group-addon {
	color: #e9eced;
}
.bg-gray .text-muted {
	color: #e9eced;
}
.bg-gray .text-loud {
	color: #ffffff;
}
.bg-gray small {
	color: inherit;
}
@media print {
.bg-gray {
	color: #000 !important;
}
}
.bg-gray .help-block {
	color: #e9eced;
}
.bg-gray .flot-tick-label {
	color: #ffffff;
}
.bg-gray hr {
	border-top-color: rgba(233, 236, 237, 0.26);
}
.bg-gray-darker {
	background-color: #2b3d51 !important;
	color: #92aac5;
}
.bg-gray-darker a {
	color: #889aad;
}
.bg-gray-darker a:focus, .bg-gray-darker a:hover {
	color: #b0c2d5;
}
.bg-gray-darker .nav > li > a {
	color: #b0c2d5;
}
.bg-gray-darker .nav > li.active {
	background-color: #243344 !important;
}
.bg-gray-darker .nav > li:hover > a, .bg-gray-darker .nav > li.active > a {
	color: #c1cfde;
}
.bg-gray-darker .nav > li:hover > a, .bg-gray-darker .nav > li.active > a {
	background-color: #1d2936;
}
.bg-gray-darker .sidebar-subnav {
	background-color: #2b3d51;
}
.bg-gray-darker .sidebar-subnav > li.active > a {
	font-weight: bold;
	background-color: transparent;
}
.bg-gray-darker .navbar-form .form-control {
	background-color: #2b3d51;
	color: #92aac5;
}
.bg-gray-darker .navbar-form .form-control::-moz-placeholder {
 color: #597ea7;
 opacity: 1;
}
.bg-gray-darker .navbar-form .form-control:-ms-input-placeholder {
 color: #597ea7;
}
.bg-gray-darker .navbar-form .form-control::-webkit-input-placeholder {
 color: #597ea7;
}
.ie9 .bg-gray-darker .navbar-form .form-control {
	border-bottom: 1px solid rgba(89, 126, 167, 0.15);
}
.bg-gray-darker .navbar-form .form-control-feedback, .bg-gray-darker .navbar-form .input-group-addon {
	color: #597ea7;
}
.bg-gray-darker .text-muted {
	color: #597ea7;
}
.bg-gray-darker .text-loud {
	color: #b0c2d5;
}
.bg-gray-darker small {
	color: inherit;
}
@media print {
.bg-gray-darker {
	color: #000 !important;
}
}
.bg-gray-darker .help-block {
	color: #597ea7;
}
.bg-gray-darker .flot-tick-label {
	color: #92aac5;
}
.bg-gray-darker hr {
	border-top-color: rgba(89, 126, 167, 0.26);
}
.bg-gray-dark {
	background-color: #515d6e !important;
	color: #c6ccd5;
}
.bg-gray-dark a {
	color: #b5bbc4;
}
.bg-gray-dark a:focus, .bg-gray-dark a:hover {
	color: #e0e3e8;
}
.bg-gray-dark .nav > li > a {
	color: #e0e3e8;
}
.bg-gray-dark .nav > li.active {
	background-color: #485362 !important;
}
.bg-gray-dark .nav > li:hover > a, .bg-gray-dark .nav > li.active > a {
	color: #eff0f3;
}
.bg-gray-dark .nav > li:hover > a, .bg-gray-dark .nav > li.active > a {
	background-color: #404957;
}
.bg-gray-dark .sidebar-subnav {
	background-color: #515d6e;
}
.bg-gray-dark .sidebar-subnav > li.active > a {
	font-weight: bold;
	background-color: transparent;
}
.bg-gray-dark .navbar-form .form-control {
	background-color: #515d6e;
	color: #c6ccd5;
}
.bg-gray-dark .navbar-form .form-control::-moz-placeholder {
 color: #949fb0;
 opacity: 1;
}
.bg-gray-dark .navbar-form .form-control:-ms-input-placeholder {
 color: #949fb0;
}
.bg-gray-dark .navbar-form .form-control::-webkit-input-placeholder {
 color: #949fb0;
}
.ie9 .bg-gray-dark .navbar-form .form-control {
	border-bottom: 1px solid rgba(148, 159, 176, 0.15);
}
.bg-gray-dark .navbar-form .form-control-feedback, .bg-gray-dark .navbar-form .input-group-addon {
	color: #949fb0;
}
.bg-gray-dark .text-muted {
	color: #949fb0;
}
.bg-gray-dark .text-loud {
	color: #e0e3e8;
}
.bg-gray-dark small {
	color: inherit;
}
@media print {
.bg-gray-dark {
	color: #000 !important;
}
}
.bg-gray-dark .help-block {
	color: #949fb0;
}
.bg-gray-dark .flot-tick-label {
	color: #c6ccd5;
}
.bg-gray-dark hr {
	border-top-color: rgba(148, 159, 176, 0.26);
}
.bg-gray-light {
	background-color: #e6e9ee !important;
	color: #585f69;
}
.adpro .bg-gray-light a {
	color: #abacae;
}
.adpro .bg-gray-light a:focus, .bg-gray-light a:hover {
	color: #919293;
}
.bg-gray-light .nav > li > a {
	color: #919293;
}
.bg-gray-light .nav > li.active {
	background-color: transparent !important;
}
.bg-gray-light .nav > li:hover > a, .bg-gray-light .nav > li.active > a {
	color: #9e9fa0;
}
.bg-gray-light .nav > li:hover > a, .bg-gray-light .nav > li.active > a {
	background-color: #ffffff;
}
.bg-gray-light .sidebar-subnav {
	background-color: #e6e9ee;
}
.bg-gray-light .sidebar-subnav > li.active > a {
	font-weight: bold;
	background-color: transparent;
}
.bg-gray-light .navbar-form .form-control {
	background-color: #e6e9ee;
	color: #585f69;
}
.bg-gray-light .navbar-form .form-control::-moz-placeholder {
 color: #a0aab2;
 opacity: 1;
}
.bg-gray-light .navbar-form .form-control:-ms-input-placeholder {
 color: #a0aab2;
}
.bg-gray-light .navbar-form .form-control::-webkit-input-placeholder {
 color: #a0aab2;
}
.ie9 .bg-gray-light .navbar-form .form-control {
	border-bottom: 1px solid rgba(160, 170, 178, 0.15);
}
.bg-gray-light .navbar-form .form-control-feedback, .bg-gray-light .navbar-form .input-group-addon {
	color: #a0aab2;
}
.bg-gray-light .text-muted {
	color: #a0aab2;
}
.bg-gray-light .text-loud {
	color: #e9eced;
}
.bg-gray-light small {
	color: inherit;
}
@media print {
.bg-gray-light {
	color: #000 !important;
}
}
.bg-gray-light .help-block {
	color: #a0aab2;
}
.bg-gray-light .flot-tick-label {
	color: #585f69;
}
.bg-gray-light hr {
	border-top-color: rgba(160, 170, 178, 0.26);
}
.bg-gray-lighter {
	background-color: #f4f5f5 !important;
	color: #585f69;
}
.bg-gray-lighter a {
	color: #afb0b0;
}
.bg-gray-lighter a:focus, .bg-gray-lighter a:hover {
	color: #919293;
}
.bg-gray-lighter .nav > li > a {
	color: #919293;
}
.bg-gray-lighter .nav > li.active {
	background-color: #fafafa !important;
}
.bg-gray-lighter .nav > li:hover > a, .bg-gray-lighter .nav > li.active > a {
	color: #9e9fa0;
}
.bg-gray-lighter .nav > li:hover > a, .bg-gray-lighter .nav > li.active > a {
	background-color: #ffffff;
}
.bg-gray-lighter .sidebar-subnav {
	background-color: #f4f5f5;
}
.bg-gray-lighter .sidebar-subnav > li.active > a {
	font-weight: bold;
	background-color: transparent;
}
.bg-gray-lighter .navbar-form .form-control {
	background-color: #f4f5f5;
	color: #585f69;
}
.bg-gray-lighter .navbar-form .form-control::-moz-placeholder {
 color: #a0aab2;
 opacity: 1;
}
.bg-gray-lighter .navbar-form .form-control:-ms-input-placeholder {
 color: #a0aab2;
}
.bg-gray-lighter .navbar-form .form-control::-webkit-input-placeholder {
 color: #a0aab2;
}
.ie9 .bg-gray-lighter .navbar-form .form-control {
	border-bottom: 1px solid rgba(160, 170, 178, 0.15);
}
.bg-gray-lighter .navbar-form .form-control-feedback, .bg-gray-lighter .navbar-form .input-group-addon {
	color: #a0aab2;
}
.bg-gray-lighter .text-muted {
	color: #a0aab2;
}
.bg-gray-lighter .text-loud {
	color: #e9eced;
}
.bg-gray-lighter small {
	color: inherit;
}
@media print {
.bg-gray-lighter {
	color: #000 !important;
}
}
.bg-gray-lighter .help-block {
	color: #a0aab2;
}
.bg-gray-lighter .flot-tick-label {
	color: #585f69;
}
.bg-gray-lighter hr {
	border-top-color: rgba(160, 170, 178, 0.26);
}
#bg-white, .bg-white {
	background-color: #ffffff !important;
	color: #585f69;
}
#bg-white a, .bg-white a {
	color: #858e9a;
}
#bg-white a:focus, .bg-white a:focus, #bg-white a:hover, .bg-white a:hover {
	color: #515d6e;
}
#bg-white .nav > li > a, .bg-white .nav > li > a {
	color: #515d6e;
}
#bg-white .nav > li.active, .bg-white .nav > li.active {
	background-color: #f8f9f9 !important;
}
#bg-white .nav > li:hover > a, .bg-white .nav > li:hover > a, #bg-white .nav > li.active > a, .bg-white .nav > li.active > a {
	color: #5c697d;
}
#bg-white .nav > li:hover > a, .bg-white .nav > li:hover > a, #bg-white .nav > li.active > a, .bg-white .nav > li.active > a {
	background-color: #f1f2f3;
}
#bg-white .sidebar-subnav, .bg-white .sidebar-subnav {
	background-color: #ffffff;
}
#bg-white .sidebar-subnav > li.active > a, .bg-white .sidebar-subnav > li.active > a {
	font-weight: bold;
	background-color: transparent;
}
#bg-white .navbar-form .form-control, .bg-white .navbar-form .form-control {
	background-color: #ffffff;
	color: #585f69;
}
#bg-white .navbar-form .form-control::-moz-placeholder, .bg-white .navbar-form .form-control::-moz-placeholder {
 color: #8394a9;
 opacity: 1;
}
#bg-white .navbar-form .form-control:-ms-input-placeholder, .bg-white .navbar-form .form-control:-ms-input-placeholder {
 color: #8394a9;
}
#bg-white .navbar-form .form-control::-webkit-input-placeholder, .bg-white .navbar-form .form-control::-webkit-input-placeholder {
 color: #8394a9;
}
.ie9 #bg-white .navbar-form .form-control, .ie9 .bg-white .navbar-form .form-control {
	border-bottom: 1px solid rgba(131, 148, 169, 0.15);
}
#bg-white .navbar-form .form-control-feedback, .bg-white .navbar-form .form-control-feedback, #bg-white .navbar-form .input-group-addon, .bg-white .navbar-form .input-group-addon {
	color: #8394a9;
}
#bg-white .text-muted, .bg-white .text-muted {
	color: #8394a9;
}
#bg-white .text-loud, .bg-white .text-loud {
	color: #d1d8df;
}
#bg-white small, .bg-white small {
	color: inherit;
}
@media print {
#bg-white,  .bg-white {
	color: #000 !important;
}
}
#bg-white .help-block, .bg-white .help-block {
	color: #8394a9;
}
#bg-white .flot-tick-label, .bg-white .flot-tick-label {
	color: #585f69;
}
#bg-white hr, .bg-white hr {
	border-top-color: rgba(131, 148, 169, 0.26);
}
.bg-inverse {
	background-color: #363f45 !important;
	color: #a1adb5;
}
.bg-inverse a {
	color: #939ca2;
}
.bg-inverse a:focus, .bg-inverse a:hover {
	color: #bbc4ca;
}
.bg-inverse .nav > li > a {
	color: #bbc4ca;
}
.bg-inverse .nav > li.active {
	background-color: #2d353a !important;
}
.bg-inverse .nav > li:hover > a, .bg-inverse .nav > li.active > a {
	color: #c9d0d5;
}
.bg-inverse .nav > li:hover > a, .bg-inverse .nav > li.active > a {
	background-color: #242a2e;
}
.bg-inverse .sidebar-subnav {
	background-color: #363f45;
}
.bg-inverse .sidebar-subnav > li.active > a {
	font-weight: bold;
	background-color: transparent;
}
.bg-inverse .navbar-form .form-control {
	background-color: #363f45;
	color: #a1adb5;
}
.bg-inverse .navbar-form .form-control::-moz-placeholder {
 color: #70838f;
 opacity: 1;
}
.bg-inverse .navbar-form .form-control:-ms-input-placeholder {
 color: #70838f;
}
.bg-inverse .navbar-form .form-control::-webkit-input-placeholder {
 color: #70838f;
}
.ie9 .bg-inverse .navbar-form .form-control {
	border-bottom: 1px solid rgba(112, 131, 143, 0.15);
}
.bg-inverse .navbar-form .form-control-feedback, .bg-inverse .navbar-form .input-group-addon {
	color: #70838f;
}
.bg-inverse .text-muted {
	color: #70838f;
}
.bg-inverse .text-loud {
	color: #bbc4ca;
}
.bg-inverse small {
	color: inherit;
}
@media print {
.bg-inverse {
	color: #000 !important;
}
}
.bg-inverse .help-block {
	color: #70838f;
}
.bg-inverse .flot-tick-label {
	color: #a1adb5;
}
.bg-inverse hr {
	border-top-color: rgba(112, 131, 143, 0.26);
}
.bg-inverse.bg-light {
	background-color: #434f56 !important;
}
.bg-inverse.bg-dark {
	background-color: #292f34 !important;
}
.bg-primary {
	background-color: #3f51b5 !important;
	color: #dce0f3;
}
.bg-primary a {
	color: #c5cae9;
}
.bg-primary a:focus, .bg-primary a:hover {
	color: #fefeff;
}
.bg-primary .nav > li > a {
	color: #fefeff;
}
.bg-primary .nav > li.active {
	background-color: #3a4aa6 !important;
}
.bg-primary .nav > li:hover > a, .bg-primary .nav > li.active > a {
	color: #ffffff;
}
.bg-primary .nav > li:hover > a, .bg-primary .nav > li.active > a {
	background-color: #344397;
}
.bg-primary .sidebar-subnav {
	background-color: #3f51b5;
}
.bg-primary .sidebar-subnav > li.active > a {
	font-weight: bold;
	background-color: transparent;
}
.bg-primary .navbar-form .form-control {
	background-color: #3f51b5;
	color: #dce0f3;
}
.bg-primary .navbar-form .form-control::-moz-placeholder {
 color: #9ca6dd;
 opacity: 1;
}
.bg-primary .navbar-form .form-control:-ms-input-placeholder {
 color: #9ca6dd;
}
.bg-primary .navbar-form .form-control::-webkit-input-placeholder {
 color: #9ca6dd;
}
.ie9 .bg-primary .navbar-form .form-control {
	border-bottom: 1px solid rgba(156, 166, 221, 0.15);
}
.bg-primary .navbar-form .form-control-feedback, .bg-primary .navbar-form .input-group-addon {
	color: #9ca6dd;
}
.bg-primary .text-muted {
	color: #9ca6dd;
}
.bg-primary .text-loud {
	color: #fefeff;
}
.bg-primary small {
	color: inherit;
}
@media print {
.bg-primary {
	color: #000 !important;
}
}
.bg-primary .help-block {
	color: #9ca6dd;
}
.bg-primary .flot-tick-label {
	color: #dce0f3;
}
.bg-primary hr {
	border-top-color: rgba(156, 166, 221, 0.26);
}
.bg-primary.bg-light {
	background-color: #5062c2 !important;
}
.bg-primary.bg-dark {
	background-color: #37479e !important;
}
.bg-secondary {
	background-color: #ccdeee!important;
}
.bg-success {
	background-color: #4caf50 !important;
	color: #e3f3e4;
}
.bg-success a {
	color: #c9e7cb;
}
.bg-success a:focus, .bg-success a:hover {
	color: #ffffff;
}
.bg-success .nav > li > a {
	color: #ffffff;
}
.bg-success .nav > li.active {
	background-color: #46a149 !important;
}
.bg-success .nav > li:hover > a, .bg-success .nav > li.active > a {
	color: #ffffff;
}
.bg-success .nav > li:hover > a, .bg-success .nav > li.active > a {
	background-color: #409343;
}
.bg-success .sidebar-subnav {
	background-color: #4caf50;
}
.bg-success .sidebar-subnav > li.active > a {
	font-weight: bold;
	background-color: transparent;
}
.bg-success .navbar-form .form-control {
	background-color: #4caf50;
	color: #e3f3e4;
}
.bg-success .navbar-form .form-control::-moz-placeholder {
 color: #a7d9a9;
 opacity: 1;
}
.bg-success .navbar-form .form-control:-ms-input-placeholder {
 color: #a7d9a9;
}
.bg-success .navbar-form .form-control::-webkit-input-placeholder {
 color: #a7d9a9;
}
.ie9 .bg-success .navbar-form .form-control {
	border-bottom: 1px solid rgba(167, 217, 169, 0.15);
}
.bg-success .navbar-form .form-control-feedback, .bg-success .navbar-form .input-group-addon {
	color: #a7d9a9;
}
.bg-success .text-muted {
	color: #a7d9a9;
}
.bg-success .text-loud {
	color: #ffffff;
}
.bg-success small {
	color: inherit;
}
@media print {
.bg-success {
	color: #000 !important;
}
}
.bg-success .help-block {
	color: #a7d9a9;
}
.bg-success .flot-tick-label {
	color: #e3f3e4;
}
.bg-success hr {
	border-top-color: rgba(167, 217, 169, 0.26);
}
.bg-success.bg-light {
	background-color: #60ba63 !important;
}
.bg-success.bg-dark {
	background-color: #439a46 !important;
}
.bg-success.bg-original {
	background-color: #dff0d8 !important;
}
.bg-info {
	background-color: #2095f2 !important;
	color: #eff7fe;
}
.bg-info a {
	color: #bcdffb;
}
.bg-info a:focus, .bg-info a:hover {
	color: #ffffff;
}
.bg-info .nav > li > a {
	color: #ffffff;
}
.bg-info .nav > li.active {
	background-color: #168be7 !important;
}
.bg-info .nav > li:hover > a, .bg-info .nav > li.active > a {
	color: #ffffff;
}
.bg-info .nav > li:hover > a, .bg-info .nav > li.active > a {
	background-color: #0d80dc;
}
.bg-info .sidebar-subnav {
	background-color: #2095f2;
}
.bg-info .sidebar-subnav > li.active > a {
	font-weight: bold;
	background-color: transparent;
}
.bg-info .navbar-form .form-control {
	background-color: #2095f2;
	color: #eff7fe;
}
.bg-info .navbar-form .form-control::-moz-placeholder {
 color: #9dd1f9;
 opacity: 1;
}
.bg-info .navbar-form .form-control:-ms-input-placeholder {
 color: #9dd1f9;
}
.bg-info .navbar-form .form-control::-webkit-input-placeholder {
 color: #9dd1f9;
}
.ie9 .bg-info .navbar-form .form-control {
	border-bottom: 1px solid rgba(157, 209, 249, 0.15);
}
.bg-info .navbar-form .form-control-feedback, .bg-info .navbar-form .input-group-addon {
	color: #9dd1f9;
}
.bg-info .text-muted {
	color: #9dd1f9;
}
.bg-info .text-loud {
	color: #ffffff;
}
.bg-info small {
	color: inherit;
}
@media print {
.bg-info {
	color: #000 !important;
}
}
.bg-info .help-block {
	color: #9dd1f9;
}
.bg-info .flot-tick-label {
	color: #eff7fe;
}
.bg-info hr {
	border-top-color: rgba(157, 209, 249, 0.26);
}
.bg-info.bg-light {
	background-color: #3da3f4 !important;
}
.bg-info.bg-dark {
	background-color: #0d86e6 !important;
}
.bg-info.bg-original {
	background-color: #d9edf7 !important;
}
.bg-warning {
	background-color: #fe9700 !important;
	color: #fff0da;
}
.bg-warning a {
	color: #ffe0b3;
}
.bg-warning a:focus, .bg-warning a:hover {
	color: #ffffff;
}
.bg-warning .nav > li > a {
	color: #ffffff;
}
.bg-warning .nav > li.active {
	background-color: #ea8b00 !important;
}
.bg-warning .nav > li:hover > a, .bg-warning .nav > li.active > a {
	color: #ffffff;
}
.bg-warning .nav > li:hover > a, .bg-warning .nav > li.active > a {
	background-color: #d57f00;
}
.bg-warning .sidebar-subnav {
	background-color: #fe9700;
}
.bg-warning .sidebar-subnav > li.active > a {
	font-weight: bold;
	background-color: transparent;
}
.bg-warning .navbar-form .form-control {
	background-color: #fe9700;
	color: #fff0da;
}
.bg-warning .navbar-form .form-control::-moz-placeholder {
 color: #ffcd84;
 opacity: 1;
}
.bg-warning .navbar-form .form-control:-ms-input-placeholder {
 color: #ffcd84;
}
.bg-warning .navbar-form .form-control::-webkit-input-placeholder {
 color: #ffcd84;
}
.ie9 .bg-warning .navbar-form .form-control {
	border-bottom: 1px solid rgba(255, 205, 132, 0.15);
}
.bg-warning .navbar-form .form-control-feedback, .bg-warning .navbar-form .input-group-addon {
	color: #ffcd84;
}
.bg-warning .text-muted {
	color: #ffcd84;
}
.bg-warning .text-loud {
	color: #ffffff;
}
.bg-warning small {
	color: inherit;
}
@media print {
.bg-warning {
	color: #000 !important;
}
}
.bg-warning .help-block {
	color: #ffcd84;
}
.bg-warning .flot-tick-label {
	color: #fff0da;
}
.bg-warning hr {
	border-top-color: rgba(255, 205, 132, 0.26);
}
.bg-warning.bg-light {
	background-color: #ffa41e !important;
}
.bg-warning.bg-dark {
	background-color: #df8500 !important;
}
.bg-warning.bg-original {
	background-color: #fcf8e3 !important;
}
.bg-danger {
	background-color: #f34235 !important;
	color: #ffffff;
}
.bg-danger a {
	color: #fbc6c2;
}
.bg-danger a:focus, .bg-danger a:hover {
	color: #ffffff;
}
.bg-danger .nav > li > a {
	color: #ffffff;
}
.bg-danger .nav > li.active {
	background-color: #f23022 !important;
}
.bg-danger .nav > li:hover > a, .bg-danger .nav > li.active > a {
	color: #ffffff;
}
.bg-danger .nav > li:hover > a, .bg-danger .nav > li.active > a {
	background-color: #f11e0e;
}
.bg-danger .sidebar-subnav {
	background-color: #f34235;
}
.bg-danger .sidebar-subnav > li.active > a {
	font-weight: bold;
	background-color: transparent;
}
.bg-danger .navbar-form .form-control {
	background-color: #f34235;
	color: #ffffff;
}
.bg-danger .navbar-form .form-control::-moz-placeholder {
 color: #fab7b2;
 opacity: 1;
}
.bg-danger .navbar-form .form-control:-ms-input-placeholder {
 color: #fab7b2;
}
.bg-danger .navbar-form .form-control::-webkit-input-placeholder {
 color: #fab7b2;
}
.ie9 .bg-danger .navbar-form .form-control {
	border-bottom: 1px solid rgba(250, 183, 178, 0.15);
}
.bg-danger .navbar-form .form-control-feedback, .bg-danger .navbar-form .input-group-addon {
	color: #fab7b2;
}
.bg-danger .text-muted {
	color: #fab7b2;
}
.bg-danger .text-loud {
	color: #ffffff;
}
.bg-danger small {
	color: inherit;
}
@media print {
.bg-danger {
	color: #000 !important;
}
}
.bg-danger .help-block {
	color: #fab7b2;
}
.bg-danger .flot-tick-label {
	color: #ffffff;
}
.bg-danger hr {
	border-top-color: rgba(250, 183, 178, 0.26);
}
.bg-danger.bg-light {
	background-color: #f55d52 !important;
}
.bg-danger.bg-dark {
	background-color: #f12718 !important;
}
.bg-amber {
	background-color: #ffc107 !important;
	color: #fff8e2;
}
.bg-amber a {
	color: #ffecb5;
}
.bg-amber a:focus, .bg-amber a:hover {
	color: #ffffff;
}
.bg-amber .nav > li > a {
	color: #ffffff;
}
.bg-amber .nav > li.active {
	background-color: #eeb304 !important;
}
.bg-amber .nav > li:hover > a, .bg-amber .nav > li.active > a {
	color: #ffffff;
}
.bg-amber .nav > li:hover > a, .bg-amber .nav > li.active > a {
	background-color: #dda600;
}
.bg-amber .sidebar-subnav {
	background-color: #ffc107;
}
.bg-amber .sidebar-subnav > li.active > a {
	font-weight: bold;
	background-color: transparent;
}
.bg-amber .navbar-form .form-control {
	background-color: #ffc107;
	color: #fff8e2;
}
.bg-amber .navbar-form .form-control::-moz-placeholder {
 color: #ffe28c;
 opacity: 1;
}
.bg-amber .navbar-form .form-control:-ms-input-placeholder {
 color: #ffe28c;
}
.bg-amber .navbar-form .form-control::-webkit-input-placeholder {
 color: #ffe28c;
}
.ie9 .bg-amber .navbar-form .form-control {
	border-bottom: 1px solid rgba(255, 226, 140, 0.15);
}
.bg-amber .navbar-form .form-control-feedback, .bg-amber .navbar-form .input-group-addon {
	color: #ffe28c;
}
.bg-amber .text-muted {
	color: #ffe28c;
}
.bg-amber .text-loud {
	color: #ffffff;
}
.bg-amber small {
	color: inherit;
}
@media print {
.bg-amber {
	color: #000 !important;
}
}
.bg-amber .help-block {
	color: #ffe28c;
}
.bg-amber .flot-tick-label {
	color: #fff8e2;
}
.bg-amber hr {
	border-top-color: rgba(255, 226, 140, 0.26);
}
.bg-amber.bg-light {
	background-color: #ffc926 !important;
}
.bg-amber.bg-dark {
	background-color: #e7ae00 !important;
}
.bg-pink {
	background-color: #e91e63 !important;
	color: #fde6ee;
}
.bg-pink a {
	color: #f8bcd0;
}
.bg-pink a:focus, .bg-pink a:hover {
	color: #ffffff;
}
.bg-pink .nav > li > a {
	color: #ffffff;
}
.bg-pink .nav > li.active {
	background-color: #da195a !important;
}
.bg-pink .nav > li:hover > a, .bg-pink .nav > li.active > a {
	color: #ffffff;
}
.bg-pink .nav > li:hover > a, .bg-pink .nav > li.active > a {
	background-color: #ca1452;
}
.bg-pink .sidebar-subnav {
	background-color: #e91e63;
}
.bg-pink .sidebar-subnav > li.active > a {
	font-weight: bold;
	background-color: transparent;
}
.bg-pink .navbar-form .form-control {
	background-color: #e91e63;
	color: #fde6ee;
}
.bg-pink .navbar-form .form-control::-moz-placeholder {
 color: #f597b7;
 opacity: 1;
}
.bg-pink .navbar-form .form-control:-ms-input-placeholder {
 color: #f597b7;
}
.bg-pink .navbar-form .form-control::-webkit-input-placeholder {
 color: #f597b7;
}
.ie9 .bg-pink .navbar-form .form-control {
	border-bottom: 1px solid rgba(245, 151, 183, 0.15);
}
.bg-pink .navbar-form .form-control-feedback, .bg-pink .navbar-form .input-group-addon {
	color: #f597b7;
}
.bg-pink .text-muted {
	color: #f597b7;
}
.bg-pink .text-loud {
	color: #ffffff;
}
.bg-pink small {
	color: inherit;
}
@media print {
.bg-pink {
	color: #000 !important;
}
}
.bg-pink .help-block {
	color: #f597b7;
}
.bg-pink .flot-tick-label {
	color: #fde6ee;
}
.bg-pink hr {
	border-top-color: rgba(245, 151, 183, 0.26);
}
.bg-pink.bg-light {
	background-color: #ec3a76 !important;
}
.bg-pink.bg-dark {
	background-color: #d41556 !important;
}
.bg-purple {
	background-color: #6639b6 !important;
	color: #e1d8f3;
}
.bg-purple a {
	color: #cfc1e8;
}
.bg-purple a:focus, .bg-purple a:hover {
	color: #fcfbfe;
}
.bg-purple .nav > li > a {
	color: #fcfbfe;
}
.bg-purple .nav > li.active {
	background-color: #5d34a6 !important;
}
.bg-purple .nav > li:hover > a, .bg-purple .nav > li.active > a {
	color: #ffffff;
}
.bg-purple .nav > li:hover > a, .bg-purple .nav > li.active > a {
	background-color: #552f97;
}
.bg-purple .sidebar-subnav {
	background-color: #6639b6;
}
.bg-purple .sidebar-subnav > li.active > a {
	font-weight: bold;
	background-color: transparent;
}
.bg-purple .navbar-form .form-control {
	background-color: #6639b6;
	color: #e1d8f3;
}
.bg-purple .navbar-form .form-control::-moz-placeholder {
 color: #b096de;
 opacity: 1;
}
.bg-purple .navbar-form .form-control:-ms-input-placeholder {
 color: #b096de;
}
.bg-purple .navbar-form .form-control::-webkit-input-placeholder {
 color: #b096de;
}
.ie9 .bg-purple .navbar-form .form-control {
	border-bottom: 1px solid rgba(176, 150, 222, 0.15);
}
.bg-purple .navbar-form .form-control-feedback, .bg-purple .navbar-form .input-group-addon {
	color: #b096de;
}
.bg-purple .text-muted {
	color: #b096de;
}
.bg-purple .text-loud {
	color: #fcfbfe;
}
.bg-purple small {
	color: inherit;
}
@media print {
.bg-purple {
	color: #000 !important;
}
}
.bg-purple .help-block {
	color: #b096de;
}
.bg-purple .flot-tick-label {
	color: #e1d8f3;
}
.bg-purple hr {
	border-top-color: rgba(176, 150, 222, 0.26);
}
.bg-purple.bg-light {
	background-color: #7548c6 !important;
}
.bg-purple.bg-dark {
	background-color: #59329f !important;
}
.bg-orange {
	background-color: #fe5621 !important;
	color: #fffcfb;
}
.bg-orange a {
	color: #ffccbc;
}
.bg-orange a:focus, .bg-orange a:hover {
	color: #ffffff;
}
.bg-orange .nav > li > a {
	color: #ffffff;
}
.bg-orange .nav > li.active {
	background-color: #fa4911 !important;
}
.bg-orange .nav > li:hover > a, .bg-orange .nav > li.active > a {
	color: #ffffff;
}
.bg-orange .nav > li:hover > a, .bg-orange .nav > li.active > a {
	background-color: #f53c01;
}
.bg-orange .sidebar-subnav {
	background-color: #fe5621;
}
.bg-orange .sidebar-subnav > li.active > a {
	font-weight: bold;
	background-color: transparent;
}
.bg-orange .navbar-form .form-control {
	background-color: #fe5621;
	color: #fffcfb;
}
.bg-orange .navbar-form .form-control::-moz-placeholder {
 color: #ffbaa5;
 opacity: 1;
}
.bg-orange .navbar-form .form-control:-ms-input-placeholder {
 color: #ffbaa5;
}
.bg-orange .navbar-form .form-control::-webkit-input-placeholder {
 color: #ffbaa5;
}
.ie9 .bg-orange .navbar-form .form-control {
	border-bottom: 1px solid rgba(255, 186, 165, 0.15);
}
.bg-orange .navbar-form .form-control-feedback, .bg-orange .navbar-form .input-group-addon {
	color: #ffbaa5;
}
.bg-orange .text-muted {
	color: #ffbaa5;
}
.bg-orange .text-loud {
	color: #ffffff;
}
.bg-orange small {
	color: inherit;
}
@media print {
.bg-orange {
	color: #000 !important;
}
}
.bg-orange .help-block {
	color: #ffbaa5;
}
.bg-orange .flot-tick-label {
	color: #fffcfb;
}
.bg-orange hr {
	border-top-color: rgba(255, 186, 165, 0.26);
}
.bg-orange.bg-light {
	background-color: #fe6d3f !important;
}
.bg-orange.bg-dark {
	background-color: #fe3f03 !important;
}
.bg-transparent {
	background-color: transparent !important;
}
.bg-pic1 {
	background-image: url('../img/bg1.jpg');
}
.bg-pic2 {
	background-image: url('../img/bg2.jpg');
}
.bg-pic3 {
	background-image: url('../img/bg3.jpg');
}
.bg-pic4 {
	background-image: url('../img/bg4.jpg');
}
.bg-pic5 {
	background-image: url('../img/bg5.jpg');
}
.bg-pic6 {
	background-image: url('../img/bg6.jpg');
}
/**
Component: utils.less
*/

.m0 {
	margin: 0 !important;
}
.ml0 {
	margin-left: 0 !important;
}
.mr0 {
	margin-right: 0 !important;
}
.mt0 {
	margin-top: 0 !important;
}
.mb0 {
	margin-bottom: 0 !important;
}
.m {
	margin: 10px !important;
}
.ml, .mh {
	margin-left: 10px !important;
}
.mr, .mh {
	margin-right: 10px !important;
}
.mt, .mv {
	margin-top: 10px !important;
}
.mb, .mv {
	margin-bottom: 10px !important;
}
.m-sm {
	margin: 5px !important;
}
.ml-sm, .mh-sm {
	margin-left: 5px !important;
}
.mr-sm, .mh-sm {
	margin-right: 5px !important;
}
.mt-sm, .mv-sm {
	margin-top: 5px !important;
}
.mt-xs, .mv-xs {
	margin-top: 3px !important;
}
.mb-sm, .mv-sm {
	margin-bottom: 5px !important;
}
.m-lg {
	margin: 15px !important;
}
.ml-lg, .mh-lg {
	margin-left: 15px !important;
}
.mr-lg, .mh-lg {
	margin-right: 15px !important;
}
.mt-lg, .mv-lg {
	margin-top: 15px !important;
}
.mb-lg, .mv-lg {
	margin-bottom: 15px !important;
}
.m-xl {
	margin: 30px !important;
}
.ml-xl {
	margin-left: 30px !important;
}
.mr-xl {
	margin-right: 30px !important;
}
.mt-xl {
	margin-top: 30px !important;
}
.mb-xl {
	margin-bottom: 30px !important;
}
.mb-xl {
	margin-bottom: 30px !important;
}
.mb-35 {
	margin-bottom: 35px !important;
}
.p0 {
	padding: 0 !important;
}
.pl0, .ph0 {
	padding-left: 0 !important;
}
.pr0, .ph0 {
	padding-right: 0 !important;
}
.pt0, .pv0 {
	padding-top: 0 !important;
}
.pb0, .pv0 {
	padding-bottom: 0 !important;
}
.p {
	padding: 10px !important;
}
.pl, .ph {
	padding-left: 10px !important;
}
.pr, .ph {
	padding-right: 10px !important;
}
.pt, .pv {
	padding-top: 10px !important;
}
.pb, .pv {
	padding-bottom: 10px !important;
}
.p-sm {
	padding: 5px !important;
}
.pl-sm, .ph-sm {
	padding-left: 5px !important;
}
.pr-sm, .ph-sm {
	padding-right: 5px !important;
}
.pt-sm, .pv-sm {
	padding-top: 5px !important;
}
.pb-sm, .pv-sm {
	padding-bottom: 5px !important;
}
.p-lg {
	padding: 15px !important;
}
.pl-lg, .ph-lg {
	padding-left: 15px !important;
}
.pr-lg, .ph-lg {
	padding-right: 15px !important;
}
.pt-lg, .pv-lg {
	padding-top: 15px !important;
}
.pb-lg, .pv-lg {
	padding-bottom: 15px !important;
}
.p-xl {
	padding: 20px !important;
}
.pl-xl, .ph-xl {
	padding-left: 20px !important;
}
.pr-xl, .ph-xl {
	padding-right: 20px !important;
}
.pt-xl, .pv-xl {
	padding-top: 20px !important;
}
.pb-xl, .pv-xl {
	padding-bottom: 20px !important;
}
.p-xxl {
	padding: 25px !important;
}
.pl-xxl, .ph-xxl {
	padding-left: 25px !important;
}
.pr-xxl, .ph-xxl {
	padding-right: 25px !important;
}
.pt-xxl, .pv-xxl {
	padding-top: 25px !important;
}
.pb-xxl, .pv-xxl {
	padding-bottom: 25px !important;
}
/* Small Margin - Top */
.mt-1 {
	margin-top: 1px !important;
}
.mt-2 {
	margin-top: 2px !important;
}
.mt-3 {
	margin-top: 3px !important;
}
.mt-4 {
	margin-top: 4px !important;
}
/* Small Margin - Left */
.ml-2 {
	margin-left: 2px !important;
}
.ml-3 {
	margin-left: 3px !important;
}
.ml-4 {
	margin-left: 4px !important;
}
/* Small Margin - Right */
.mr-2 {
	margin-right: 2px !important;
}
.mr-3 {
	margin-right: 3px !important;
}
.mr-4 {
	margin-right: 4px !important;
}
/* Small Margin - Bottom */
.mb-2 {
	margin-bottom: 2px !important;
}
.mb-3 {
	margin-bottom: 3px !important;
}
.mb-4 {
	margin-bottom: 4px !important;
}
/* Border Classes */
.td-border-light>tbody>tr>td,
.td-border-light>tfoot>tr>td,
.td-border-light>thead>tr>td {
	border: 1px solid #e7e6e6 !important;
}

.no-radius {
	border-radius: 0 !important;
}
.b0 {
	border-width: 0 !important;
}
.bl0 {
	border-left-width: 0 !important;
}
.br0 {
	border-right-width: 0 !important;
}
.bt0 {
	border-top-width: 0 !important;
}
.bb0 {
	border-bottom-width: 0 !important;
}
.br, .b {
	border-right: 1px solid #eceeee;
}
.bl, .b {
	border-left: 1px solid #eceeee;
}
.bt, .b {
	border-top: 1px solid #eceeee;
}
.bb, .b {
	border-bottom: 1px solid #eceeee;
}
.br-primary {
	border-right: 1px solid #3f51b5;
}
.bl-primary {
	border-left: 1px solid #3f51b5;
}
.bt-primary {
	border-top: 1px solid #3f51b5;
}
.bb-primary {
	border-bottom: 1px solid #3f51b5;
}
.br-success {
	border-right: 1px solid #4caf50;
}
.bl-success {
	border-left: 1px solid #4caf50;
}
.bt-success {
	border-top: 1px solid #4caf50;
}
.bb-success {
	border-bottom: 1px solid #4caf50;
}
.br-info {
	border-right: 1px solid #2095f2;
}
.bl-info {
	border-left: 1px solid #2095f2;
}
.bt-info {
	border-top: 1px solid #2095f2;
}
.bb-info {
	border-bottom: 1px solid #2095f2;
}
.br-warning {
	border-right: 1px solid #fe9700;
}
.bl-warning {
	border-left: 1px solid #fe9700;
}
.bt-warning {
	border-top: 1px solid #fe9700;
}
.bb-warning {
	border-bottom: 1px solid #fe9700;
}
.br-danger {
	border-right: 1px solid #f34235;
}
.bl-danger {
	border-left: 1px solid #f34235;
}
.bt-danger {
	border-top: 1px solid #f34235;
}
.bb-danger {
	border-bottom: 1px solid #f34235;
}
.br-amber {
	border-right: 1px solid #ffc107;
}
.bl-amber {
	border-left: 1px solid #ffc107;
}
.bt-amber {
	border-top: 1px solid #ffc107;
}
.bb-amber {
	border-bottom: 1px solid #ffc107;
}
.br-pink {
	border-right: 1px solid #e91e63;
}
.bl-pink {
	border-left: 1px solid #e91e63;
}
.bt-pink {
	border-top: 1px solid #e91e63;
}
.bb-pink {
	border-bottom: 1px solid #e91e63;
}
.br-purple {
	border-right: 1px solid #6639b6;
}
.bl-purple {
	border-left: 1px solid #6639b6;
}
.bt-purple {
	border-top: 1px solid #6639b6;
}
.bb-purple {
	border-bottom: 1px solid #6639b6;
}
.br-inverse {
	border-right: 1px solid #363f45;
}
.bl-inverse {
	border-left: 1px solid #363f45;
}
.bt-inverse {
	border-top: 1px solid #363f45;
}
.bb-inverse {
	border-bottom: 1px solid #363f45;
}
.br-orange {
	border-right: 1px solid #fe5621;
}
.bl-orange {
	border-left: 1px solid #fe5621;
}
.bt-orange {
	border-top: 1px solid #fe5621;
}
.bb-orange {
	border-bottom: 1px solid #fe5621;
}
.br-gray-darker {
	border-right: 1px solid #2b3d51;
}
.bl-gray-darker {
	border-left: 1px solid #2b3d51;
}
.bt-gray-darker {
	border-top: 1px solid #2b3d51;
}
.bb-gray-darker {
	border-bottom: 1px solid #2b3d51;
}
.br-gray-dark {
	border-right: 1px solid #515d6e;
}
.bl-gray-dark {
	border-left: 1px solid #515d6e;
}
.bt-gray-dark {
	border-top: 1px solid #515d6e;
}
.bb-gray-dark {
	border-bottom: 1px solid #515d6e;
}
.br-gray {
	border-right: 1px solid #a0aab2;
}
.bl-gray {
	border-left: 1px solid #a0aab2;
}
.bt-gray {
	border-top: 1px solid #a0aab2;
}
.bb-gray {
	border-bottom: 1px solid #a0aab2;
}
.br-gray-light {
	border-right: 1px solid #e6e9ee;
}
.bl-gray-light {
	border-left: 1px solid #e6e9ee;
}
.bt-gray-light {
	border-top: 1px solid #e6e9ee;
}
.bb-gray-light {
	border-bottom: 1px solid #e6e9ee;
}
.br-gray-lighter {
	border-right: 1px solid #f4f5f5;
}
.bl-gray-lighter {
	border-left: 1px solid #f4f5f5;
}
.bt-gray-lighter {
	border-top: 1px solid #f4f5f5;
}
.bb-gray-lighter {
	border-bottom: 1px solid #f4f5f5;
}
.br-muted {
	border-right: 1px solid #8394a9;
}
.bl-muted {
	border-left: 1px solid #8394a9;
}
.bt-muted {
	border-top: 1px solid #8394a9;
}
.bb-muted {
	border-bottom: 1px solid #8394a9;
}
@media only screen and (max-width: 479px) {
.b0-sm {
	border-width: 0 !important;
}
.bl0-sm {
	border-left-width: 0 !important;
}
.br0-sm {
	border-right-width: 0 !important;
}
.bt0-sm {
	border-top-width: 0 !important;
}
.bb0-sm {
	border-bottom-width: 0 !important;
}
.br-sm {
	border-right: 1px solid #eceeee;
}
.bl-sm {
	border-left: 1px solid #eceeee;
}
.bt-sm {
	border-top: 1px solid #eceeee;
}
.bb-sm {
	border-bottom: 1px solid #eceeee;
}
}
@media only screen and (max-width: 767px) {
.b0-md {
	border-width: 0 !important;
}
.bl0-md {
	border-left-width: 0 !important;
}
.br0-md {
	border-right-width: 0 !important;
}
.bt0-md {
	border-top-width: 0 !important;
}
.bb0-md {
	border-bottom-width: 0 !important;
}
.br-md {
	border-right: 1px solid #eceeee;
}
.bl-md {
	border-left: 1px solid #eceeee;
}
.bt-md {
	border-top: 1px solid #eceeee;
}
.bb-md {
	border-bottom: 1px solid #eceeee;
}
}
@media only screen and (max-width: 991px) {
.b0-lg {
	border-width: 0 !important;
}
.bl0-lg {
	border-left-width: 0 !important;
}
.br0-lg {
	border-right-width: 0 !important;
}
.bt0-lg {
	border-top-width: 0 !important;
}
.bb0-lg {
	border-bottom-width: 0 !important;
}
.br-lg {
	border-right: 1px solid #eceeee;
}
.bl-lg {
	border-left: 1px solid #eceeee;
}
.bt-lg {
	border-top: 1px solid #eceeee;
}
.bb-lg {
	border-bottom: 1px solid #eceeee;
}
}
.radius-clear {
	border-radius: 0 !important;
}
.circle {
	border-radius: 50% !important;
}
.rounded {
	border-radius: 3px !important;
}
.shadow-clear, .no-shadow {
	box-shadow: 0 0 0 #000 !important;
}
.text-primary {
	color: #3f51b5;
}
a.text-primary {
	color: #3f51b5;
}
a.text-primary:hover, a.text-primary:focus {
	color: #606fc7;
	color: rgba(63, 81, 181, 0.7);
}
.text-success {
	color: #4caf50;
}
a.text-success {
	color: #4caf50;
}
a.text-success:hover, a.text-success:focus {
	color: #6ec071;
	color: rgba(76, 175, 80, 0.7);
}
.text-warning {
	color: #fe9700;
}
a.text-warning {
	color: #fe9700;
}
a.text-warning:hover, a.text-warning:focus {
	color: #ffac32;
	color: rgba(254, 151, 0, 0.7);
}
.text-danger {
	color: #f34235;
}
a.text-danger {
	color: #f34235;
}
a.text-danger:hover, a.text-danger:focus {
	color: #f66f65;
	color: rgba(243, 66, 53, 0.7);
}
.text-info {
	color: #2095f2;
}
a.text-info {
	color: #2095f2;
}
a.text-info:hover, a.text-info:focus {
	color: #50acf5;
	color: rgba(32, 149, 242, 0.7);
}
.text-white {
	color: #ffffff;
}
a.text-white {
	color: #ffffff;
}
a.text-white:hover, a.text-white:focus {
	color: #ffffff;
	color: rgba(255, 255, 255, 0.7);
}
.text-inverse {
	color: #363f45;
}
a.text-inverse {
	color: #363f45;
}
a.text-inverse:hover, a.text-inverse:focus {
	color: #4c5962;
	color: rgba(54, 63, 69, 0.7);
}
.text-alpha {
	color: rgba(255, 255, 255, 0.5);
}
a.text-alpha {
	color: rgba(255, 255, 255, 0.5);
}
a.text-alpha:hover, a.text-alpha:focus {
	color: rgba(255, 255, 255, 0.5);
	color: rgba(255, 255, 255, 0.7);
}
.text-pink {
	color: #e91e63;
}
a.text-pink {
	color: #e91e63;
}
a.text-pink:hover, a.text-pink:focus {
	color: #ee4c83;
	color: rgba(233, 30, 99, 0.7);
}
.text-purple {
	color: #6639b6;
}
a.text-purple {
	color: #6639b6;
}
a.text-purple:hover, a.text-purple:focus {
	color: #8157cb;
	color: rgba(102, 57, 182, 0.7);
}
.text-alpha-inverse {
	color: rgba(0, 0, 0, 0.5);
}
a.text-alpha-inverse {
	color: rgba(0, 0, 0, 0.5);
}
a.text-alpha-inverse:hover, a.text-alpha-inverse:focus {
	color: rgba(26, 26, 26, 0.5);
	color: rgba(0, 0, 0, 0.7);
}
.text-amber {
	color: #ffc107;
}
a.text-amber {
	color: #ffc107;
}
a.text-amber:hover, a.text-amber:focus {
	color: #ffce3a;
	color: rgba(255, 193, 7, 0.7);
}
.text-orange {
	color: #fe5621;
}
a.text-orange {
	color: #fe5621;
}
a.text-orange:hover, a.text-orange:focus {
	color: #fe7d54;
	color: rgba(254, 86, 33, 0.7);
}
.text-gray-darker {
	color: #2b3d51;
}
a.text-gray-darker {
	color: #2b3d51;
}
a.text-gray-darker:hover, a.text-gray-darker:focus {
	color: #3d5672;
	color: rgba(43, 61, 81, 0.7);
}
.text-gray-dark {
	color: #515d6e;
}
a.text-gray-dark {
	color: #515d6e;
}
a.text-gray-dark:hover, a.text-gray-dark:focus {
	color: #67768b;
	color: rgba(81, 93, 110, 0.7);
}
.text-gray {
	color: #a0aab2;
}
a.text-gray {
	color: #a0aab2;
}
a.text-gray:hover, a.text-gray:focus {
	color: #bcc3c9;
	color: rgba(160, 170, 178, 0.7);
}
.text-gray-light {
	color: #e6e9ee;
}
a.text-gray-light {
	color: #e6e9ee;
}
a.text-gray-light:hover, a.text-gray-light:focus {
	color: #ffffff;
	color: rgba(230, 233, 238, 0.7);
}
.text-gray-lighter {
	color: #f4f5f5;
}
a.text-gray-lighter {
	color: #f4f5f5;
}
a.text-gray-lighter:hover, a.text-gray-lighter:focus {
	color: #ffffff;
	color: rgba(244, 245, 245, 0.7);
}
.text-xs {
	font-size: 8px !important;
}
.text-sm {
	font-size: 11px !important;
}
.text-md-sm {
    font-size: 13px !important;
}
.text-md {
	font-size: 16px !important;
}
.text-md-md {
	font-size: 21px !important;
}
.text-md-lg {
	font-size: 25px !important;
}
.text-lg {
	font-size: 39px !important;
}
.text-hg {
	font-size: 52px !important;
}
.text-left {
	text-align: left !important;
}
.text-right {
	text-align: right !important;
}
.text-center {
	text-align: center !important;
}
.text-justify {
	text-align: justify !important;
}
.text-nowrap {
	white-space: nowrap !important;
}
.text-nowrap {
	white-space: nowrap;
}
.text-thin {
	font-weight: 100 !important;
}
.text-normal {
	font-weight: normal !important;
}
.text-bold {
	font-weight: bold !important;
}
.text-ellipsis, .text-oneline {
	text-overflow: ellipsis;
	white-space: nowrap;
	padding: 0 .2em;
	overflow: hidden;
	vertical-align: bottom;
}
.text-link {
	color: #585f69;
}
.text-link:hover, .text-link:focus {
	color: #3f51b5;
}
.show-behind {
	position: relative;
	z-index: -1;
}
.no-scroll {
	/*overflow: hidden !important;*/
}
.inline {
	display: inline !important;
}
.inline-block {
	display: inline-block !important;
}
.block {
	display: block !important;
}
.thumb8 {
	width: 8px !important;
	height: 8px !important;
	line-height: 8px !important;
}
.thumb16 {
	width: 16px !important;
	height: 16px !important;
	line-height: 16px !important;
}
.thumb24 {
	width: 24px !important;
	height: 24px !important;
	line-height: 24px !important;
}
.thumb32 {
	width: 32px !important;
	height: 32px !important;
	line-height: 32px !important;
}
.thumb40 {
	width: 40px !important;
	height: 40px !important;
	line-height: 40px !important;
}
.thumb48 {
	width: 48px !important;
	height: 48px !important;
	line-height: 48px !important;
}
.thumb64 {
	width: 64px !important;
	height: 64px !important;
	line-height: 64px !important;
}
.thumb80 {
	width: 80px !important;
	height: 80px !important;
	line-height: 80px !important;
}
.thumb96 {
	width: 96px !important;
	height: 96px !important;
	line-height: 96px !important;
}
.thumb128 {
	width: 128px !important;
	height: 128px !important;
	line-height: 128px !important;
}
.align-middle {
	vertical-align: middle;
}
.align-top {
	vertical-align: top;
}
.align-bottom {
	vertical-align: bottom;
}
.bg-center {
	background-position: center center;
	background-size: cover;
}
.list-icon em {
	font-size: 14px;
	width: 40px;
	margin: 0;
	display: inline-block;
	text-align: center;
	-webkit-transition: all 0.2s;
	-o-transition: all 0.2s;
	transition: all 0.2s;
	line-height: 30px;
}
.list-icon div:hover {
	background-color: #2095f2;
	color: #fff;
}
.list-table {
	padding-left: 0;
	list-style: none;
	display: table;
	table-layout: fixed;
	width: 100%;
	margin: 0;
}
.list-table > li {
	display: table-cell;
	table-layout: fixed;
	vertical-align: middle;
}
.list-block {
	padding-left: 0;
	list-style: none;
}
.list-block > li {
	display: block;
}
.wd-tiny {
	width: 50px;
}
.wd-xxs {
	width: 60px;
}
.wd-xs {
	width: 90px;
}
.wd-sm {
	width: 150px;
}
.wd-sd {
	width: 200px;
}
.wd-md {
	width: 240px;
}
.wd-lg {
	width: 280px;
}
.wd-xl {
	width: 320px;
}
.wd-xxl {
	width: 360px;
}
.wd-wide {
	width: 100%;
}
.wd-auto {
	width: auto;
}
.label-md {
	font-size: 85% !important;
}
.label-lg {
	font-size: 95% !important;
}
.label-inverse {
	background-color: #363f45;
}
.label-inverse[href]:hover, .label-inverse[href]:focus {
	background-color: #202528;
}
.label-amber {
	background-color: #ffc107;
}
.label-amber[href]:hover, .label-amber[href]:focus {
	background-color: #d39e00;
}
.label-pink {
	background-color: #e91e63;
}
.label-pink[href]:hover, .label-pink[href]:focus {
	background-color: #c1134e;
}
.label-purple {
	background-color: #6639b6;
}
.label-purple[href]:hover, .label-purple[href]:focus {
	background-color: #502d8f;
}
.label-orange {
	background-color: #fe5621;
}
.label-orange[href]:hover, .label-orange[href]:focus {
	background-color: #eb3901;
}
.alert-purple {
	background-color: #6639b6;
	border-color: #6639b6;
	color: #ffffff;
}
.alert-purple hr {
	border-top-color: #5b33a3;
}
.alert-purple .alert-link {
	color: #e6e6e6;
}
.alert-amber {
	background-color: #ffc107;
	border-color: #ffc107;
	color: #ffffff;
}
.alert-amber hr {
	border-top-color: #ecb100;
}
.alert-amber .alert-link {
	color: #e6e6e6;
}
.alert-pink {
	background-color: #e91e63;
	border-color: #e91e63;
	color: #ffffff;
}
.alert-pink hr {
	border-top-color: #d81557;
}
.alert-pink .alert-link {
	color: #e6e6e6;
}
.alert-inverse {
	background-color: #363f45;
	border-color: #363f45;
	color: #ffffff;
}
.alert-inverse hr {
	border-top-color: #2b3237;
}
.alert-inverse .alert-link {
	color: #e6e6e6;
}
.alert-orange {
	background-color: #fe5621;
	border-color: #fe5621;
	color: #ffffff;
}
.alert-orange hr {
	border-top-color: #fe4308;
}
.alert-orange .alert-link {
	color: #e6e6e6;
}
.abs-center-container {
	position: relative;
}
.abs-center {
	height: 50%;
	overflow: auto;
	margin: auto;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}
.abs-center.abs-fixed {
	position: fixed;
	z-index: 999;
}
.abs-center.abs-right {
	left: auto;
	right: 20px;
	text-align: right;
}
.abs-center.abs-left {
	right: auto;
	left: 20px;
	text-align: left;
}
.clickable {
	cursor: pointer;
}
.media-block:before, .media-block:after {
	content: " ";
	display: table;
}
.media-block:after {
	clear: both;
}
.media-block .media-body {
	display: block;
}
.shadow-z1 {
	box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 6px rgba(0, 0, 0, 0.12) !important;
}
.shadow-z2 {
	box-shadow: 0 3px 10px rgba(0, 0, 0, 0.23), 0 3px 10px rgba(0, 0, 0, 0.16) !important;
}
.shadow-z2-hover {
	box-shadow: 0 6px 10px rgba(0, 0, 0, 0.23), 0 10px 30px rgba(0, 0, 0, 0.19) !important;
}
.shadow-z3 {
	box-shadow: 0 6px 10px rgba(0, 0, 0, 0.23), 0 10px 30px rgba(0, 0, 0, 0.19) !important;
}
.shadow-z4 {
	box-shadow: 0 10px 18px rgba(0, 0, 0, 0.22), 0 14px 45px rgba(0, 0, 0, 0.25) !important;
}
.shadow-z5 {
	box-shadow: 0 15px 20px rgba(0, 0, 0, 0.22), 0 19px 60px rgba(0, 0, 0, 0.3) !important;
}
/**
Component: Custom Utility css
*/
/*Alert*/

.alert-success {
	background-color: #4caf50;
	border-color: rgba(0, 0, 0, 0.1);
	color: #ffffff;
}
.alert-success hr {
	border-top-color: rgba(0, 0, 0, 0.1);
}
.alert-success .alert-link {
	color: #e6e6e6;
}
.alert-info {
	background-color: #2095f2;
	border-color: rgba(0, 0, 0, 0.1);
	color: #ffffff;
}
.alert-info hr {
	border-top-color: rgba(0, 0, 0, 0.1);
}
.alert-info .alert-link {
	color: #e6e6e6;
}
.alert-warning {
	background-color: #fe9700;
	border-color: rgba(0, 0, 0, 0.1);
	color: #ffffff;
}
.alert-warning hr {
	border-top-color: rgba(0, 0, 0, 0.1);
}
.alert-warning .alert-link {
	color: #e6e6e6;
}
.alert-danger {
	background-color: #f34235;
	border-color: rgba(0, 0, 0, 0.1);
	color: #ffffff;
}
.alert-danger hr {
	border-top-color: rgba(0, 0, 0, 0.1);
}
.alert-danger .alert-link {
	color: #e6e6e6;
}
/*Label*/

.label {
	border-radius: 0.25em;
	color: #ffffff;
	display: inline;
	font-size: 75%;
	font-weight: bold;
	line-height: 1;
	padding: 0.2em 0.6em 0.3em;
	text-align: center;
	vertical-align: baseline;
	white-space: nowrap;
}
a.label:hover, a.label:focus {
	color: #ffffff;
	cursor: pointer;
	text-decoration: none;
}
.label:empty {
	display: none;
}
.btn .label {
	position: relative;
	top: -1px;
}
.label-default {
	background-color: #a0aab2;
}
.label-default[href]:hover, .label-default[href]:focus {
	background-color: #84919b;
}
.dTable .label-default a {
	color: #fff;
}
.label-primary {
	background-color: #3f51b5;
}
.label-primary[href]:hover, .label-primary[href]:focus {
	background-color: #32408f;
}
.label-success {
	background-color: #4caf50;
}
.label-success[href]:hover, .label-success[href]:focus {
	background-color: #3d8b40;
}
.label-info {
	background-color: #2095f2;
}
.label-info[href]:hover, .label-info[href]:focus {
	background-color: #0c7bd3;
}
.label-warning {
	background-color: #fe9700;
}
.label-warning[href]:hover, .label-warning[href]:focus {
	background-color: #cb7900;
}
.label-danger {
	background-color: #f34235;
}
.label-danger[href]:hover, .label-danger[href]:focus {
	background-color: #e71d0e;
}
/*Panel*/

.panel-default {
	border-color: #e6e9ee;
}
.panel-default > .panel-heading {
	/*  background-color: #ffffff;
	border-color: #e6e9ee;*/

	color: #444444;
}
.panel-default > .panel-heading + .panel-collapse > .panel-body {
	border-top-color: #e6e9ee;
}
.panel-default > .panel-heading .badge {
	background-color: #444444;
	color: #ffffff;
}
.panel-default > .panel-footer + .panel-collapse > .panel-body {
	border-bottom-color: #e6e9ee;
}
.panel-primary {
	border-color: #e6e9ee;
}
.panel-primary > .panel-heading {
	background-color: #3f51b5;
	border-color: #e6e9ee;
	color: #ffffff;
}
.panel-primary > .panel-heading + .panel-collapse > .panel-body {
	border-top-color: #e6e9ee;
}
.panel-primary > .panel-heading .badge {
	background-color: #ffffff;
	color: #3f51b5;
}
.panel-primary > .panel-footer + .panel-collapse > .panel-body {
	border-bottom-color: #e6e9ee;
}
.panel-success {
	border-color: #e6e9ee;
}
.panel-success > .panel-heading {
	background-color: #4caf50;
	border-color: #e6e9ee;
	color: #ffffff;
}
.panel-success > .panel-heading + .panel-collapse > .panel-body {
	border-top-color: #e6e9ee;
}
.panel-success > .panel-heading .badge {
	background-color: #ffffff;
	color: #4caf50;
}
.panel-success > .panel-footer + .panel-collapse > .panel-body {
	border-bottom-color: #e6e9ee;
}
.panel-info {
	border-color: #e6e9ee;
}
.panel-info > .panel-heading {
	background-color: #2095f2;
	border-color: #e6e9ee;
	color: #ffffff;
}
.panel-info > .panel-heading + .panel-collapse > .panel-body {
	border-top-color: #e6e9ee;
}
.panel-info > .panel-heading .badge {
	background-color: #ffffff;
	color: #2095f2;
}
.panel-info > .panel-footer + .panel-collapse > .panel-body {
	border-bottom-color: #e6e9ee;
}
.panel-warning {
	border-color: #e6e9ee;
}
.panel-warning > .panel-heading {
	background-color: #fe9700;
	border-color: #e6e9ee;
	color: #ffffff;
}
.panel-warning > .panel-heading + .panel-collapse > .panel-body {
	border-top-color: #e6e9ee;
}
.panel-warning > .panel-heading .badge {
	background-color: #ffffff;
	color: #fe9700;
}
.panel-warning > .panel-footer + .panel-collapse > .panel-body {
	border-bottom-color: #e6e9ee;
}
.panel-danger {
	border-color: #e6e9ee;
}
.panel-danger > .panel-heading {
	background-color: #f34235;
	border-color: #e6e9ee;
	color: #ffffff;
}
.panel-danger > .panel-heading + .panel-collapse > .panel-body {
	border-top-color: #e6e9ee;
}
.panel-danger > .panel-heading .badge {
	background-color: #ffffff;
	color: #f34235;
}
.panel-danger > .panel-footer + .panel-collapse > .panel-body {
	border-bottom-color: #e6e9ee;
}
/*Button*/

.btn-default {
	background-color: #eee;
	border-color: #e6e9ee;
	color: #454545 !important;
}
.btn-default:focus, .btn-default.focus {
	background-color: #f5f5f5;
	border-color: #dadee6;
	color: #a0aab2;
}
.btn-default:hover {
	background-color: #f5f5f5;
	border-color: #dadee6;
	color: #a0aab2;
}
.btn-default:active, .btn-default.active, .open > .dropdown-toggle.btn-default {
	color: #a0aab2;
}
.btn-default:active:hover, .btn-default.active:hover, .open > .dropdown-toggle.btn-default:hover, .btn-default:active:focus, .btn-default.active:focus, .open > .dropdown-toggle.btn-default:focus, .btn-default.focus:active, .btn-default.active.focus, .open > .dropdown-toggle.btn-default.focus {
	color: #a0aab2;
}
.btn-default:active, .btn-default.active, .open > .dropdown-toggle.btn-default {
	background-image: none;
}
.btn-default.disabled, .btn-default[disabled], fieldset[disabled] .btn-default, .btn-default.disabled:hover, .btn-default[disabled]:hover, fieldset[disabled] .btn-default:hover, .btn-default.disabled:focus, .btn-default[disabled]:focus, fieldset[disabled] .btn-default:focus, .btn-default.disabled.focus, .btn-default.focus[disabled], fieldset[disabled] .btn-default.focus, .btn-default.disabled:active, .btn-default[disabled]:active, fieldset[disabled] .btn-default:active, .btn-default.disabled.active, .btn-default.active[disabled], fieldset[disabled] .btn-default.active {
	background-color: #ffffff;/*  border-color: #e6e9ee;*/
}
.btn-default .badge {
	background-color: #a0aab2;
	color: #ffffff;
}
.btn-primary {
	background-color: #3f51b5;
	border-color: #3e4fb1;
	color: #ffffff !important;
}
.btn-primary:focus, .btn-primary.focus {
	background-color: #3a4aa6;
	border-color: #3849a2;
	color: #ffffff;
}
.btn-primary:hover {
	background-color: #3a4aa6;
	border-color: #3849a2;
	color: #ffffff;
}
.btn-primary:active, .btn-primary.active, .open > .dropdown-toggle.btn-primary {
	color: #ffffff;
}
.btn-primary:active:hover, .btn-primary.active:hover, .open > .dropdown-toggle.btn-primary:hover, .btn-primary:active:focus, .btn-primary.active:focus, .open > .dropdown-toggle.btn-primary:focus, .btn-primary.focus:active, .btn-primary.active.focus, .open > .dropdown-toggle.btn-primary.focus {
	color: #ffffff;
}
.btn-primary:active, .btn-primary.active, .open > .dropdown-toggle.btn-primary {
	background-image: none;
}
.btn-primary.disabled, .btn-primary[disabled], fieldset[disabled] .btn-primary, .btn-primary.disabled:hover, .btn-primary[disabled]:hover, fieldset[disabled] .btn-primary:hover, .btn-primary.disabled:focus, .btn-primary[disabled]:focus, fieldset[disabled] .btn-primary:focus, .btn-primary.disabled.focus, .btn-primary.focus[disabled], fieldset[disabled] .btn-primary.focus, .btn-primary.disabled:active, .btn-primary[disabled]:active, fieldset[disabled] .btn-primary:active, .btn-primary.disabled.active, .btn-primary.active[disabled], fieldset[disabled] .btn-primary.active {
	background-color: #3f51b5;
	border-color: #3e4fb1;
}
.btn-primary .badge {
	background-color: #ffffff;
	color: #3f51b5;
}
.btn-success {
	background-color: #4caf50;
	border-color: #4aab4e;
	color: #ffffff !important;
}
.btn-success:focus, .btn-success.focus {
	background-color: #46a149;
	border-color: #449d48;
	color: #ffffff;
}
.btn-success:hover {
	background-color: #46a149;
	border-color: #449d48;
	color: #ffffff;
}
.btn-success:active, .btn-success.active, .open > .dropdown-toggle.btn-success {
	color: #ffffff;
}
.btn-success:active:hover, .btn-success.active:hover, .open > .dropdown-toggle.btn-success:hover, .btn-success:active:focus, .btn-success.active:focus, .open > .dropdown-toggle.btn-success:focus, .btn-success.focus:active, .btn-success.active.focus, .open > .dropdown-toggle.btn-success.focus {
	color: #ffffff;
}
.btn-success:active, .btn-success.active, .open > .dropdown-toggle.btn-success {
	background-image: none;
}
.btn-success.disabled, .btn-success[disabled], fieldset[disabled] .btn-success, .btn-success.disabled:hover, .btn-success[disabled]:hover, fieldset[disabled] .btn-success:hover, .btn-success.disabled:focus, .btn-success[disabled]:focus, fieldset[disabled] .btn-success:focus, .btn-success.disabled.focus, .btn-success.focus[disabled], fieldset[disabled] .btn-success.focus, .btn-success.disabled:active, .btn-success[disabled]:active, fieldset[disabled] .btn-success:active, .btn-success.disabled.active, .btn-success.active[disabled], fieldset[disabled] .btn-success.active {
	background-color: #4caf50;
	border-color: #4aab4e;
}
.btn-success .badge {
	background-color: #ffffff;
	color: #4caf50;
}
.btn-info {
	background-color: #2095f2;
	border-color: #1b93f2;
	color: #ffffff !important;
}
.btn-info:focus, .btn-info.focus {
	background-color: #0e8cf0;
	border-color: #0e89eb;
	color: #ffffff;
}
.btn-info:hover {
	background-color: #0e8cf0;
	border-color: #0e89eb;
	color: #ffffff;
}
.btn-info:active, .btn-info.active, .open > .dropdown-toggle.btn-info {
	color: #ffffff;
}
.btn-info:active:hover, .btn-info.active:hover, .open > .dropdown-toggle.btn-info:hover, .btn-info:active:focus, .btn-info.active:focus, .open > .dropdown-toggle.btn-info:focus, .btn-info.focus:active, .btn-info.active.focus, .open > .dropdown-toggle.btn-info.focus {
	color: #ffffff;
}
.btn-info:active, .btn-info.active, .open > .dropdown-toggle.btn-info {
	background-image: none;
}
.btn-info.disabled, .btn-info[disabled], fieldset[disabled] .btn-info, .btn-info.disabled:hover, .btn-info[disabled]:hover, fieldset[disabled] .btn-info:hover, .btn-info.disabled:focus, .btn-info[disabled]:focus, fieldset[disabled] .btn-info:focus, .btn-info.disabled.focus, .btn-info.focus[disabled], fieldset[disabled] .btn-info.focus, .btn-info.disabled:active, .btn-info[disabled]:active, fieldset[disabled] .btn-info:active, .btn-info.disabled.active, .btn-info.active[disabled], fieldset[disabled] .btn-info.active {
	background-color: #2095f2;
	border-color: #1b93f2;
}
.btn-info .badge {
	background-color: #ffffff;
	color: #2095f2;
}
.btn-warning {
	background-color: #fe9700;
	border-color: #f99400;
	color: #ffffff !important;
}
.btn-warning:focus, .btn-warning.focus {
	background-color: #ea8b00;
	border-color: #e58800;
	color: #ffffff;
}
.btn-warning:hover {
	background-color: #ea8b00;
	border-color: #e58800;
	color: #ffffff;
}
.btn-warning:active, .btn-warning.active, .open > .dropdown-toggle.btn-warning {
	color: #ffffff;
}
.btn-warning:active:hover, .btn-warning.active:hover, .open > .dropdown-toggle.btn-warning:hover, .btn-warning:active:focus, .btn-warning.active:focus, .open > .dropdown-toggle.btn-warning:focus, .btn-warning.focus:active, .btn-warning.active.focus, .open > .dropdown-toggle.btn-warning.focus {
	color: #ffffff;
}
.btn-warning:active, .btn-warning.active, .open > .dropdown-toggle.btn-warning {
	background-image: none;
}
.btn-warning.disabled, .btn-warning[disabled], fieldset[disabled] .btn-warning, .btn-warning.disabled:hover, .btn-warning[disabled]:hover, fieldset[disabled] .btn-warning:hover, .btn-warning.disabled:focus, .btn-warning[disabled]:focus, fieldset[disabled] .btn-warning:focus, .btn-warning.disabled.focus, .btn-warning.focus[disabled], fieldset[disabled] .btn-warning.focus, .btn-warning.disabled:active, .btn-warning[disabled]:active, fieldset[disabled] .btn-warning:active, .btn-warning.disabled.active, .btn-warning.active[disabled], fieldset[disabled] .btn-warning.active {
	background-color: #fe9700;
	border-color: #f99400;
}
.btn-warning .badge {
	background-color: #ffffff;
	color: #fe9700;
}
.btn-danger {
	background-color: #f34235;
	border-color: #f33d30;
	color: #ffffff !important;
}
.btn-danger:focus, .btn-danger.focus {
	background-color: #f23022;
	border-color: #f22b1d;
	color: #ffffff;
}
.btn-danger:hover {
	background-color: #f23022;
	border-color: #f22b1d;
	color: #ffffff;
}
.btn-danger:active, .btn-danger.active, .open > .dropdown-toggle.btn-danger {
	color: #ffffff;
}
.btn-danger:active:hover, .btn-danger.active:hover, .open > .dropdown-toggle.btn-danger:hover, .btn-danger:active:focus, .btn-danger.active:focus, .open > .dropdown-toggle.btn-danger:focus, .btn-danger.focus:active, .btn-danger.active.focus, .open > .dropdown-toggle.btn-danger.focus {
	color: #ffffff;
}
.btn-danger:active, .btn-danger.active, .open > .dropdown-toggle.btn-danger {
	background-image: none;
}
.btn-danger.disabled, .btn-danger[disabled], fieldset[disabled] .btn-danger, .btn-danger.disabled:hover, .btn-danger[disabled]:hover, fieldset[disabled] .btn-danger:hover, .btn-danger.disabled:focus, .btn-danger[disabled]:focus, fieldset[disabled] .btn-danger:focus, .btn-danger.disabled.focus, .btn-danger.focus[disabled], fieldset[disabled] .btn-danger.focus, .btn-danger.disabled:active, .btn-danger[disabled]:active, fieldset[disabled] .btn-danger:active, .btn-danger.disabled.active, .btn-danger.active[disabled], fieldset[disabled] .btn-danger.active {
	background-color: #f34235;
	border-color: #f33d30;
}
.btn-danger .badge {
	background-color: #ffffff;
	color: #f34235;
}
.btn-link {
	border-radius: 0;
	color: #3f51b5;
	font-weight: normal;
}
.btn-link, .btn-link:active, .btn-link.active, .btn-link[disabled], fieldset[disabled] .btn-link {
	background-color: transparent;
	box-shadow: none;
}
.btn-link, .btn-link:hover, .btn-link:focus, .btn-link:active {
	border-color: transparent;
}
.btn-link:hover, .btn-link:focus {
	background-color: transparent;
	color: #2b387c;
	text-decoration: underline;
}
.btn-link[disabled]:hover, fieldset[disabled] .btn-link:hover, .btn-link[disabled]:focus, fieldset[disabled] .btn-link:focus {
	color: #e6e9ee;
	text-decoration: none;
}
.form-control {
	background-color: #ffffff;
	background-image: none;
	border: 1px solid #dbd9d9;
	border-radius: 2px;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
	color: #515d6e;
	display: block;
	font-size: 13px;
	height: 34px;
	line-height: 1.52857;
	transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
	width: 100%;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	color: inherit;
	font-family: inherit;
	font-weight: 500;
	line-height: 1.1;
}
h4, .h4, h5, .h5, h6, .h6 {
	margin-bottom: 9.5px;
	margin-top: 9.5px;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	color: inherit;
	font-family: inherit;
	font-weight: 500;
	line-height: 1.1;
}
h1 small, h2 small, h3 small, h4 small, h5 small, h6 small, .h1 small, .h2 small, .h3 small, .h4 small, .h5 small, .h6 small, h1 .small, h2 .small, h3 .small, h4 .small, h5 .small, h6 .small, .h1 .small, .h2 .small, .h3 .small, .h4 .small, .h5 .small, .h6 .small {
	color: #515d6e;
	font-weight: normal;
	line-height: 1;
}
h1, .h1, h2, .h2, h3, .h3 {
	margin-bottom: 9.5px;
	margin-top: 19px;
}
h1 small, .h1 small, h2 small, .h2 small, h3 small, .h3 small, h1 .small, .h1 .small, h2 .small, .h2 .small, h3 .small, .h3 .small {
	font-size: 65%;
}
h4, .h4, h5, .h5, h6, .h6 {
	margin-bottom: 9.5px;
	margin-top: 9.5px;
}
h4 small, .h4 small, h5 small, .h5 small, h6 small, .h6 small, h4 .small, .h4 .small, h5 .small, .h5 .small, h6 .small, .h6 .small {
	font-size: 75%;
}
h1, .h1 {
	font-size: 33px;
}
h2, .h2 {
	font-size: 27px;
}
h3, .h3 {
	font-size: 23px;
}
h4, .h4 {
	font-size: 17px;
}
h5, .h5 {
	font-size: 13px;
}
h6, .h6 {
	font-size: 12px;
}
p {
	margin: 0 0 9.5px;
}
/*
* Dashboard Conntent Css
****************************/

.dashboard-content {
	padding: 20px 0;
}
.custom-row {
	margin-bottom: 20px;
}
.stats-item {
	background: #fff;
	position: relative;
	color: #666;
	min-height: 120px;
	margin-bottom: 15px;
	float: left;
	margin-right: 1.33%;
	font-family: "Open Sans", sans-serif;
	border: none;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
	border-radius: 0;
	text-align: left;
	width: 49%;
	margin-right: 2%;
}
.stats-item:nth-child(even) {
	margin-right: 0%;
}
.stats-item:hover {
	border: none;
	background: #f5f5f5;
}
.stats-item i.icon-large {
	position: absolute;
	left: 10px;
	opacity: 0.5;
	top: 10px;
	line-height: 60px;
}
.stats-item span.text-large {
	font-weight: 100;
	font-size: 54px;
	display: block;
	text-align: right;
	line-height: 80px;
	padding: 0 15px;
}
.stats-item .label {
	position: absolute;
	bottom: 0;
	border-radius: 0;
	margin: 0;
	font-weight: 500;
	font-size: 13px;
	left: 0;
	width: 100%;
	padding: 10px 15px;
}
.status-slides .status-group-item {
	padding: 15px;
	border-radius: 0;
	margin-bottom: 10px;
	background: #fff;
	box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.26);
	margin-bottom: 10px;
	position: relative;
}
.status-slides .status-group-item .name {
	font-size: 20px;
	font-weight: 100;
}
.status-slides .status-group-item .label {
	border-radius: 3px;
	float: none;
	font-size: 16px;
	font-weight: 400;
	padding: 5px 12px;
	position: absolute;
	right: 10px;
}
.notification-center .notification-sender {
	padding-right: 65px;
}
.notification-center .alert {
	position: relative;
}
.notification-center .alert-content {
	padding-left: 70px;
	padding-right: 10px;
}
.notification-center .icon {
	position: absolute;
	left: 15px;
	top: 15px;
}
.notification-center .notification-time {
	position: absolute;
	right: 15px;
	color: rgba(255, 255, 255, 0.8);
}
.notification-center p {
	margin-bottom: 0;
}
.post-title {
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 10px;
}
/*
* Sidebar Styling
****************************/

.logo-surround {
	background: none;
	border-bottom: 1px solid rgba(0, 0, 0, 0.4);
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4);
	height: 50px;
	margin-bottom: 20px;
	padding: 12px 0;
	position: relative;
	text-align: center;
	width: 50px;
}
.sidebar {
	/*! background-color: #263456; */

	border-right: none;
	bottom: 0;
	box-shadow: 0 0 0 #ccc;
	display: block;
	left: 0;
	padding: 0;
	position: fixed;
	top: 0;
	width: 50px;
	z-index: 1000;
	transition: all 0.5s;
}
#nav-wrapper {
	height: 100%;
	min-width: 50px;
}
.main-navigation {
	background-color: #263456;
	width: 50px;
	height: 100%;
	z-index: 250;
	position: absolute;
	left: 0;
}
.main-navigation li {
	margin-bottom: 15px;
	padding: 0 5px;
	text-align: center;
}
#item-signout {
	bottom: 10px;
	box-shadow: 0 0 3px #222;
	color: rgba(255, 255, 255, 0.3);
	font-size: 20px;
	margin-bottom: 0;
	right: 10px;
	padding: 0;
	position: absolute;
	text-align: center;
	width: 30px;
	border-radius: 100% 90%;
	z-index: 4;
}
#item-signout:hover {
	color: rgba(255, 255, 255, 0.8);
}
.main-navigation li a {
	border-radius: 3px;
	color: rgba(255, 255, 255, 0.3);
	display: block;
	padding: 8px 0;
}
.main-navigation li i {
	font-size: 18px;
}
.main-navigation li.active a {
	background: #3291e1;
	color: rgba(255, 255, 255, 0.8);
}
.main-navigation li a:hover {
	color: rgba(255, 255, 255, 0.8);
}
/* DIRECTION CONTROLS (NEXT / PREV) */

.main-navigation .bx-wrapper .bx-prev {
	left: 54px;
	font-size: 0;
}
.main-navigation .bx-wrapper .bx-prev::after {
	content: "\f053";
	color: #ccc;
	font-family: 'Font Awesome\ 5 Pro';
	font-size: 14px;
	line-height: 36px;
}
.main-navigation .bx-wrapper .bx-next {
	right: 54px;
	font-size: 0;
}
.main-navigation .bx-wrapper .bx-next::after {
	content: "\f054";
	color: #ccc;
	font-family: 'Font Awesome\ 5 Pro';
	font-size: 14px;
	line-height: 36px;
}
.main-navigation .bx-wrapper .bx-controls-direction a {
	height: 32px;
	margin-top: -16px;
	outline: 0 none;
	position: absolute;
	text-align: center;
	text-indent: 0;
	top: 50%;
	width: 20px;
	z-index: 9999;
}
.main-navigation .bx-wrapper .bx-controls-direction a.disabled {
	opacity: 0;
	cursor: not-allowed;
}
.st-menu-open .main {
	margin-left: 250px;
}
.st-menu-open .sub-navigation {
	visibility: visible;
	-webkit-transition-timing-function: ease-in-out;
	transition-timing-function: ease-in-out;
	-webkit-transition-property: -webkit-transform;
	transition-property: transform;
	-webkit-transform: translateX(50px);
	transform: translateX(50px);
	-webkit-transition-speed: 0.2s;
	transition-speed: 0.2s;
}
.sub-navigation {
	background: #3291e1;
	position: absolute;
	top: 0;
	left: 0;
	width: 185px;
	visibility: hidden;
	z-index: 1;
	opacity: 1;
	-webkit-transform: translateX(-100%);
	transform: translateX(-100%);
	height: 100%;
	transition: all 0.5s;
}
.sub-navigation h3 {
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
	margin: 0;
	margin-bottom: 30px;
	padding: 0 15px;
}
.sub-navigation h3 a {
	color: #fff;
	font-size: 14px;
	font-weight: 600;
	line-height: 50px;
	text-shadow: 0 0 4px rgba(0, 0, 0, 0.6);
	text-transform: uppercase;
}
.sub-navigation .listing {
	border-left: 1px solid rgba(255, 255, 255, 0.2);
	margin: 0px 5px 0px 15px;
}
.sub-navigation .listing li a {
	color: rgba(255, 255, 255, 0.7);
}
.sub-navigation .listing li a i {
	display: inline-block;
	font-size: 15px;
	left: 8px;
	position: absolute;
	text-align: center;
	top: 1px;
	vertical-align: top;
	width: 20px;
}
.sub-navigation .listing li.active a, .sub-navigation .listing li a:hover {
	color: #fff;
}
.sub-navigation > ul > li {
	height: 0;
	overflow: hidden;
	position: relative;
	text-align: left;
	transition-duration: 0.35s;
	transition-property: height, visibility;
	transition-timing-function: ease;
}
.sub-navigation > ul > li.active {
	height: auto;
}
.main {
	/*margin-left: 65px;*/
	/*margin-right: 15px;*/
	/* padding: 0; */
}
.main .title-bar {
	padding: 0 0 15px;
}
.main .title-bar i {
	margin-right: 5px;
}
.main .title-bar h1 small {
	color: #8394a9;
	font-size: 12px;
	font-weight: 500;
}
.main .title-bar h1 i, .main .title-bar h1 span.glyphicon {
	color: #3291e1;
}
/*
* Subnav Styling
****************************/

.top-subnav li {
	border: 1px solid #CCC;
	margin: 12px 0;
}
.top-subnav li a {
	border-radius: 0;
	background: #EEE;
	padding: 5px 12px;
}
.top-subnav > li > a:focus, .top-subnav > li > a:hover, .top-subnav > li.active > a {
	background: #289DCD;
	color: white;
}
/*
** Dashboard Widget Classes
****************************/

.icon-large {
	font-size: 50px !important;
}
.text-large {
	font-size: 50px;
}
.glyphicon-medium {
	font-size: 16px;
	line-height: 14px;
}
.glyphicon-large {
	font-size: 40px;
}
.widgetRow .list-group-item a {
	color: white;
	opacity: 0.8;
}
.widgetRow .list-group-item a:hover {
	opacity: 1.0 !important;
}
.widgetBlock {
	display: block;
	background-color: #3291e1;
	min-height: 320px;
}
.equal-row .eq-column {
	min-height: 330px;
}
/*
** Tab Classes
****************************/


/*
** Scroll Tab Classes
****************************/

.scroll-tabs {
	width: auto;
}
.scroll-tabs .scroll-tabs-inner {
	position: relative;
	margin: 0 auto;
	overflow: hidden;
	padding: 5px;
	height: 50px;
}
.scroll-tabs .nav-tabs {
	position: absolute;
	left: 0;
	top: 0;
	min-width: 3000px;
	margin-left: 12px;
	margin-top: 0;
}
.scroll-tabs .nav-tabs li {
	display: table-cell;
	position: relative;
	text-align: center;
	cursor: grab;
	cursor: -webkit-grab;
	color: #efefef;
	vertical-align: middle;
}
.scroll-tabs .scroller {
	text-align: center;
	cursor: pointer;
	padding: 7px;
	padding-top: 11px;
	white-space: nowrap;
	vertical-align: middle;
	background-color: #fff;
}
.scroll-tabs .scroller-right {
	float: right;
}
.scroll-tabs .scroller-left {
	float: left;
}
/*
** jQuery UI - Autocomplete
****************************/

.ui-autocomplete {
	display: inline-block !important;
}
.ui-autocomplete-gallery {
	color: #3291e1;
	font-weight: 500;
	border-top: solid 1px #eee;
	/* opacity: 0.8; */

	font-family: open sans;
	padding: 6px 15px;
	margin: 0;
	font-size: 18px;
	background: #f0f0f0;
}
.ui-menu-item {
	padding: 5px;
	font-size: 13px;
	color: black;
}
.ui-autocomplete {
	max-height: 350px;
	overflow-y: auto;
	overflow-x: hidden;
}
/* IE 6 doesn't support max-height*/

* html .ui-autocomplete {
	height: 350px;
}
/*
** Searchable Classes
****************************/

.ordering-container {
	height: 125px;
}
/*
** Color Classes
****************************/

.white {
	color: white !important;
}
.blue {
	color: #428bca !important;
}
.green {
	color: green !important;
}
.red {
	color: red !important;
}
.red-dark {
	color: #b10c35 !important;
}
.maroon {
	color: #a2407c !important;
}
.gold {
	color: gold !important;
}
.yellow {
	color: #F8BB86 !important;
}
.lightgrey {
	color: #E3E3E3;
}
.light-blue {
	color: #5bc0de;
}
.orange {
	color: orange;
}
/* Colors */
.navy { color: #001F3F !important; }
.blue { color: #0074D9 !important; }
.aqua { color: #7FDBFF !important; }
.teal { color: #39CCCC !important; }
.olive { color: #3D9970 !important; }
.green { color: #2ECC40 !important; }
.lightest-green { color: #aaee82 !important; }
.light-green { color: #67C230 !important; }
.lime { color: #01FF70 !important; }
.yellow { color: #FFDC00 !important; }
.orange { color: #FF851B !important; }
.red { color: #FF4136 !important; }
.fuchsia { color: #F012BE !important; }
.purple { color: #B10DC9 !important; }
.maroon { color: #85144B !important; }
.white { color: #FFFFFF !important; }
.silver { color: #DDDDDD !important; }
.gray { color: #AAAAAA !important; }
.black { color: #111111 !important; }

/*
** Flag Classes
****************************/

.flagItem {
	cursor: pointer;
}
.Important {
	color: #428bca;
	text-shadow: 0 1px 1px #888;
}
.Review {
	color: red;
	text-shadow: 0 1px 1px #888;
}
.None {
	color: #C3C3C3;
}
.flagLegend {
	padding-top: 10px;
	font-size: 12px;
}
.flagLegend .flaglegendItem {
	margin-left: 10px;
}
a.tooltip {
	display: block;
}
.tooltip.top .tooltip-arrow {
	bottom: 0;
	left: 50%;
	margin-left: -5px;
	border-top-color: #fff;
	border-width: 5px 5px 0;
}
.tooltip-inner {
	color: #000;
	background: #fff;
	border: solid 1px #fff;
}
.tooltip.left .tooltip-arrow {
	top: 50%;
	right: 0;
	margin-top: -5px;
	border-left-color: #000000;
	border-width: 5px 0 5px 5px;
}
.tooltip.right .tooltip-arrow {
	top: 50%;
	left: 0;
	margin-top: -5px;
	border-right-color: #000000;
	border-width: 5px 5px 5px 0;
}
.tooltip.bottom .tooltip-arrow {
	top: 0;
	left: 50%;
	margin-left: -5px;
	border-bottom-color: #000000;
	border-width: 0 5px 5px;
}
/*
** Display List Classes
****************************/

.displayExpandable {
	-webkit-transition: height .05s;
	-moz-transition: height .05s;
	-ms-transition: height .05s;
	-o-transition: height .05s;
	transition: height .05s;
	background: #e5feff;
	overflow: hidden;
	height: 10px;
	position: relative;
}
.expandQuickEdit {
	margin: 0 5px 0 0;
	border: 1px solid #3291e1;
	border-radius: 0 4px 4px 0;
	padding: 2px 5px 2px 5px;
}

.displayQuickScheduler .select2-container--default .select2-selection--single, .select2-xs.select2-container--default .select2-selection--single {
	border-radius: 4px 0 0 4px;
	border-right-width: 0;
	min-height: 26px;
}
.displayQuickScheduler .select2-container--default .select2-selection--single .select2-selection__rendered, .select2-xs.select2-container--default .select2-selection--single .select2-selection__rendered {
	font-size: 11px;
    padding: 2px 20px 2px 8px;
}
.displayQuickScheduler .select2-container--default .select2-selection--single .select2-selection__arrow, .select2-xs.select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 24px;
}
.cell-content a.expandQuickEdit {
    margin-top: 0;
}
.cell-content a.expandQuickEdit small {
	color: #3291e1;
	display: inline-block;
	font-size: 11px;
	font-weight: 500;
	line-height: 18px;
	padding: 1px 5px;
	vertical-align: top;
}
.cell-content .expandQuickEdit:hover {
	background: #3291e1 none repeat scroll 0 0;
	border: solid 1px #fff;
}
.cell-content .expandQuickEdit:hover small {
	color: #fff;
}
.displayContents {
	position: absolute;
	width: 100%;
}
.expandable .displayContents .timeline-wrapper {
	overflow: hidden;
}
.displayContents .timeline-wrapper {
    /*min-height: 173px;
	max-height: 655px;*/
	/*overflow-y: scroll;*/
	display: block;
	float: left;
	width: 100%;
}
/* Chrome Fix - Scheduler Width */


/*
** Icon Classes
****************************/

.glyph-sm {
	font-size: 12px !important;
}
.colorpicker-element .input-group-addon i {
	background-color: #CCC;
}
/*
** DivTable Classes
****************************/

.dTable {
	display: table;
	min-width: 100%;
	border: 1px solid #ccc;
    table-layout: fixed;
}
.dTitle {
	display: table-caption;
	text-align: center;
	font-weight: bold;
	font-size: larger;
}
.dHeading {
	display: table-row;
	font-weight: bold;
	text-align: center;
}
.dHeading .dCell:first-of-type {
	border-left-width: 0;
}
.dHeading .dCell, .custom-dTable > thead > tr > th {
	color: #ffffff;
	padding: 8px 7px !important;
	background: #5062c2;
	border-bottom: none;
    font-size: 11px;
}
.dHeading .sortbylink {
	color: #fff;
	cursor: pointer;
}
.dHeading .sortbylink:hover {
	color: #000;
}
.dRow {
	display: table-row;
	text-align: center;
}
.dCell {
	border: none;
	border-bottom: solid 1px #ddd;
	display: table-cell;
	padding: 6px 10px;
	background: #f6f7fa;
	color: #47586c;
	vertical-align: middle;
}
.dRow:not(.dHeading) .dCell {
    -ms-word-wrap: break-word;
    word-wrap: break-word;
}
.dCell.cell-item-name .cell-content {
	padding: 0;
}
.top-stats-panel {
	min-height: 164px;
}
.dTable a {
	color: #2095f2;
}
.dTable a:hover {
	color: #0d80dc;
}
.dRow .small {
	margin-bottom: 5px;
	padding-bottom: 5px;
	border-bottom: 1px solid #EEE;
}
.dTable .dRow:nth-child(even) .dCell, .custom-dTable tbody tr:nth-child(even) td {
	background: #e6e9ee;
}
.dRow .small span {
	display: block;
	font-weight: bold;
	padding-right: 5px;
}
.dTableTransparent {
	border: 0;
}
.dTableTransparent .dCell, .dTableTransparent.dTable .dRow:nth-child(2n) .dCell {
	background: none;
	color: white;
}
.dRow .dCell:last-child .btn {
	margin-bottom: 5px;
}
/* Filters & Search */

#filter-form .filter-toolbar > div {
	margin-bottom: 10px;
}
.filter-toolbar a {
	cursor: pointer;
}
.filter-separator .filter-form-group {
	float: left;
}
.filter-toolbar .btn-group-sm > .btn, .btn-sm {
	padding: 6px 10px;
}
.filter-toolbar .btn-default {
	background-color: #f5f5f5;
	border-color: #ccc;
	color: #333;
	font-weight: 500;
}
.dRow.dFilters .form-control {
	width: 100%;
}
.dRow.dFilters .select2 {
	text-align: left !important;
	white-space: normal;
}
.dRow.dFilters .search-input-table {
	line-height: 20px;
	height: 34px;
	font-size: 13px;
	border: 1px solid #ddd;
}
.dRow.dFilters .dCell {
	background: #fff none repeat scroll 0 0;
	border-bottom: 1px solid #ddd;
	padding-bottom: 10px;
	text-align:left;
}
.empty-collection {
	border: 1px solid #CCC;
	padding: 10px 10px;
	background: white;
	font-size: 15px;
}
.tooltip > .tooltip-inner {
	background-color: #337AB7;
	color: white;
	box-shadow: 0 0 3px #888;
}

/*
** Select 2 Changes
****************************/
.select2-results {
    min-width: 100%;
}
.select2-dropdown {
    width: auto;
}
.select2-search {
    width: auto !important;
}
.select2-results__option {
    white-space: nowrap;
    overflow-x: hidden;
    padding-right: 20px;
    min-width: 100%;
}

/*
** Spinner Buttons
****************************/

a.spinner {
	display: inline-block;
	opacity: 0;
	max-width: 0;
	-webkit-transition: opacity 0.25s, max-width 0.45s;
	-moz-transition: opacity 0.25s, max-width 0.45s;
	-o-transition: opacity 0.25s, max-width 0.45s;
	transition: opacity 0.25s, max-width 0.45s;/* Duration fixed since we animate additional hidden width */
}
.has-spinner .spinner {
	display: none;
}
.has-spinner.active .spinner {
	opacity: 1;
	max-width: 50px;
	display: inline-block;
}
.spin {
	-webkit-animation: spin 2s infinite linear;
	-moz-animation: spin 2s infinite linear;
	-o-animation: spin 2s infinite linear;
	animation: spin 2s infinite linear;
}
@-moz-keyframes spin {
 from {
 -moz-transform: rotate(0deg);
}
to {
	-moz-transform: rotate(360deg);
}
}
@-webkit-keyframes spin {
 from {
 -webkit-transform: rotate(0deg);
}
to {
	-webkit-transform: rotate(360deg);
}
}
@keyframes spin {
 from {
 transform: rotate(0deg);
}
to {
	transform: rotate(360deg);
}
}
/*
** Dashboard Widgets
****************************/

.top-stats-panel {
	min-height: 120px;
	background: #EEE;
	padding: 5px;
}
.gauge-canvas {
	text-align: center;
}
.widget-h {
	color: #AFAEBC;
	font-size: 16px;
	text-transform: uppercase;
	margin: 0 0 10px;
	text-align: center;
}
.gauge-meta {
	margin: 0;
	padding-top: 7px;
	list-style: outside none none;
	padding-left: 0;
}
.gauge-value {
	color: #FA8564;
	font-size: 16px;
	font-weight: bold;
	padding-left: 20px;
}
.gauge-title {
	color: #CCC;
	font-size: 14px;
	padding-right: 20px;
}
.stat-meta {
	margin: 0;
	padding: 0;
	padding-top: 7px;
	list-style: none none;
	padding-left: 0;
}
.stat-value {
	margin: 0;
	padding: 0 !important;
	color: #FA8564;
	font-size: 30px;
	font-weight: bold;
	padding-left: 20px;
	width: 100%;
	text-align: center;
}
.stat-title {
	color: #CCC;
	font-size: 14px;
	padding-right: 20px;
}
.alert-warning .alert-icon {
	background-color: #FFE699;
}
.alert-success .alert-icon {
	background-color: #98D7AD;
}
.alert-danger .alert-icon {
	background-color: #FCB1AE;
}
.alert-info .alert-icon {
	background-color: #99CCE3;
}
.alert-icon {
	width: 40px;
	height: 40px;
	display: inline-block;
	border-radius: 100%;
}
.alert-icon i {
	width: 40px;
	height: 40px;
	display: block;
	text-align: center;
	line-height: 40px;
	font-size: 20px;
	color: #FFF;
}
.widgetRow .panel-heading {
	border-color: #EFF2F7;
	font-size: 13px;
	font-weight: 400;
	background: #FAFAFA none repeat scroll 0% 0%;
	text-transform: uppercase;
	padding: 15px;
}
.notification-success, .notification-danger, .notification-default, .notification-primary, .notification-info {
	margin-left: 56px;
	margin-top: -40px;
}
.notification-meta {
	margin-bottom: 3px;
	padding-left: 0;
	list-style: outside none none;
}
.notification-info p {
	margin: 0;
	color: #999;
	font-size: 12px;
}
.notification-time {
	font-style: italic;
	color: #999;
}
.tools a {
	margin-left: 10px;
	color: #A7A7A7;
	font-size: 12px;
}
/*
** Slide Item Edit Page
****************************/

.slide-preview-container {
	text-align: left;
}
img.slide-preview {
	background: #FFF;
	padding: 5px;
	max-height: 500px;
	border: 1px solid #CCC;
	box-shadow: 0 0 5px #CCC;
}

.menu-size-tabs li a {
    padding: 8px 6px;
    font-size: 12px;
}
.menu-size-tabs li {
    padding: 0 6px 0 0;
}
.menu-size-tabs li.active a {
    padding: 8px 6px 15px 6px;
}

/*
** Menu Item Edit Page
****************************/

.menu-preview-container {
	text-align: left;
}
img.menu-preview {
	background: #FFF;
	padding: 5px;
	max-height: 500px;
	border: 1px solid #CCC;
	box-shadow: 0 0 5px #CCC;
}
/*
** Image Upload Forms
***************************/
/* Base Styles */

.file-preview-frame, .file-input .file-preview, .file-drop-zone, .file-drop-disabled, .file-preview-thumbnails {
	width: 100%;
	height: 100%;
	/*  box-shadow: none;
	border: none;
	display: block;
	padding: 0;
	margin: 0;*/

	text-align: center;
}
.file-input .file-preview img {
	/*height: 84% !important;
	min-height: 84% !important;*/
	padding: 2px;
	margin: 0;
	max-width: 100%;
	box-shadow: 0 0 5px #C3C3C3;
	background: white;
	border: 1px solid #C3C3C3;
}

.upload-image-xs.tab-content, .upload-image-sm.tab-content, .upload-image-md.tab-content, .upload-image-lg.tab-content {
	border-width: 0 1px 1px 1px;
	border-style: solid;
	box-shadow: 0 1px 3px #CCC;
	padding: 15px;
}
.upload-image-xs .edit-buttons, .upload-image-sm .edit-buttons, .upload-image-md .edit-buttons, .upload-image-lg .edit-buttons {
	position: absolute;
	bottom: 20px;
	left: 20px;
}
.upload-image-xs .file-input, .upload-image-sm .file-input, .upload-image-md .file-input, .upload-image-lg .file-input {
	position: relative;
}
.file-preview-frame:hover:not(.file-preview-error) {
	box-shadow: none;
}
.file-preview-frame {
	display: block;
	/*height: 90% !important;
	margin: 0 70px 20px;*/
	width: auto !important;
	float: none;
}
.file-drop-zone {
	margin: 0 0 15px;
}
.file-preview {
	border: none;
	padding: 0;
}
.file-default-preview {
	background: #e6e9ee;
	border: 1px solid #ddd;
	border-radius: 2px;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
	height: 100%;
	padding: 15px;
    display:flex;
    justify-content:center;
    align-items: center;
	overflow: hidden;
}
.file-default-preview .upload-image-placeholder div {
	text-align: center;
	font-size: 20px;
	height: auto !important;
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	transform: translateY(-50%);
}
.file-default-preview .upload-image-placeholder {
	background: #e6e9ee;
	height: 100%;
}
.file-input.has-error .file-preview-other {
    text-align: center;
    margin: 0 auto !important;
    display: inline-block;
}
.file-input.has-error .file-preview-other span {
    vertical-align: middle !important;
    line-height: 150px !important;
}
.file-input.has-error .file-other-error {
    text-align: center !important;
}

/* XS Image Uploader */

.upload-image-xs .file-input {
	height: 125px;
}
/* SM Image Uploader */

.upload-image-sm .file-input {
	height: 200px;
}
/* MD Image Uploader */

.upload-image-md .file-input {
	height: 300px;
}
/* LG Image Uploader */

.upload-image-lg .file-input {
	height: 500px;
}
/*
** Display Edit Styling
****************************/

.display-container {
	min-height: 300px;
}
#display-accordion .panel-heading.altPad {
	padding: 0;
}
#display-accordion .panel-heading.altPad h4, #display-accordion .panel-heading.altPad a {
	display: block;
}
#display-accordion .panel-heading.altPad a {
	padding: 10px 10px;
	cursor: pointer;
}
.display-container .well {
	background: white;
	margin-top: 0;
}
.display-container .menu-content-wrapper {
	position: relative;
	width: 100%;
}
.display-container .menu-content-wrapper {
	width: 100%;
	padding-bottom: 76.5%;
}
.display-container .menu-content-wrapper.layout-default, .display-container .menu-content-wrapper.layout-landscape {
	padding-bottom: 56.5%;
}
.display-container .menu-content-wrapper.layout-portrait {
	padding-bottom: 66.9%;
}
.display-container .menu-content {
	position: absolute;
	border-top: 1px solid #71738c;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: 0 0 0;
}
.display-container .menu-content-wrapper.layout-portrait .menu-content {
	margin: 0 31% 0 31%;
}
.theme-panel {
	max-height: 600px;
	margin-bottom: 10px;
}
.layout-inner-tabs.nav-tabs > li {
	padding: 0 5px 0 0;
	margin: 0 0 5px;
}
.layout-inner-tabs.nav-tabs > li > a {
	padding: 5px 10px !important;
	border: 1px solid #333 !important;
}
.layout-inner-tabs.nav-tabs > li.active > a, .layout-inner-tabs.nav-tabs > li.active > a:focus, .layout-inner-tabs.nav-tabs > li.active > a:hover {
	background: #46a149;
	color: white !important;
}
.scroll-themes .layout-tab-content {
	background: #fff;
	padding: 10px;
	border: solid 1px #3291e1;
	border-top: none;/*height: 180px;*/
}
.scroll-themes .layout-tab-inner-content {
	background: #fff;
	padding: 5px;
	border: solid 1px #3291e1;
	border-top: none;
}
.scroll-themes .alert {
	margin-bottom: 10px;
}
.scroll-themes label input {
	display: none;
}
.scroll-themes label span {
	display: block;
	text-align: center;
	background: #337ab7;
	border-radius: 0 0 3px 3px;
	padding: 5px 0;
	color: white;
}
.scroll-themes input[type="radio"]:checked+label {
	border: 1px solid red;
}
.scroll-themes label > input + .layout-image {
	border-color: #333;
	border-style: solid;
	border-width: 1px;
	cursor: pointer;
	opacity: 0.85;
	padding: 2px;
	position: relative;
}
.scroll-themes label > input:checked + .layout-image {
	opacity: 1;
}
.scroll-themes label > input:checked + .layout-image:before {
	position: absolute;
	content: "";
	height: 100%;
	width: 100%;
	background: white;
	opacity: .4;
}
.scroll-themes label > input:checked + .layout-image:after {
	position: absolute;
	font-family: 'Font Awesome\ 5 Pro';
	content: "\f058";
	font-size: 36px;
	height: 36px;
	width: 36px;
	color: green;
	left: 50%;
	top: 50%;
	margin: -25px 0 0 -15px;
}
.scroll-themes label > input:checked + span.layout-name {
	background: #449d44 !important;
}
.display-container .slider li, .display-container .slider img {
	width: 100% !important;
	height: 100% !important;
}
.display-container .static-wrapper, .display-container .slide-wrapper {
	background: #fff none repeat scroll 0 0;
	border-radius: 6px;
	position: relative;
}
.display-container .static-item, .display-container .static-item img {
	height: 100%;
	width: 100%;
	border-radius: 6px;
}
.display-container .static-links, .display-container .slide-links {
	width: 100%;
	height: 100%;
	z-index: 100;
	padding: 10px;
	bottom: 0;
	background: rgba(1, 1, 1, 0.4);
	border-radius: 6px;
	position: absolute;
}
.display-container .static-links-wrapper, .display-container .slide-links-wrapper {
	text-align: center;
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}
.display-container .display-content-inner {
	border: 1px solid #CCC;
	border-radius: 3px;
}
.display-container .templateSlide {
	border-radius: 6px;
}
.display-container .templateSlide .slide-links {
	background: #C3C3C3;
	padding: 3px;
	border-radius: 6px;
	box-shadow: 0 0 5px #888;
}
.display-container .modal-lg {
	width: 96% !important;
	height: auto;
	margin: 30px 2%;
}
.display-container .displayContents {
	position: static;
	border: none;
}
.display-container .displayContents .timeline-wrapper {
	/*height: 625px;*/
}
.display-content {
	background: #f3f2f7;
	width: 100%;
	border-radius: 3px;
}
.display-content .display-toolbar {
	padding: 10px;
	width: 100%;
	border-radius: 3px 3px 0 0;
	background: #263456;
	position: relative;
}
.display-content .display-toolbar .toolbar-name {
	color: #fff;
	float: left;
	font-size: 22px;
	font-weight: 400;
	height: 100%;
	line-height: 20px;
	padding: 10px;
	text-align: left;
	width: 200px;
}
#spinner {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 9995;
}
#spinner .spinner {
	height: 100%;
	width: 100%;
	display: block;
	box-shadow: 0 5px 10px #E3E3E3 inset;
}
.display-toolbar .btn-group {
	padding: 5px;
}
.displayModal .modal-dialog {
	width: 1024px;
}
.displayModal .modal-content {
	width: 1024px;
	height: auto;
}
.displayModal .modal-body .scrollable  {
    overflow-y: auto;
    overflow-x: hidden;
    height: 410px;
}
.displayModal ul.row {
	margin: 0 -10px;
	padding: 0;
}
.displayModal .alert {
	margin-bottom: 5px;
}
.displayModal .alert h4 {
	margin-bottom: 0;
	font-size: 16px;
}
.displayModal .alert .fa-stack {
	font-size: 14px;
}
.modal-content .slide-item-spacer {
	height: 100%;
	display: block;
	position: relative;
	margin: 0 3px;
	background: #F2F2F2;
	border: 1px solid #FFF;
	box-shadow: 0 3px 5px #CCC;
	border: 1px solid #CCC;
	border-radius: 6px 6px 0 0;
	cursor: pointer;
}
.modal-content .menuImage {
	text-align: center;
}
.modal-content .menuItem img {
	width: 150px;
}
.modal-content .menuCaption {
	text-align: right;
	font-weight: bold;
	padding-right: 5px;
}
.modal-content .menuPrice {
	text-align: left;
	color: #666;
}
.modal-header .close {
	font-size: 40px;
	margin-top: -10px;
	color: #CC0000;
	opacity: .6;
}
.modal-header .close:hover {
	opacity: .9;
	color: black;
}
.modal-content h5 {
	margin: 0;
	padding: 5px 0 5px;
	font-size: 85%;
	border-radius: 6px 6px 0 0;
	border: 1px solid #00B0F0;
	width: 100%;
	text-align: center;
	background: #00B0F0;
	color: white;
	font-weight: bold;
}
.modal-content .slideItem h5 {
	position: absolute;
	top: 0;
}

.menuRow {
	background: white;
	cursor: move;
}
.menuRow.gridCell {
	position: relative;
	border-radius: 6px;
	height: 100%;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}
.menuRow.gridCell:before, .menuRow.gridCell:after {
	border-radius: 6px;
}
.gridLinks {
	position: absolute;
	width: 100%;
	bottom: 0;
	left: 0;
	text-align: right;
	padding: 5px;
	background: rgba(255, 255, 255, 0.6);
	z-index: 30;
}
.disabled-menu .menuRow {
	opacity: 0.5;
	cursor: initial;
}
.disabled-menu .gridLinks .canLock {
	display: none;
}
.disabled-menu .gridLinks:after {
	font-family: "Font Awesome\ 5 Pro";
	position: relative;
	right: 10px;
	top: 0;
	content: '\f023';
	color: #FFF;
	font-size: 1em;
	padding: 0 10px 0 15px;
	border-radius: 0 0 4px 0;
}
.disabled-menu .gridLinks::before {
	background: rgba(210, 0, 0, 0.65) none repeat scroll 0 0;
	border-radius: 0 0 4px;
	color: #fff;
	content: "Locked";
	font-size: 1em;
	padding: 1% 25px 1% 10px;
	position: relative;
	right: -25px;
	top: 0;
}
.menuRow span.effect-apply.list {
	font-size: 10px;
	margin-left: 5px;
}
.menuRow span.effect-apply.grid {
	font-size: 10px;
	float: right;
	margin: 10px 3px 0;
}
.menuRow.gridCell input[type=checkbox] {
	margin: 10px 3px 0 0;
}
.menu-item-selector {
	z-index: 99;
	position: absolute;
	right: 50px;
	top: 10px;
	background: white;
	padding: 1px 5px 0;
}
.menu-item-selector .select2 {
	min-width: 250px;
}
.menu-settings .alert, .menu-config .alert {
	margin-top: 5px;
	padding: 5px;
}
.menu-edit-options .title-row {
	border-radius: 4px;
	margin-bottom: 10px;
	padding: 0;
}
.menu-edit-options .title-row h4 {
	font-size: 15px;
	margin-top: 0;
}
.menu-settings .modal-body, .menu-config .modal-body {
	/* overflow: hidden !important; */
	height: auto !important;
}
.menu-config .row div {
	cursor: inherit;
	-webkit-transition: width 0.3s ease, margin 0.3s ease;
	-moz-transition: width 0.3s ease, margin 0.3s ease;
	-o-transition: width 0.3s ease, margin 0.3s ease;
	transition: width 0.3s ease, margin 0.3s ease;
}
.menu-config .panel-group {
	margin: 0;
}
.menu-config .menu-config-option-edit {
	float: left;
	background: orange;
	width: calc(100% - 295px);
	height: 100%;
}
.menu-config .panel {
	border-radius: 0 !important;
	border: none;
	margin-top: 0 !important;
}
.menu-config .panel-heading {
	padding: 7px 10px;
	color: white;
	border-radius: 0;
	background: #337ab7;
}
.menu-config .panel-heading a i {
	margin-right: 5px;
	color: yellow;
}
.menu-config .panel-heading a:focus {
	text-decoration: none;
}
.menu-config .list-group {
	border: none !important;
	margin: 0;
}
.menu-config .list-group-item {
	cursor: pointer;
	padding: 8px 10px;
}
.menu-config .list-group-item:not(:last) {
	padding-left: 40px;
	background: #DDD;
	border-bottom: 1px solid #666;
	margin-bottom: 1px;
	border-radius: 0;
	opacity: 0.6;
}
.menu-config .list-group-item:hover {
	opacity: 1;
	background: #f5f5f5;/*color:#;*/
}
.menu-config .grid-preview-wrapper {
	padding: 20px 0;
	height: 300px;
	box-shadow: 0 0 4px #888 inset;
	background: rgb(255, 255, 255);
	/* Old browsers */

	background: -moz-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 1) 0%, rgba(194, 196, 198, 1) 78%);
	/* FF3.6-15 */

	background: -webkit-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 1) 0%, rgba(194, 196, 198, 1) 78%);
	/* Chrome10-25,Safari5.1-6 */

	background: radial-gradient(ellipse at center, rgba(255, 255, 255, 1) 0%, rgba(194, 196, 198, 1) 78%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

	filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#c2c4c6', GradientType=1);
/* IE6-9 fallback on horizontal gradient */
}
.menu-settings .font-row {
	display: block;
	border: 1px solid #CCC;
	position: relative;
	margin-bottom: 10px;
}
.menu-settings .font-conf {
	width: 30%;
	margin: 0 0 0 0%;
	display: inline-block;
	padding: 10px;
	vertical-align: top;
}
.menu-settings .font-preview-wrapper {
	display: inline-block;
	width: 68.5%;
	margin: 5px 0 0 0;
	height: 105px;
}
.menu-settings .font-preview {
	background: #DDD;
	height: 100%;
	text-align: center;
	font-size: 30px;
	line-height: 105px;
}
.menu-settings .font-preview .price-preview span {
	font-size: 50px;
	vertical-align: top;
}
.menu-settings .font-preview .price-preview sup {
	font-size: 20px;
	vertical-align: middle;
}
.menu-config .grid-preview-inner {
	width: 540px;
	border-radius: 6px;
	margin: 0 auto;
	height: 100%;
}
.menu-config .grid-preview-inner.preview-2 {
	width: 375px;
}
.menu-config .grid-preview-inner.preview-25 {
	width: 435px;
}
.menu-config .grid-preview-inner.preview-30 {
	width: 500px;
}
.menu-config .grid-preview-inner.preview-33 {
	width: 550px;
}
.menu-config .grid-preview {
	height: 100%;
	width: 100%;
}
.menu-config .grid-preview .imageBox img {
	bottom: 0;
}
.menu-config .menu-flex-item {
	background: white;
	width: 100% !important;
	height: 100% !important;
	border-radius: 6px;
}
.menu-config .menu-flex-item .menuRow {
	box-shadow: 0 0 3px #888;
	background-repeat: no-repeat;
	-webkit-background-size: 100% 100%;
	-moz-background-size: 100% 100%;
	-o-background-size: 100% 100%;
	background-size: 100% 100%;
}
.menu-edit-options {
	background: #f6f6f6 none repeat scroll 0 0;
	border: 2px solid #3291e1;
	border-radius: 4px;
	min-height: 275px;
	margin-top: 10px;
	padding: 10px;
}
.menu-size-chooser label, .menu-layout-chooser label {
	margin: 5px 0 0 0;
	padding: 5px;
}
.menu-size-chooser label input, .menu-layout-chooser label input {
	display: none;
}
.menu-size-chooser label > input + img, .menu-layout-chooser label > input + img {
	cursor: pointer;
	border-width: 1px;
	border-color: #337ab7;
	border-style: solid;
}
.menu-size-chooser span.layout-name, .menu-layout-chooser span.layout-name {
	display: block;
	text-align: center;
}
.menu-size-chooser span.layout-name {
	font-size: 10px;
}
.menu-size-chooser input[type="radio"]:checked+label, .menu-layout-chooser input[type="radio"]:checked+label {
	border: 1px solid #CC0000;
}
.menu-size-chooser label > input + img, .menu-layout-chooser label > input + img {
	cursor: pointer;
	border-width: 2px;
	border-color: transparent;
	border-style: solid;
	background: #CCC;
}
.menu-size-chooser label > input:checked + div, .menu-size-chooser label > input:checked + img, .menu-layout-chooser label > input:checked + img {
	border-width: 2px;
	border-color: #3291e1;
	border-style: solid;
	box-shadow: 0 0 3px #449d44;
}
.menu-size-chooser label > input:checked + span.layout-name, .menu-layout-chooser label > input:checked + span.layout-name {
	background: #449d44 !important;
}
.image_size-image {
	height: 85px;
}
.menu-size-chooser {
	text-align: center;
}
.menu-size-chooser label {
	text-align: center;
}
.menu-size-chooser:not(.image-size) {
	padding-bottom: 20px;
}
.menu-size-chooser:not(.image-size) label {
	height: 75px;
	padding: 0;
}
.menu-size-chooser input[disabled] + img {
	opacity: 0.3 !important;
	cursor: unset;
}
.menu-size-chooser .gradient_color {
	position: relative;
	height: 60px;
	width: 100px;
}
.menu-size-chooser .update-image-links {
	margin-top: 5px;
}
.list-wrapper {
	position: relative;
	background: white;
}
.list-menu-item {
	position: relative;
	min-height: 30px;
}
.list-menu-item .nameBox {
	position: static;
	height: auto;
	width: auto;
}
.list-menu-item .priceBox {
	position: static;
	width: 10vw;
}
.priceBox .price {
	padding: 0 5px;
}
.priceBox {
	top: 18%!important;
}
.list-footer {
	padding: 5px;
	position: absolute;
	bottom: 0;
	height: 40px;
	width: 100%;
	z-index: 53;
	background: rgba(255, 255, 255, 0.6);
}
.category-selection .select2-container {
	width: 100% !important;
}
/*
** Menu Type Selection Classes
****************************/

.selectType {
	text-align: center;
}
.selectType a {
	display: block;
	border: 1px solid #CCC;
	box-shadow: 0 0 5px #CCC;
}
.selectType a:hover h4 {
	color: #5BC0DE !important;
}
.selectType h4 {
	padding: 5px 0 5px 0;
	margin: 0;
	background: #888;
	color: white;
}
.feedItems-mlText {
	float: left;
	width: 100%;
}

/***************************
*
* Scheduler
**
***************************/

.timeline-wrapper {
	position: relative;
	float: left;
	width: 100%;
}
#scheduler-overlay {
	height: 100%;
	width: 100%;
	position: absolute;
	background: rgba(0, 0, 0, 0.2);
	z-index: 2;
}
.scheduler-overlay-inner-message {
	position: absolute;
	top: 50%;
	width: 100%;
	text-align: center;
	transform: translateY(-50%);
	color: IndianRed;
	font-size: 22px;
	background: rgba(255, 255, 255, 0.8);
	box-shadow: 0 0 3px #888;
}
span.temp-name {
	color: #fff;
	font-size: 11px;
	line-height: 30px;
}
span.temp-name span {
	font-size: 11px;
}
.active-scheduler {
	display: none;
}
div.timeline-axis-text-major {
	font-weight: bold;
}
.vis-left .group-section div {
	color: #000 !important;
}
.vis-label .vis-inner {
    height: 96px;
}
#scheduler-add-group {
    width: 90px;
    height: 55px !important;
    top: 1px !important;
    left: 1px !important;
}
.vis-panel.vis-left,
.vis-panel.vis-left .vis-labelset,
.vis-panel.vis-left .vis-content {
    width: 92px !important;
}
.vis-left .group-section .vis-inner {
	position: relative;
	top: 50%;
	font-size: 12px;
	transform: translateY(-50%);
	font-weight: 500;
}
.vis-labelset .vis-label, .vis-time-axis, .vis-foreground .vis-group {
	border-bottom: 1px solid #BFBFBF !important;
	background: rgb(235, 241, 246);
	background: rgba(235, 241, 246, 0.6);
}
.vis-background .group-disabled {
	display: none;
}
.vis-group.group-disabled {
	background: rgb(204, 51, 102);
	background: rgba(204, 51, 102, 0.3);
}
.vis-group.group-actions-disabled .vis-delete, .vis-group.group-actions-disabled .modify-item {
	display: none;
}
.vis-panel.vis-top {
	box-shadow: 0 0 5px #CECECE;
	background: #fff;
	border-bottom: 2px solid #3291e1;
}
.vis-panel.vis-top .vis-time-axis.vis-foreground, .vis-panel.vis-left .vis-label.group-section {
	background: none;
}
.vis-panel.vis-bottom {
	border-top: 1px solid #ddd !important;
	box-shadow: 0 0 5px #CECECE;
}
.vis-panel.vis-center, .vis-panel.vis-right {
	border: none !important;
}
.vis-time-axis .vis-grid.vis-major {
	border-color: #333 !important;
}
.vis-time-axis .vis-grid.vis-minor {
	border-color: #CCC !important;
}
.vis-panel.vis-left {
	top: 52px !important;
	background: #fff;
	border-right: 2px solid #3291e1 !important;
	border-top: none !important;
}
.vis-label .vis-item {
	background: rgb(213, 221, 246) !important;
	background: rgba(213, 221, 246, 0.4) !important;
}
.vis-selected {
	background: rgba(240, 107, 107, 0.5) none repeat scroll 0 0;
	box-shadow: 0 0 5px #CCC;
}
.vis-item.vis-box, .vis-item.vis-range {
	top: 4px !important;
	height: 88px !important;
}
.vis-item.item-loc-selected {
    opacity: .3 !important;
    user-select: none;
    pointer-events: none;
    background: inherit !important;
    border-color: #3291e1 !important;
}
.assigned-slide-image-container {
	float: left;
	margin-right: 5px;
}
.assigned-slide-image {
	height: 62px;
	width: auto;
	border: solid 2px #fff;
}
.assigned-title {
	font-size: 11px;
	font-weight: 500;
	padding: 2px 5px;
	line-height: 50px;
}
.displayContents {
	text-align: left;
}
.displayContents .vis-timeline {
	border: none;
}
.displayContents #scheduler-overlay {
	height: 660px;
}
.vis-timeline {
    border-bottom: 2px solid #3291e1 !important;
}
.vis-panel.vis-background,
.vis-panel.vis-top,
.vis-panel.vis-center {
	left: 91px !important;
	width: -webkit-calc(100% - 90px)  !important;
	width: -moz-calc(100% - 90px)  !important;
	width: calc(100% - 90px) !important;
}
#scheduler-add-group {
	position: absolute;
	left: 0;
	top: 0;
	z-index: 3;
	width: 90px;
	text-align: center;
	height: 60px;
	background: white;
}
#scheduler-add-group .btn {
	margin: 10px 2px 0 2px;
	width: 38%;
	line-height: 30px;
}
.group-timing-sel2 {
    height: 25px !important;
    margin: 5px 0 0 0;
    min-height: 25px!important;
}
.group-timing-sel2 .select2-selection__rendered {
    line-height: 25px !important;
    padding: 0px 8px!important;
}
.group-timing-sel2 .select2-selection__arrow {
	height: 35px !important;
}
.float-tools {
	position: absolute;
	right: 0;
	top: -5px;
}
.float-tools a {
	padding: 0;
	margin: 0 !important;
}
.float-tools .fa {
	text-shadow: 0 1px 1px #666;
}
.float-tools .fa-unlock {
	color: #fe9700;
}
.float-tools .fa-lock {
	color: #d9534f;
}
.vis-item {
	background: rgba(50, 145, 225, 0.2) none repeat scroll 0 0;
	opacity: 1;
	color: #000;
	padding: 10px;
	font-size: 13px;
	font-weight: 500;
	border-left: 5px solid #3291e1;
}
.vis-item.vis-box .vis-item-content {
	padding: 10px;
}
.vis-item.item-assigned-adv {
	background-color: IndianRed !important;
	opacity: 0.8;
	color: white;
}
.vis-item.item-assigned.vis-selected {
	opacity: 1;
	border-color: #5cb85c;
	background: rgba(92, 184, 92, 0.5) none repeat scroll 0 0;/*box-shadow: 0 0 5px #FFF inset;*/
}
.vis-item.item-assigned-adv.vis-selected {
	opacity: 0.9;
}
.vis-item.vis-range .vis-item-content {
	position: static !important;
	width: auto !important;
	border-radius: 0;
	float: left;
	margin-right: 10px;
	text-align: center;
}
.vis-item.vis-range .vis-drag-right {
	width: 12px !important;
}
.vis-item.vis-range .vis-drag-left {
	width: 12px !important;
}
.edit-item {
	position: absolute;
	right: 12px;
	background: #5cb85c none repeat scroll 0 0;
	background: rgba(92, 184, 92, .3);
	border: 1px solid rgba(0, 0, 0, 0.1);
	bottom: 0;
	font-weight: normal;
	border-radius: 3px;
	padding: 3px 8px;
	font-size: 13px;
	visibility: hidden;
}
.edit-item:hover {
	background: #61ca61;
	background: rgba(92, 184, 92, .5);
	border: 1px solid rgba(0, 0, 0, 0.1);
}
.edit-item .btn:last-of-type {
	margin-right: 0 !important;
}
.vis-selected .edit-item {
	visibility: visible;
}
.edit-item a {
	cursor: pointer;
	color: #fff;
}
.vis-item.item-placeholder-droppable {
	background: none !important;
	min-height: 80px;
	z-index: 22;
	border: 2px dashed red !important;
}
.vis-item.smooth-left {
	transition: all .5s ease-in-out;
}
.vis-item.item-action-add .vis-item-content {
	padding: 0;
	margin: 0;
}
.vis-item .item-toolset .add-quick {
	line-height: 90px;
	font-size: 12px;
	height: 98px;
	color: white;
	padding: 0 5px;
	margin: 0 0 0 5px;
	border-radius: 2px;
	background: rgb(20, 195, 110);
	background: rgba(20, 195, 110, 0.6);
}
.vis-item .item-toolset .add-quick span {
	font-weight: normal;
	font-size: 11px;
	margin: 0 0 0 5px;
}
.vis-xs .assigned-title {
	display: none;
}
.vis-xs .assigned-slide-image-container {
	width: auto;
	height: 65px;
}
.vis-xs .assigned-slide-image {
	height: 65px;
}
.vis-sm .assigned-title span {
	display: block;
}
.vis-md .assigned-title span {
	display: block;
}
.date .input-group-addon {
	width: 135px !important;
}
#time-selector .form-group, .date.input-group, .date.input-group .form-control {
	width: 100% !important;
}
.datepicker table tr td {
	border-radius: 0;
}
.bootstrap-datetimepicker-widget table td.today, .bootstrap-datetimepicker-widget table td.today:hover {
	background: #00CC66 !important;
	color: white;
}
.bootstrap-datetimepicker-widget table td.today:before {
	border-color: rgba(0, 0, 0, 0.2) transparent #FFF !important;
}
.bootstrap-datetimepicker-widget table td.today.old, .bootstrap-datetimepicker-widget table td.today.new {
	opacity: .6 !important;
}
.datepicker table tr td.disableAllow {
	color: red !important;
}
.datepicker table tr td.disableAllow.new, .datepicker table tr td.disableAllow.old {
	background: #EEE;
}
/* Slide Modal Layout */
.slideWrap {
	overflow: hidden;
	margin: 0 -10px;
}

.scheduler-buttons {
	float: left;
}
#filter-form .scheduler-buttons .btn-group a.btn, .scheduler-buttons .btn-group a.btn {
	margin: 0;
}
.scheduler-buttons .btn span {
	margin-right: 5px;
}
.scheduler-buttons .select2 .select2-selection {
	min-height: 30px;
}
.sweet-overlay {
	z-index: 9998;
}
.swal2-modal.conflictAlert {
	width: 550px;
	/*margin-left: -283px;*/
}
.swal2-modal.conflictAlert a {
	cursor: pointer;
}
.swal2-modal.conflictAlert a span {
	color: #000;
}
#slide-selector #upload, #menu-selector #menuUpload {
	padding: 5px;
}
.displayModal .menu-upload-inner {
	margin: 5px -5px 0 -5px;
}
#change-template, #change-orientation {
	position: relative;
}
#slide-single-upload .modal-body {
	height: 580px;
	overflow: hidden;
}
#slide-single-upload .file-footer-buttons {
	margin-bottom: 5px;
}
.contain-below {
	position: absolute;
	bottom: -44px;
	z-index: 999;
	background: white;
	padding: 5px 8px;
	border: 1px solid #CCC;
	box-shadow: 0 0 3px #CCC;
}
.swal2-modal.loadAlert span.custom-button-container {
	display: block;
	line-height: 90px;
}
.swal2-modal.loadAlert span.custom-button-container .well {
	line-height: 20px !important;
}
.swal2-modal.loadAlert span.custom-button-container .well small {
	font-size: 75%;
}
.swal2-modal.loadAlert a:not(.btn) {
	background-color: #8CD4F5;
	color: #FFF;
	border: medium none;
	box-shadow: none;
	font-size: 17px;
	font-weight: 500;
	border-radius: 5px;
	padding: 10px 32px;
	margin: 26px 5px 0;
	cursor: pointer;
}
.swal2-modal.loadAlert .noLoadButton {
	background: #C1C1C1 !important;
}
.swal2-modal.loadAlert .copyButton {
	background: #5BC0DE !important;
}
.swal2-modal.loadAlert .assignButton {
	background: #5CB85C !important;
}
.swal2-modal.loadAlert .sa-button-container {
	display: none;
}
.swal2-modal.loadAlert a.disabled {
	opacity: 0.4;
	cursor: not-allowed;
}
.item-no-error {
	border-color: #CCC !important;
}
#staticTimeline .vis-foreground .vis-group, #staticTimeline .vis-label {
	height: 250px !important;
}
#staticTimeline .vis-item.item-assigned.vis-selected {
	height: 235px;
}
#staticTimeline .assigned-slide-image {
	height: 200px;
}
.slide-selector .file-preview-frame, .slide-selector .file-preview-image, .slide-selector .file-preview-other {
	width: 245px !important;
	height: auto !important;
	max-height: inherit;
	float: left;
	margin: 8px;
}
.slide-selector .file-input .file-preview-image {
	height: 140px!important;
}
/***************************
*
* Device Page & Terminal
**
***************************/

.terminal-toolbar .dRow {
	background: #f2f5f6;
	background: -moz-linear-gradient(top, #f2f5f6 0%, #e3eaed 37%, #c8d7dc 100%);
	background: -webkit-linear-gradient(top, #f2f5f6 0%, #e3eaed 37%, #c8d7dc 100%);
	background: linear-gradient(to bottom, #f2f5f6 0%, #e3eaed 37%, #c8d7dc 100%);
 filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#f2f5f6', endColorstr='#c8d7dc', GradientType=0);
}
.terminal-toolbar .terminal-device-pre {
	float: left;
	width: 80px;
	font-weight: bold;
}
.terminal-toolbar .terminal-device-name {
	float: left;
	width: calc(100% - 205px);
}
/*
** Product Gallery Styling
****************************/

.gallery-container .galleryFilters {
	padding-right: 0;
}
.gallery-input-group .input-group-addon {
	background: white !important;
}
.gallery-input-group .form-control {
	border-right: 0;
	box-shadow: 0 0 0;
	border-color: #ccc;
}
.gallery-input-group button {
	border: 0;
	background: transparent;
}
.gallery-container .list-group {
	padding: 0;
	margin: 0;
}
.gallery-container .list-group-item {
	padding: 5px 7px;
	font-size: 12px;
}
.gallery-container .list-group-item.keyFound {
	font-weight: bold;
	color: #FFF;
	background: #5bc0de;
}
.gallery-container .filterPanel {
	padding: 0;
}
.gallery-container .filterPanel .collapsing {
	-webkit-transition: none;
	transition: none;
}
.gallery-container .filterHeader {
	margin: 0 0 5px;
}
.gallery-container .filterPanel .panel-heading {
	padding: 7px 8px;
}
.gallery-container .filterPanel .panel-body {
	padding: 0;
	height: 275px;
	overflow-y: scroll;
}
.gallery-container .list-root, .gallery-container .list-root:focus {
	background: #ECECEC;
	font-size: 13px;
}
.gallery-container .list-root:hover {
	background: #C3C3C3;
}
.gallery-container .list-group-item.activeFilterItem {
	background: #5cb85c;
	font-weight: bold;
	color: white;
}
.gallery-container .gallery-headers {
	border: 1px solid #ccc;
	font-weight: bold;
	margin-bottom: 2px;
	padding: 10px 0;
}
.gallery-container .gallery-items .list-group {
	margin: 0;
	padding: 0;
	height: 675px;
	overflow-y: auto;
}
.gallery-container .gallery-items .list-group-item {
	padding: 10px 15px;
	cursor: pointer;
}
.gallery-container .gallery-items .list-group-item:hover {
	background: #337ab7;
	color: white;
}
.gallery-container .gallery-items .list-group-item.activeGalleryItem {
	color: #337ab7;
	font-weight: bold;
}
.gallery-container .gallery-items .list-group-item.activeGalleryItem:hover {
	color: white;
}
.galleryContent * {
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
.push-container {
	width: 520px;
	height: 100%;
	top: 0;
	z-index: 1000;
	position: absolute;
	background: white;
	border: 1px solid #CCC;
}
.push-right {
	right: -520px;
}
.push-left.push-open {
	right: 0;
}
.galleryContent {
	min-height: 575px;
	overflow-x: hidden;
	position: relative;
	right: 0;
}
.push-left .push-container {
	right: 15px;
}
.gallery-container .gallery-panel .col-md-6 {
	padding: 0 5px;
}
.gallery-container .thumbnail-container {
	position: relative;
	width: 100%;
	padding-bottom: 75%;
	margin-bottom: 30px;
	height: 100px;
}
.gallery-container .thumbnail {
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 0 0 4px 4px;
}
.gallery-container .thumbnail img {
	position: absolute;
	height: 120px;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	width: auto;
}
.gallery-container img {
	max-height: 100%;
	max-width: none;
}
.thumbnail-container h5 {
	font-weight: bold;
	background: #337ab7;
	color: white;
	margin: 0 !important;
	padding: 4px 0;
	border-radius: 4px 4px 0 0;
}
.push-container .btn-toolbar .btn {
	margin-bottom: 15px;
}
/*
** Media Item Page
****************************/

#media-item-upload .file-preview-frame {
	max-width: 250px;
	float: left;
	margin: 5px;
}
/*
** .Flex Grid Fixes - Edit
****************************/

.menu-content .flex-col-span-2 .nameBox {
 font-size: .9vw;
}
.menu-content .menu-flex-item .minimal .nameBox {
	font-size: 1.3vw;
}
.menu-content .flex-col-span-2 .minimal .priceBox {
 font-size: .500vw;
}
.menu-content .flex-col-span-2 .minimal .nameBox {
 font-size: .9vw;
	font-weight: 500;
}
.menu-content .menu-flex-sub-item.flex-col-span-3 .minimal .nameBox {
	font-size: 1vw;
	font-weight: 500;
}
/*.menu-config .grid-preview .minimal .nameBox {
font-size: 1.65vw;
}*/
/*
.menu-content .flex-col-span-5:not(.menu-flex-sub-item) .minimal .nameBox {
font-size: 1.5vw;
}
.menu-content .flex-col-span-5:not(.menu-flex-sub-item) .minimal .priceBox .price1 {
font-size: 1.3vw;
}
.menu-content .flex-col-span-5:not(.menu-flex-sub-item) .minimal .priceBox .price:not(.price1) {
font-size: .7vw;
}
*/

.menu-config .preview-2 .grid-preview .minimal .nameBox {
	/*font-size: 0.9vw;*/

	font-size: 1.2em;
}
.menu-config .preview-2 .grid-preview .minimal .priceBox {
	/*font-size: .525vw;*/

	font-size: 0.68em;
}
/**
* Device Update Page
**/

.list-devices {
	max-height: 300px;
	overflow-y: auto;
	padding: 0 0 0 0;
	border: 1px solid #CCC;
}
.list-devices-title {
	background: rgb(125, 126, 125);
	background: -moz-linear-gradient(top, rgba(125, 126, 125, 1) 0%, rgba(14, 14, 14, 1) 100%);
	background: -webkit-linear-gradient(top, rgba(125, 126, 125, 1) 0%, rgba(14, 14, 14, 1) 100%);
	background: linear-gradient(to bottom, rgba(125, 126, 125, 1) 0%, rgba(14, 14, 14, 1) 100%);
 filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#7d7e7d', endColorstr='#0e0e0e', GradientType=0);
	color: white;
	padding: 10px 5px;
	margin: 0 !important;
}
.list-devices-title .checkbox {
	font-size: 14px;
	margin: 0;
}
.list-devices > div + div {
	margin-top: 0;
}
.list-devices > div {
	margin: 0 0;
}
.list-devices .checkbox {
	border-bottom: 1px solid #DDD;
	padding: 3px 0 3px 10px;
	color: green;
}
.list-devices .applied {
	padding: 3px 0 3px 10px;
	border-bottom: 1px solid #DDD;
}
.list-devices .checkbox + .checkbox {
	margin-top: 0;
}
.list-devices .checkbox.disabled {
	background: #CC0000;
	background: rgba(204, 0, 0, 0.5);
	color: #FFF;
}
.list-devices .checkbox.disabled.other {
	background: #F84300;
	background: rgba(248, 67, 0, 0.5);
}
.progress.ftx-progress-bar .progress-bar {
	line-height: 35px;
}
.progress.ftx-progress-bar {
	height: 35px;
	background: #DDD;
}
.progress.ftx-progress-bar .progress-bar {
	line-height: 35px;
	font-size: 18px;
	font-weight: bold;
}
#update-progress ul {
	margin: 0;
	padding: 0;
	max-height: 250px;
	overflow-y: auto;
}
#update-progress li {
	list-style: none;
	padding: 2px 0;
}
.canvasjs-chart-credit {
	display: none !important;
}
/**
* Admin Config Page
**/

.desc-tag-block {
	background: #e6e9ee;
	margin: 0;
	padding: 15px;
}
.desc-tag-block p {
	font-size: 14px;
}
ul.taglist {
	list-style-type: none;
	padding: 0;
	font-size: 12px;
	font-weight: bold;
}
ul.taglist li {
	margin-bottom: 7px;
}
.dd.permission-hierarchy {
	float: none;
	width: auto;
}
.btn-default {
	color: #263556;
	background-color: #e8f3fc;
	border-color: #c0dbf3;
}
.btn-default:focus, .btn-default.focus {
	color: #263556;
	background-color: #fff;
	border-color: #c0dbf3;
}
.btn-default:hover {
	color: #263556;
	background-color: #fff;
	border-color: #c0dbf3;
}
.btn-default:active, .btn-default.active, .open > .btn-default.dropdown-toggle {
	color: #263556;
	background-color: #fff;
	border-color: #c0dbf3;
}
/**
* Sweat Alert Content Css
**/

.swal2-modal {
	border-radius: 0;
	padding: 20px 20px 30px;
}
.swal2-modalt h2 {
	color: #333;
	font-weight: 300;
}
.swal2-modal fieldset {
	margin: 0 auto;
	width: 280px;
}
.swal2-modal button.cancel {
	background-color: #f3f2f8;
	color: #333;
}
.swal2-modal.loadAlert .noLoadButton {
	background: #f3f2f8 none repeat scroll 0 0 !important;
	color: #333 !important;
}
.swal2-modal.loadAlert .noLoadButton:hover, .swal2-modal button.cancel:hover {
	background: #fff;
	color: #333 !important;
}
.swal2-modal .btn, .swal2-modal button, .swal2-modal.loadAlert a:not(.btn) {
	border-radius: 3px;
}
/**
* Custom Tab css
**/

.nav-tabs {
	border-bottom: 1px solid #3291e1;
}
.nav-tabs > li > a {
	background: #3291e1 none repeat scroll 0 0;
	border: 1px solid #3291e1;
	color: #fff!important;
}
.nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
	color: #3291e1!important;
	border-color: #3291e1 #3291e1 transparent;
}
.nav-tabs > li > a:hover {
	border-color: #3291e1 #3291e1 #3291e1;
}
.nav > li > a:focus, .nav > li > a:hover {
	background-color: #f5f5f5;
	text-decoration: none;
	color: #3291e1!important;
}
.tab-content {
	border: solid 1px #3291e1;
	border-top: none;
	padding: 10px;
	margin-bottom: 15px;
}
/**
* Layout Design Content css
**/

.layoutlist-item h5 {
	font-size: 14px;
	font-weight: 500;
	padding: 0 5px 5px;
}
.layoutlist-item .layout-content {
	background: #f5f5f5 none repeat scroll 0 0;
	border: 1px solid #eee;
	padding: 5px 5px 0;
}
.layoutlist-item label, .layoutlist-item label img {
	transition: width 0.5s ease 0s;
	webkit-transition: width 0.5s ease 0s;
}
.layout-content.gallery li {
	float: left;
	text-align: center;
	margin-right: 12px;
	margin-bottom: 10px;
}
.layout-content.gallery li input {
	display: none;
}
.layout-content.gallery li label {
	display: block;
	padding: 5px;
	margin-bottom: 2px;
	border-radius: 2px;
	background: #fff;
	border: solid 1px #ddd;
	max-width: 150px;
}
.layout-content.gallery li .caption {
	font-size: 11px;
	color: #666;
	display: block;
	font-weight: 500;
	margin-bottom: 10px;
}
.layout-content.gallery li.preview-active label {
	border-color: #2095f2;
	background: #2095f2;
}
/* Setting Css
==========================================================================================*/

.setting-menu.open > .dropdown-menu {
	padding: 0;
	margin: 0;
	width: 180px;
}
.setting-menu li {
	padding: 8px 15px;
	margin-bottom: 0;
	border: none;
	border-bottom: solid 1px #ddd;
	position: relative;
}
.setting-menu li.dropdown-header {
	font-weight: 700;
	padding: 10px 15px;
	color: #000;
	font-size: 13px;
}
.setting-menu li a {
	color: #999;
	font-size: 13px;
	padding: 5px 15px;
}
.setting-menu li.active a {
	background: none;
	color: #2e6da4;
}
.setting-menu li.active::after {
	position: absolute;
	right: 10px;
	color: #2e6da4;
	font-size: 16px;
	top: 5px;
	content: "\f00c";
	font-family: 'Font Awesome\ 5 Pro';
}
.setting-menu li a:hover, .setting-menu li a:focus {
	text-decoration: none;
	color: #2e6da4;
	background: none;
}
/* Product Categories Sidebar Css update + Treeview css
==========================================================================================*/

.categoryblock, .treeview-sidebar {
	float: left;
	padding: 15px;
}
.treeview-sidebar {
	background: #c2def6;
	border-right: 3px solid #3291e1;
	overflow: auto;
	position: relative;
	width: 300px;
    padding: 0;
}
.treeview-container.category-wrapper .treeview-sidebar {
	overflow: inherit !important;
	height: 100% !important;
}
.category-right {
	width: -webkit-calc(100% - 300px);
	width: -moz-calc(100% - 300px);
	width: calc(100% - 300px);
}
.treeview-sidebar.sm-sidebar {
	width: 220px;
}
.treeview-sidebar .tree-demo {
	background: #ebf4fc none repeat scroll 0 0;
	height: 100%;
}
.tree-demo {
	background: #ebf4fc;
	border: 1px solid rgba(50, 145, 225, 0.5);
	overflow: auto;
	padding: 5px;
	position: relative;
	width: 100%;
}
.treeview-container {
	display: table;
	position: relative;
	width: 100%;
}
.treeview-container .jstree-default .jstree-open>.jstree-ocl, .treeview-container .jstree-default .jstree-closed>.jstree-ocl, .treeview-container .jstree-default .jstree-leaf>.jstree-ocl {
	float: left;
	position: relative;
	top: 10px;
}
.treeview-container .jstree-default .jstree-node {
	position: relative;
}
.treeview-container .jstree-default .jstree-anchor {
	height: auto!important;
	padding: 8px 10px;
	display: block;
	border-radius: 2px;
}
.treeview-container .jstree-default .jstree-clicked {
	background: #fff!important;
	font-weight: 500;
	color: #3291e1!important;
}
.alert .alert-title {
	font-size: 16px;
	font-weight: 500;
	margin: 5px 0;
}
.custom-contentpanel h3, .custom-contentpanel h4, .custom-contentpanel h5, .custom-contentpanel p {
	padding: 0;
	margin: 0 0 15px;
}
.custom-contentpanel p:last-child {
	margin-bottom: 0;
}
.mb-0 {
	margin-bottom: 0 !important;
}
.zoomify {
	cursor: pointer;
	cursor: -webkit-zoom-in;
	cursor: zoom-in;
}
.zoomify.zoomed {
	cursor: -webkit-zoom-out;
	cursor: zoom-out;
	padding: 0;
	margin: 0;
	border: none;
	border-radius: 0;
	box-shadow: none;
	position: relative;
	z-index: 1501;
}
.zoomify-shadow {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	display: block;
	z-index: 1500;
	background: rgba(0, 0, 0, .3);
	opacity: 0;
}
.zoomify-shadow.zoomed {
	opacity: 1;
	cursor: pointer;
	cursor: -webkit-zoom-out;
	cursor: zoom-out;
}
.switch-container .switch-content {
	display: block;
	width: 45%;
	table-layout: fixed;
	float: left;
}
.switch-container .switch-content.linked {
	width: 45%;
}

.switch-content .list-group-item {
    font-size: 10px;
    padding: 10px 0;
    border: none;
    margin-bottom: 0;
    text-transform: capitalize;
    border-bottom: solid 1px #ddd!important;
}
.switch-content .scrollResult {
    overflow: auto;
    position: relative;
    height: 400px;
	border-top: 1px solid #ddd;
    padding: 0;
    box-shadow: 0 1px 3px #AAA;
}

.switch-content .switch-box {border: solid 1px #ddd;}

.switch-container .switch-content.divider {
	width: 10%;
	padding-top: 140px;
}
.switch-content.divider {
	width: 10%;
	padding-top: 140px;
}
.switch-container .switch-content.linked input {
	height: 26px;
	line-height: 26px;
	padding: 3px 6px;
}
.addSingleItem {
	display: block;
	min-height: 35px;
}
.addSingleItemDisabled {
	display: block;
	min-height: 35px;
}
/**
** Designer Category List Styling
**/

.design-category-item {
	float: left;
	margin-right: 25px;
	min-height: 300px;
	min-width: 230px;
	text-align: center;
	box-shadow: 0 0 3px #DDD;
	margin-bottom: 15px;
}
.design-category-item, .design-category-item * {
	cursor: pointer;
	transition: all .1s;
}
.design-category-image {
	height: 215px;
}
.design-category-image img {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	padding: 15px;
    margin: 0 auto;
}
.design-category-list input:checked + .design-category-image {
	background: #3291e1;
	opacity: .8;
	position: relative;
}
.design-category-list input:checked + .design-category-image:after {
	position: absolute;
	font-family: 'Font Awesome\ 5 Pro';
	content: "\f058";
	font-size: 40px;
	height: 40px;
	width: 40px;
	color: green;
	left: 50%;
	top: 50%;
	margin: -20px 0 0 -20px;
}

.custom-design-item{
    max-height: 300px;
    max-width: 300px;
    height: 300px;
    margin: 0px auto;
    width: 100%;
}

.custom-dimensions .design-category-item
{
    width: 300px;
    height: 300px;
    border: 1px solid #cbcbcb;
}
.custom-dimensions .custom-dimension-item {
    height: 100%;
    width: 100%;
}
.custom-dimensions .design-category-image
{
    height: 150px;
    width: 150px;
    margin: auto;
    display: table;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background: #cbcbcb;
}
.custom-dimensions .design-category-image .size-text
{
    display: table-cell;
    vertical-align: middle;
    color: #959595;
}
#accordion .panel-heading
{
    cursor: pointer;
}
.custom-dimensions.design-category-list input:checked + .design-category-image
{
    background-color: #cbcbcb;
}
/*@media (max-width: 550px)
{
    .custom-dimensions .design-category-item
    {
        width: 200px;
        height: 200px;
    }
    .custom-dimensions .design-category-image
    {
        height: 75px;
        width: 75px;
    }
}*/
/*@media (max-width: 460px)
{
	.custom-dimensions .responsive > div
	{
		display: inline-block;
	}
}*/

.gridster .grid {
	background: none;
	box-shadow: none;
}
.gridster .grid-item {
	background: #fefefe none repeat scroll 0 0;
	border: 2px solid #fcfcfc;
	border-radius: 6px;
	box-shadow: 0 0 4px #999;
	display: inline-block;
	margin: 5px 0.25%;
	padding: 3px;
	transition: opacity 0.4s ease-in-out 0s;
	width: 19.5%;
}
.gridster.col-3-gallery .grid-item {
	width: 31.3%;
	margin: 0 1% 15px;
	font-size: 0em;
}
.gridster.col-4-gallery .grid-item {
	width: 23%;
	margin: 0 1% 15px;
	font-size: 0em;
}
.gridster .grid-item:hover {
	border-color: #3291e1;
	padding: 1px;
	border-width: 4px;
}
.gridster .grid-item-inner {
	position: relative;
	overflow: hidden;
}
.slide-gallery-image img {
	border-radius: 6px;
	min-width: 150px;
	height: auto;
}
.slide-gallery-image {
	border: none;
	height: auto;
	background: none;
	font-size: 7em;
}
.slide-gallery-title {
	display: block;
	font-size: 13px;
	font-weight: 600;
	padding: 6px 7px 15px 7px;
	bottom: -100px;
	transition: all 0.5s ease 0s;
	-webkit-transition: all 0.5s ease 0s;
	position: absolute;
	text-align: left;
	margin-bottom: 0;
	background: rgba(0, 0, 0, 0.7) none repeat scroll 0 0;
	border-radius: 0 0 6px 6px;
	width: 100%;
	color: white;
}
.slide-gallery-title small {
	color: #02aeb4;
}
.slide-gallery-title a, .slide-gallery-title a:hover {
	color: #fff;
}
.slide-gallery-title .btn-content {
	cursor: pointer;
	margin-bottom: 0;
	margin-top: -10px;
	position: absolute;
	right: 6px;
	bottom: 6px;
}
.grid .slide-gallery-title .btn-content a {
	display: inline-block;
}
.gridster .grid-item:hover .slide-gallery-title, .gridster.fix-gallery-title .grid-item .slide-gallery-title {
	bottom: 0;
}
/**
* Lazyloader style
**/

.grid {
	list-style: none;
	margin: 0 auto;
	padding: 0;
}
.grid li {
	display: block;
	float: left;
	padding: 7px;
	width: 48%;
	opacity: 0;
}
.grid li.shown, .no-js .grid li, .no-cssanimations .grid li {
	opacity: 1;
}
.grid li a, .grid li img {
	outline: none;
	border: none;
	display: block;
	max-width: 100%;
}
/* Effect 1: opacity */

.grid.effect-1 li.animate {
	-webkit-animation: fadeIn 0.65s ease forwards;
	animation: fadeIn 0.65s ease forwards;
}
@-webkit-keyframes fadeIn {
 0% {
}
100% {
 opacity: 1;
}
}
@keyframes fadeIn {
 0% {
}
100% {
 opacity: 1;
}
}
/* Effect 2: Move Up */

.grid.effect-2 li.animate {
	-webkit-transform: translateY(200px);
	transform: translateY(200px);
	-webkit-animation: moveUp 0.65s ease forwards;
	animation: moveUp 0.65s ease forwards;
}
@-webkit-keyframes moveUp {
 0% {
}
100% {
 -webkit-transform: translateY(0);
 opacity: 1;
}
}
@keyframes moveUp {
 0% {
}
100% {
 -webkit-transform: translateY(0);
 transform: translateY(0);
 opacity: 1;
}
}
/* Effect 3: Scale up */

.grid.effect-3 li.animate {
	-webkit-transform: scale(0.6);
	transform: scale(0.6);
	-webkit-animation: scaleUp 0.65s ease-in-out forwards;
	animation: scaleUp 0.65s ease-in-out forwards;
}
@-webkit-keyframes scaleUp {
 0% {
}
100% {
 -webkit-transform: scale(1);
 opacity: 1;
}
}
@keyframes scaleUp {
 0% {
}
100% {
 -webkit-transform: scale(1);
 transform: scale(1);
 opacity: 1;
}
}
/* Effect 4: fall perspective */

.grid.effect-4 {
	-webkit-perspective: 1300px;
	perspective: 1300px;
}
.grid.effect-4 li.animate {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transform: translateZ(400px) translateY(300px) rotateX(-90deg);
	transform: translateZ(400px) translateY(300px) rotateX(-90deg);
	-webkit-animation: fallPerspective .8s ease-in-out forwards;
	animation: fallPerspective .8s ease-in-out forwards;
}
@-webkit-keyframes fallPerspective {
 0% {
}
100% {
 -webkit-transform: translateZ(0px) translateY(0px) rotateX(0deg);
 opacity: 1;
}
}
@keyframes fallPerspective {
 0% {
}
100% {
 -webkit-transform: translateZ(0px) translateY(0px) rotateX(0deg);
 transform: translateZ(0px) translateY(0px) rotateX(0deg);
 opacity: 1;
}
}
/* Effect 5: fly (based on http://lab.hakim.se/scroll-effects/ by @hakimel) */

.grid.effect-5 {
	-webkit-perspective: 1300px;
	perspective: 1300px;
}
.grid.effect-5 li.animate {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transform-origin: 50% 50% -300px;
	transform-origin: 50% 50% -300px;
	-webkit-transform: rotateX(-180deg);
	transform: rotateX(-180deg);
	-webkit-animation: fly .8s ease-in-out forwards;
	animation: fly .8s ease-in-out forwards;
}
@-webkit-keyframes fly {
 0% {
}
100% {
 -webkit-transform: rotateX(0deg);
 opacity: 1;
}
}
@keyframes fly {
 0% {
}
100% {
 -webkit-transform: rotateX(0deg);
 transform: rotateX(0deg);
 opacity: 1;
}
}
/* Effect 6: flip (based on http://lab.hakim.se/scroll-effects/ by @hakimel) */

.grid.effect-6 {
	-webkit-perspective: 1300px;
	perspective: 1300px;
}
.grid.effect-6 li.animate {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transform-origin: 0% 0%;
	transform-origin: 0% 0%;
	-webkit-transform: rotateX(-80deg);
	transform: rotateX(-80deg);
	-webkit-animation: flip .8s ease-in-out forwards;
	animation: flip .8s ease-in-out forwards;
}
@-webkit-keyframes flip {
 0% {
}
100% {
 -webkit-transform: rotateX(0deg);
 opacity: 1;
}
}
@keyframes flip {
 0% {
}
100% {
 -webkit-transform: rotateX(0deg);
 transform: rotateX(0deg);
 opacity: 1;
}
}
/* Effect 7: helix (based on http://lab.hakim.se/scroll-effects/ by @hakimel) */

.grid.effect-7 {
	-webkit-perspective: 1300px;
	perspective: 1300px;
}
.grid.effect-7 li.animate {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transform: rotateY(-180deg);
	transform: rotateY(-180deg);
	-webkit-animation: helix .8s ease-in-out forwards;
	animation: helix .8s ease-in-out forwards;
}
@-webkit-keyframes helix {
 0% {
}
100% {
 -webkit-transform: rotateY(0deg);
 opacity: 1;
}
}
@keyframes helix {
 0% {
}
100% {
 -webkit-transform: rotateY(0deg);
 transform: rotateY(0deg);
 opacity: 1;
}
}
/* Effect 8:  */

.grid.effect-8 {
	-webkit-perspective: 1300px;
	perspective: 1300px;
}
.grid.effect-8 li.animate {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transform: scale(0.4);
	transform: scale(0.4);
	-webkit-animation: popUp .8s ease-in forwards;
	animation: popUp .8s ease-in forwards;
}
@-webkit-keyframes popUp {
 0% {
}
70% {
 -webkit-transform: scale(1.1);
 opacity: .8;
 -webkit-animation-timing-function: ease-out;
}
 100% {
 -webkit-transform: scale(1);
 opacity: 1;
}
}
@keyframes popUp {
 0% {
}
70% {
 -webkit-transform: scale(1.1);
 transform: scale(1.1);
 opacity: .8;
 -webkit-animation-timing-function: ease-out;
 animation-timing-function: ease-out;
}
 100% {
 -webkit-transform: scale(1);
 transform: scale(1);
 opacity: 1;
}
}
@media screen and (max-width: 900px) {
.grid li {
	width: 50%;
}
}
@media screen and (max-width: 400px) {
.grid li {
	width: 100%;
}
}
/* for tree view in product clone*/

.product-clone-module .categoryblock {
	width: 100%;
}
.fluid-popup .modal-dialog {
	margin: 20px 2%;
	width: 96% !important;
	height: -webkit-calc(100% - 40px) !important;
	height: -moz-calc(100% - 40px) !important;
	height: calc(100% - 40px) !important;
}
.fluid-popup .modal-content {
	width: auto;
	height: 100% !important;
}

.fluid-popup .modal-content.h-auto {height: auto!important;}


.fluid-popup .modal-body {
	height: -webkit-calc(100% - 77px) !important;
	height: -moz-calc(100% - 77px) !important;
	height: calc(100% - 77px) !important;
}
.fluid-popup .close {
	position: absolute;
	right: 15px;
	top: 15px;
}
.ui-autocomplete .ui-autocomplete-category {
	color: #3291e1;
	font-weight: 500;
	border-top: solid 1px #eee;
	font-family: "Open Sans";
	padding: 6px 15px;
	margin: 0;
	font-size: 18px;
	background: #f0f0f0;
}
.ui-autocomplete .ui-menu-item img {
	margin-right: 10px;
}
/* IE 6 doesn't support max-height*/
* html .ui-autocomplete {
	height: 350px;
}
.product-clone-module.image-gridster {
	display: block;
	padding-left: 340px;
}
.product-clone-module.image-gridster .categoryblock {
	display: block;
	overflow: auto;
	height: 100%;
}
.product-clone-module .treeview-sidebar-clone {
	height: 100%;
	overflow: auto;
	display: block;
	border-right: none;
	background: none;
	position: absolute;
	left: 10px;
	width: 330px;
	padding: 0;
}
.product-clone-module.image-gridster {
	margin: 0;
	display: block;
	padding-left: 340px;
	height: 100%;
}
.ui-product-clone {
	z-index: 1060;
}
.clone-scroll-area {
	margin: 0 0 10px;
	padding: 5px 10px;
	height: 180px;
	overflow-x: auto;
	position: relative;
	white-space: nowrap;
	box-shadow: 0 1px 3px #C3C3C3;
}
.image-grid .product-item {
	display: inline-block;
	margin: 5px 1%;
	padding: 3px;
	transition: opacity 0.4s ease-in-out 0s;
	width: auto;
	vertical-align: top;
}
.image-grid .product-item .slide-gallery-image {
	margin: 0 0 3px;
	height: 120px;
	width: 150px;
	text-align: center;
	position: relative;
	border: solid 1px #ccc;
	background: white;
}
.image-grid.clone-scroll-area .product-item .slide-gallery-image {
	height: 90px;
}
.image-grid .product-item img {
	background: #fff;
	height: auto;
	position: absolute;
	width: auto !important;
	vertical-align: top;
	min-width: initial;
	left: 50%;
	top: 50%;
	-webkit-transform: translateY(-50%) translateX(-50%);
	-moz-transform: translateY(-50%) translateX(-50%);
	transform: translateY(-50%) translateX(-50%);
}
.clone-product-area {
	overflow: auto;
	min-height: 200px;
	height: -webkit-calc(100% - 255px) !important;
	height: -moz-calc(100% - 255px) !important;
	height: calc(100% - 255px) !important;
	box-shadow: 0 1px 3px #C3C3C3;
}
.image-grid .grid-item-inner .product-name {
	text-align: center;
	font-size: 14px;
	font-weight: bold;
	margin: 0 0 3px;
}
.image-grid .grid-item-inner .btn-content {
	text-align: center;
}
.file-input-error-list {
	list-style-type: decimal;
	margin-left: 0;
}
.label.label-tag {
	background-color: #e4e4e4;
	border: 1px solid #DDD;
	box-shadow: 0 1px 3px #999;
	border-radius: 4px;
	cursor: default;
	float: left;
	margin-right: 5px;
	margin-top: 5px;
	padding: 5px 5px;
	color: #888;
	font-size: 12px;
	font-weight: normal;
}
.list-group-item > a.disabled, .list-group-item > a.disabled:focus, .list-group-item a.disabled:hover {
	color: #777;
	cursor: not-allowed;
	background-color: #eee;
}
.display-dropdown {
	padding: 5px 3px;
	background: transparent;
	border: 1px solid #ddd;
	border-radius: 3px;
}
/*jQuery Business hours css overrite*/
.operationTime .mini-time {
	width: 55px;
}
.custom-scheduler .dayContainer {
    float: left;
    line-height: 20px;
    margin-right: 1.2%;
    width: 13% !important;
    font-size: 11px;
    font-weight: bold;
	max-width:60px;
    position: relative;
}
.custom-scheduler .colorBox {
	height: 34px;
}
.custom-scheduler .weekday {
	cursor: pointer;
	position: absolute;
	top: 7px;
	z-index: 1;
	color: #fff;
	left: 50%;
	transform: translateX(-50%);
}
.custom-scheduler .operationDayTimeContainer .input-group-addon {
	display: none;
}
.custom-scheduler {
	position: relative;
	min-height: 120px;
	padding-left: 38px;
}
.custom-scheduler .operationTime .mini-time {
	width: 100%;
	font-size: 11px;
}
.custom-scheduler .custom-iconpreffix {
	position: absolute;
	top: 40px;
	left: 0;
}
.custom-scheduler .custom-iconpreffix .btn {
	margin-bottom: 7px;
}
.loader-Content {
	height: 100%;
	width: 100%;
	-webkit-transition: -webkit-transform 0.3s;
	opacity: 0;
	transition: transform 0.3s;
	left: 0;
	top: 0;
	position: absolute;
	background: rgba(0, 0, 0, 0.6);
	z-index: -1;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.loader-Content .loading-text
{
	display: none;
}
.loader-Content.active.full-screen .loading-text{
	display: block;
}
.loader-Content.active.full-screen{
	background-color: #3291e1;
}
.loader-Content.active.full-screen .loading-text {
    text-align: center;
    font-size: 50px;
    position: absolute;
	top: 50%;
	left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(to left, #185080 20%, #ffffff 40%, #ffffff 60%, #185080 80%);
    background-size: 200% auto;
    color: #000;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 1s linear infinite;
}
@keyframes shine {
	from {
		background-position: 200% center;
	}
    to {
      background-position: 0% center;
    }
  }
.loader-Content.active {
	opacity: 1;
	z-index: 99999;
	height: 100%;
	-webkit-user-select: inherit;
	-khtml-user-select: inherit;
	-moz-user-select: inherit;
	-ms-user-select: inherit;
	user-select: inherit;
}
/* Loader Effect: Corner indicator */
.loader-anim {
	position: fixed;
	top: 0;
	right: 0;
	z-index: 999;
	width: 200px;
	height: 200px;
	opacity: 0;
	background: #3291e1;
	-webkit-transition: -webkit-transform 0.3s;
	transition: transform 0.3s;
	-webkit-transform: translateX(100%) translateY(-100%) rotate(45deg);
	transform: translateX(100%) translateY(-100%) rotate(45deg);
	pointer-events: none;
}
.loader-Content.active .loader-anim {
	opacity: 1;
}
.loader-Content.active .loader-anim {
	-webkit-transform: translateX(50%) translateY(-50%) rotate(45deg);
	transform: translateX(50%) translateY(-50%) rotate(45deg);
}
/* Loading circle idea from http://codepen.io/Metty/details/lgJck */
.loader-anim::before, .loader-anim::after {
	position: absolute;
	bottom: 15px;
	left: 50%;
	display: block;
	border: 5px solid #fff;
	border-radius: 50%;
	content: '';
}
.loader-anim::before {
	margin-left: -30px;
	width: 60px;
	height: 60px;
	border-right-color: #185080;
	border-left-color: #185080;
	-webkit-animation: rotation 3s linear infinite;
	animation: rotation 3s linear infinite;
}
.loader-anim::after {
	bottom: 30px;
	margin-left: -15px;
	width: 30px;
	height: 30px;
	border-top-color: #185080;
	border-bottom-color: #185080;
	-webkit-animation: rotation 1s linear infinite;
	animation: rotation 1s linear infinite;
}
 @-webkit-keyframes rotation {
 0% {
-webkit-transform: rotate(0deg);
}
 50% {
-webkit-transform: rotate(180deg);
}
 100% {
-webkit-transform: rotate(360deg);
}
}
 @keyframes rotation {
 0% {
transform: rotate(0deg);
}
 50% {
transform: rotate(180deg);
}
 100% {
transform: rotate(360deg);
}
}

.all-items-point-value {
    display: inline-block;
    width: 95px;
    margin-left: 10px;
    border: none;
    height: 28px;
    background: rgba(0,0,0,0.4);
    padding: 0 6px;
}

.word-break {word-break:break-all;}

textarea
{
    resize: none !important;
}

textarea.resizable
{
    resize: both !important;
}

#carousel-product .carousel-inner>.item {
	width: auto;
	height: 300px;
	max-height: 300px;
}

/** Dump Styling **/

pre.sf-dump {
    background: rgba(0, 0, 0, .85) !important;
    width: 100% !important;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.clearfix { display: inline-block; }
/* start commented backslash hack \*/
* html .clearfix { height: 1%; }
.clearfix { display: block; }

.select2-sm .select2-container .select2-selection--single {
    height: 30px;
}
.select2-sm .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 28px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 28px;
}


/* Modal Vertically Aligned */
.modal-dialog {
	position: absolute !important;
	top: 50% !important;
	left: 50% !important;
	transition: width .3s ease-out!important;
	margin: 0 !important;
	-webkit-transform: translateY(-50%) translateX(-50%) !important;
	-ms-transform: translateY(-50%) translate(-50%) !important;
	transform: translateY(-50%) translate(-50%) !important;
}

@media (min-width: 1625px)
{
	.modal-dialog.modal-lg {
	    /*width: 70%;*/
	}
}

.modal-body {
	height: auto;
	max-height: calc(100vh - 160px);
	overflow: auto;
	max-width: calc(100vw - 30px);
	overflow-x: hidden !important;
}

/* Col 5ths Addon */
.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
	position: relative;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
}

.col-xs-5ths {
	width: 20%;
	float: left;
}

@media (min-width: 768px) {
	.col-sm-5ths {
		width: 20%;
		float: left;
	}
}

@media (min-width: 992px) {
	.col-md-5ths {
		width: 20%;
		float: left;
	}
}

@media (min-width: 1200px) {
	.col-lg-5ths {
		width: 20%;
		float: left;
	}
}

.campaign-image-preview .file-preview img {
	max-width: 100% !important;
}

#slide-box-contents .nav-tabs > li.active > a {
    padding: 8px 16px;
}

.bg-speech-blue {
	background-color: #4F62C2 !important;
	color: #fff;
}

.displayWrapper  .lock-layer-icon {
	display: none;
}